import {
    Button,
    Modal,
    ModalDialog,
    ModalClose,
    FormControl,
    FormLabel,
    Input,
    Stack,
    DialogTitle,
    DialogContent,
    Switch,
    FormHelperText,
} from "@mui/joy";
import { generateClient } from "aws-amplify/api";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createRoom } from "../../graphql/mutations";
import { useModalContext } from "../../context/modalContext";
import { getCurrentUser } from "aws-amplify/auth";
import { useMutation } from "react-query";
import { FieldApi, useForm } from "@tanstack/react-form";
import { roomByName } from "../../graphql/queries";

// TODO: GET RIDE OF ALL ZINDEXES

function FieldInfo({ field }: { field: FieldApi<any, any, any, any> }) {
    return (
        <>
            {field.state.meta.touchedErrors ? <FormHelperText>{field.state.meta.touchedErrors}</FormHelperText> : null}
            {field.state.meta.isValidating ? <FormHelperText>Validating...</FormHelperText> : null}
        </>
    );
}

interface NewRoom {
    name: string;
    private: boolean;
    allowComments: boolean;
    private_code?: string;
}

export const CreateRoomModal = () => {
    const navigate = useNavigate();
    const client = generateClient();
    const { createRoomModalOpen, setCreateRoomModalOpen, setNeedsRefetch } = useModalContext();
    const [showPasscode, setShowPasscode] = useState(false);

    const createRoomMutation = async (newRoom: NewRoom) => {
        const { userId } = await getCurrentUser();
        const { data } = await client.graphql({
            query: createRoom,
            variables: { input: { ...newRoom, user_id: userId } },
        });
        return data.createRoom;
    };

    const { mutate, isLoading } = useMutation(createRoomMutation, {
        onSuccess: (data) => {
            setCreateRoomModalOpen(false);
            setNeedsRefetch(true);
            navigate("/room/" + data.name);
        },
        onError: (error) => {
            console.error("Error creating room:", error);
        },
    });

    const form = useForm({
        defaultValues: {
            name: "",
            privateRoom: false,
            privateRoomPassword: Math.floor(100000 + Math.random() * 900000).toString(),
            allowComments: true,
        },
        onSubmit: async ({ value }) => {
            mutate({
                name: value.name,
                private: value.privateRoom,
                allowComments: value.allowComments,
                private_code: value.privateRoom ? value.privateRoomPassword : undefined,
            });
            setShowPasscode(false);
        },
    });

    return (
        <Modal
            open={createRoomModalOpen}
            sx={{ zIndex: 100000000 }}
            onClose={() => {
                setCreateRoomModalOpen(false);
                setShowPasscode(false);
            }}
        >
            <ModalDialog variant="soft">
                <ModalClose />
                <DialogTitle>Create Room</DialogTitle>
                <DialogContent>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            form.handleSubmit();
                        }}
                    >
                        <Stack spacing={2}>
                            <form.Field
                                name="name"
                                validators={{
                                    onSubmitAsync: async ({ value }) => {
                                        const isValid = /^[a-zA-Z0-9-]+$/.test(value);
                                        const isValidMultipleDashes = /^(?!.*--)(?!^-)(?!.*-$)[a-zA-Z0-9-]+$/.test(
                                            value
                                        );

                                        if (!isValid) {
                                            return "Only letters, numbers, and dashes are allowed.";
                                        }
                                        if (!isValidMultipleDashes) {
                                            return "No leading or trailing dashes, and no multiple dashes in a row.";
                                        }

                                        try {
                                            const { data } = await client.graphql({
                                                query: roomByName,
                                                variables: { name: value, limit: 1 },
                                            });
                                            if (data.roomByName.items.length > 0) {
                                                return "A room with this name already exists.";
                                            }
                                        } catch (error) {
                                            console.error("Error checking room name:", error);
                                            return "An error occurred while checking the room name.";
                                        }

                                        return undefined;
                                    },
                                }}
                                children={(field) => {
                                    return (
                                        <FormControl required error={field.state.meta.errors.length > 0 ? true : false}>
                                            <FormLabel>Name</FormLabel>
                                            <Input
                                                id={field.name}
                                                autoFocus
                                                required
                                                value={field.state.value}
                                                onChange={(e) => field.handleChange(e.target.value.toLowerCase())}
                                                placeholder="baby-nuckols"
                                            />
                                            <FieldInfo field={field} />
                                        </FormControl>
                                    );
                                }}
                            />
                            <form.Field
                                name="privateRoom"
                                children={(field) => (
                                    <FormControl
                                        required
                                        orientation="horizontal"
                                        sx={{
                                            width: { xs: 280, sm: 400 },
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            <FormLabel>Private</FormLabel>
                                            <FormHelperText sx={{ mt: 0 }}>Require passcode to join</FormHelperText>
                                        </div>
                                        <Switch
                                            checked={field.state.value}
                                            onChange={(event) => {
                                                field.handleChange(event.target.checked);
                                                setShowPasscode(event.target.checked);
                                            }}
                                        />
                                    </FormControl>
                                )}
                            />
                            {showPasscode && (
                                <form.Field
                                    name="privateRoomPassword"
                                    validators={{
                                        onSubmit: ({ value }) => {
                                            const isValid = /^\d{4}(\d{2})?$/.test(value);
                                            return !isValid ? "Must be a 4 or 6 digit number." : undefined;
                                        },
                                    }}
                                    children={(field) => (
                                        <FormControl
                                            required={showPasscode}
                                            error={field.state.meta.errors.length > 0 ? true : false}
                                        >
                                            <FormLabel>Passcode</FormLabel>
                                            <Input
                                                type="number"
                                                value={field.state.value}
                                                onChange={(e) => field.handleChange(e.target.value)}
                                            />
                                            <FieldInfo field={field} />
                                        </FormControl>
                                    )}
                                />
                            )}
                            <form.Field
                                name="allowComments"
                                children={(field) => (
                                    <FormControl
                                        required
                                        orientation="horizontal"
                                        sx={{
                                            width: { xs: 280, sm: 400 },
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            <FormLabel>Comments</FormLabel>
                                            <FormHelperText sx={{ mt: 0 }}>Allow comments on your posts</FormHelperText>
                                            <FormHelperText sx={{ mt: 0, fontSize: "0.6rem" }}>
                                                Comments are visible only to you and the commenter
                                            </FormHelperText>
                                        </div>
                                        <Switch
                                            checked={field.state.value}
                                            onChange={(event) => field.handleChange(event.target.checked)}
                                        />
                                    </FormControl>
                                )}
                            />
                            <Button type="submit" loading={isLoading}>
                                Submit
                            </Button>
                        </Stack>
                    </form>
                </DialogContent>
            </ModalDialog>
        </Modal>
    );
};
