import React from "react";
import { useColorScheme } from "@mui/joy/styles";
import IconButton, { IconButtonProps } from "@mui/joy/IconButton";

import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeIcon from "@mui/icons-material/LightMode";

export const ColorSchemeToggle = (props: IconButtonProps) => {
    const { onClick, sx, ...other } = props;
    const { mode, setMode } = useColorScheme();
    const [mounted, setMounted] = React.useState(false);

    React.useEffect(() => {
        setMounted(true);
    }, []);

    React.useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            if (mode === "light") {
                metaThemeColor.setAttribute("content", "#ffffff");
            } else {
                metaThemeColor.setAttribute("content", "#000000");
            }
        }
    }, [mode]);

    if (!mounted) {
        return <IconButton size="sm" variant="outlined" color="neutral" {...other} sx={sx} disabled />;
    }

    return (
        <IconButton
            id="toggle-mode"
            size="sm"
            variant="outlined"
            color="neutral"
            {...props}
            onClick={(event) => {
                if (mode === "light") {
                    setMode("dark");
                } else {
                    setMode("light");
                }
                onClick?.(event);
            }}
            sx={[
                {
                    "& > *:first-of-type": {
                        display: mode === "dark" ? "none" : "initial",
                    },
                    "& > *:last-of-type": {
                        display: mode === "light" ? "none" : "initial",
                    },
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <DarkModeRoundedIcon />
            <LightModeIcon />
        </IconButton>
    );
};
