import React, { useEffect, useMemo, useState } from "react";
import { Box, typographyClasses, Tabs, TabList, Tab, ListItemDecorator, Chip } from "@mui/joy";
import { generateClient } from "aws-amplify/api";
import { UpdatePanel } from "./UpdatePanel";
import { TextUpdatePanel } from "./TextUpdatePanel";
import { SettingsPanel } from "./SettingsPanel";
import { listRoomAccessRequests } from "../../graphql/queries";
import { useMutation, useQuery } from "react-query";
import { onCreateRoomAccessRequests } from "../../graphql/subscriptions";

type TRoomAdminProps = {
    roomId: string;
    setSuccessfulNotificationOpen: (value: boolean) => void;
    setFailedNotificationOpen: (value: boolean) => void;
    isRoomPrivate: boolean;
    privateRoomCode: string | null;
    setCurrentTabIndex: (value: number) => void;
};

export type RoomAccessRequest = {
    __typename: "RoomAccessRequests";
    id: string;
    room_id: string;
    user_id: string;
    user_info: { first_name: string; last_name: string };
    approved: boolean;
    reviewed: boolean;
    created_at: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null | undefined;
};

export const RoomAdminController = ({
    roomId,
    setSuccessfulNotificationOpen,
    setFailedNotificationOpen,
    isRoomPrivate,
    privateRoomCode,
    setCurrentTabIndex,
}: TRoomAdminProps) => {
    const client = generateClient();

    const [roomAccessRequests, setRoomAccessRequests] = useState<RoomAccessRequest[]>([]);

    const requestsNeedReview = useMemo(
        () => roomAccessRequests.filter((accessRequest) => !accessRequest.reviewed),
        [roomAccessRequests]
    );

    useQuery(["roomAccessRequests", roomId], () => getRoomAccessRequestsInfo(roomId), {
        enabled: !!roomId,
        onSuccess: (data: RoomAccessRequest[]) => {
            const sortedData = data.sort((a: RoomAccessRequest, b: RoomAccessRequest) => {
                if (!a.reviewed && b.reviewed) return -1;
                if (a.reviewed && !b.reviewed) return 1;
                return 0;
            });
            setRoomAccessRequests(sortedData);
        },
    });

    const getRoomAccessRequestsInfo = async (roomId: string) => {
        const { data } = await client.graphql({
            query: listRoomAccessRequests,
            variables: { filter: { room_id: { eq: roomId } } },
            authMode: "userPool",
        });

        return data?.listRoomAccessRequests?.items;
    };

    const subscribeToAccessRequests = useMutation(
        async () => {
            const subscription = client
                .graphql({
                    query: onCreateRoomAccessRequests,
                    variables: { filter: { room_id: { eq: roomId } } },
                    authMode: "userPool",
                })
                .subscribe({
                    next: ({ data }: any) => {
                        const newRoomAccessRequest = data.onCreateRoomAccessRequests;
                        setRoomAccessRequests((prevRoomAccessRequets) => [
                            newRoomAccessRequest,
                            ...prevRoomAccessRequets,
                        ]);
                    },
                    error: (error: any) => console.error("Error on subscribe to access requests subscription: ", error),
                });
            return subscription;
        },
        {
            onError: (error) => {
                console.error("Error subscribing to room access requests: ", error);
            },
        }
    );

    useEffect(() => {
        if (roomId !== "" && roomId !== undefined) {
            const subscriptionPromise = subscribeToAccessRequests.mutateAsync();
            let subscription: { unsubscribe: () => void } | undefined;

            subscriptionPromise.then((sub) => {
                subscription = sub;
            });

            const timeoutId = setTimeout(() => {
                subscription?.unsubscribe();
            }, 3 * 60 * 60 * 1000);

            return () => {
                clearTimeout(timeoutId);
                subscription?.unsubscribe();
            };
        }
    }, [roomId]);

    return (
        <Box
            sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                [`& .${typographyClasses.root}`]: {
                    textWrap: "balance",
                },
            })}
        >
            <Tabs defaultValue={1} onChange={(event, newValue) => setCurrentTabIndex(Number(newValue))}>
                <TabList tabFlex="auto">
                    <Tab disableIndicator indicatorInset>
                        Text Update
                    </Tab>
                    <Tab disableIndicator indicatorInset>
                        Update
                    </Tab>
                    <Tab disableIndicator indicatorInset>
                        Admin
                        {requestsNeedReview.length > 0 && (
                            <ListItemDecorator>
                                <Chip color="warning">{requestsNeedReview.length}</Chip>
                            </ListItemDecorator>
                        )}
                    </Tab>
                </TabList>
                <TextUpdatePanel
                    roomId={roomId}
                    isRoomPrivate={isRoomPrivate}
                    privateRoomCode={privateRoomCode}
                    client={client}
                />
                <UpdatePanel
                    roomId={roomId}
                    setFailedNotificationOpen={setFailedNotificationOpen}
                    setSuccessfulNotificationOpen={setSuccessfulNotificationOpen}
                    client={client}
                />
                <SettingsPanel
                    roomId={roomId}
                    client={client}
                    roomAccessRequests={roomAccessRequests}
                    setRoomAccessRequests={setRoomAccessRequests}
                    requestsNeedReview={requestsNeedReview.length}
                />
            </Tabs>
        </Box>
    );
};
