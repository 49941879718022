import React, { useState } from "react";
import { Button, Card, CardContent, Chip, FormControl, FormLabel, Input, LinearProgress, TabPanel } from "@mui/joy";
import { uploadData } from "aws-amplify/storage";
import { createRoomUpdate } from "../../../graphql/mutations";
import TimeInput from "../../TimeInput";
import { Upload as UploadIcon, Done as CheckboxIcon } from "@mui/icons-material";

type TUpdatePanelProps = {
    roomId: string;
    setFailedNotificationOpen: (value: boolean) => void;
    setSuccessfulNotificationOpen: (value: boolean) => void;
    client: any;
};

export const UpdatePanel = ({
    roomId,
    setFailedNotificationOpen,
    setSuccessfulNotificationOpen,
    client,
}: TUpdatePanelProps) => {
    const [isManualChange, setIsManualChange] = useState(false);
    const [isUpdatePostLoading, setIsUpdatePostLoading] = useState(false);
    const [fileReady, setFileReady] = useState(false);
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);

    return (
        <TabPanel value={1}>
            <form
                noValidate
                onSubmit={async (e) => {
                    e.preventDefault();
                    setIsUpdatePostLoading(true);
                    const formElement = e.target as HTMLFormElement;
                    const form = new FormData(e.target as HTMLFormElement);
                    const file = form.get("file") as File;

                    const isVideo = file && file.type.startsWith("video/");

                    let imageFileName = file.name;

                    if (isVideo) {
                        // Extract the first frame from the video
                        const video = document.createElement("video");
                        video.src = URL.createObjectURL(file);
                        await new Promise((resolve) => {
                            video.onloadeddata = () => {
                                video.currentTime = 1;
                                resolve(null);
                            };
                        });

                        const canvas = document.createElement("canvas");
                        canvas.width = video.videoWidth;
                        canvas.height = video.videoHeight;
                        const context = canvas.getContext("2d");

                        if (context) {
                            await new Promise((resolve) => {
                                video.onseeked = () => {
                                    context.drawImage(video, 0, 0, canvas.width, canvas.height);
                                    resolve(null);
                                };
                                video.currentTime = 1;
                            });

                            const imageBlob = await new Promise<Blob>((resolve, reject) => {
                                canvas.toBlob((blob) => {
                                    if (blob) {
                                        resolve(blob);
                                    } else {
                                        reject(new Error("Failed to create blob from canvas"));
                                    }
                                });
                            });
                            const imageFile = new File([imageBlob], `${file.name.split(".")[0]}.jpg`, {
                                type: "image/jpeg",
                            });
                            imageFileName = "image-" + imageFile.name;

                            // Upload the image file
                            uploadData({
                                key: imageFileName,
                                data: imageFile,
                            });
                        } else {
                            console.error("Failed to get 2D context");
                            setFailedNotificationOpen(true);
                            setIsUpdatePostLoading(false);
                            return;
                        }
                    }

                    const data = {
                        update: form.get("update") as string,
                        created_at: form.get("time") as string,
                        show_time: form.has("show_time"),
                        image: isVideo ? imageFileName : file.name,
                        video: isVideo ? file.name : undefined,
                        room_id: roomId,
                    };

                    if (!data.update.trim() || isNaN(Date.parse(data.created_at))) {
                        setFailedNotificationOpen(true);
                        setIsUpdatePostLoading(false);
                        return;
                    }

                    try {
                        if (file) {
                            try {
                                const uploadResult = uploadData({
                                    key: data.video || data.image,
                                    data: file,
                                    options: {
                                        onProgress: ({ transferredBytes, totalBytes }) => {
                                            if (totalBytes) {
                                                setUploadProgress(Math.round((transferredBytes / totalBytes) * 100));
                                            }
                                        },
                                    },
                                });

                                await uploadResult.result;
                                setUploadProgress(null);
                            } catch (error) {
                                console.error("Error uploading file:", error);
                                setFailedNotificationOpen(true);
                                setIsUpdatePostLoading(false);
                                setUploadProgress(null);
                                return;
                            }
                        }

                        await client.graphql({
                            query: createRoomUpdate,
                            variables: { input: data },
                        });
                        setIsUpdatePostLoading(false);
                        formElement.reset();
                        setSuccessfulNotificationOpen(true);
                        setFileReady(false);
                    } catch (error) {
                        console.error("Error creating room update:", error);
                        setFailedNotificationOpen(true);
                        setIsUpdatePostLoading(false);
                    }
                }}
            >
                <Card
                    variant="soft"
                    sx={(theme) => ({
                        minWidth: 340,
                        [theme.breakpoints.up(440)]: {
                            minWidth: 420,
                        },
                    })}
                    color="neutral"
                    size="md"
                >
                    <CardContent orientation="horizontal">
                        <Button
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                            startDecorator={fileReady ? <CheckboxIcon /> : <UploadIcon />}
                            sx={{ width: "100%" }}
                            variant="solid"
                            disabled={typeof uploadProgress === "number"}
                        >
                            {fileReady ? "File Ready" : "Upload a picture or video"}
                            <Input
                                type="file"
                                name="file"
                                sx={{
                                    clip: "rect(0 0 0 0)",
                                    clipPath: "inset(50%)",
                                    overflow: "hidden",
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    whiteSpace: "nowrap",
                                    width: "1px",
                                    height: "1px",
                                }}
                                onChange={(e) => setFileReady(e.target.files ? true : false)}
                                disabled={typeof uploadProgress === "number"}
                            />
                        </Button>
                        {fileReady && (
                            <Chip
                                size="md"
                                variant="solid"
                                color="danger"
                                onClick={(e) => {
                                    e.preventDefault();
                                    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
                                    if (fileInput) fileInput.value = ""; // Clear the file input
                                    setFileReady(false); // Set fileReady state to false
                                }}
                                sx={{
                                    "--Chip-radius": "6px",
                                    "font-weight": "600",
                                }}
                                disabled={typeof uploadProgress === "number"}
                            >
                                Remove
                            </Chip>
                        )}
                    </CardContent>
                    <CardContent>
                        <FormControl>
                            <FormLabel>Update</FormLabel>
                            <Input
                                required
                                placeholder="We are hungry"
                                name="update"
                                disabled={typeof uploadProgress === "number"}
                            />
                        </FormControl>
                    </CardContent>
                    <TimeInput isManualChange={isManualChange} setIsManualChange={setIsManualChange} />
                    {uploadProgress ? (
                        <CardContent>
                            <LinearProgress size="lg" determinate value={uploadProgress} />
                        </CardContent>
                    ) : (
                        <CardContent>
                            <FormControl>
                                <Button loading={isUpdatePostLoading} type="submit">
                                    Post
                                </Button>
                            </FormControl>
                        </CardContent>
                    )}
                </Card>
            </form>
        </TabPanel>
    );
};
