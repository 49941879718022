/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
    id?: string | null;
    first_name: string;
    last_name: string;
    email: string;
};

export type ModelUserConditionInput = {
    first_name?: ModelStringInput | null;
    last_name?: ModelStringInput | null;
    email?: ModelStringInput | null;
    and?: Array<ModelUserConditionInput | null> | null;
    or?: Array<ModelUserConditionInput | null> | null;
    not?: ModelUserConditionInput | null;
};

export type ModelStringInput = {
    ne?: string | null;
    eq?: string | null;
    le?: string | null;
    lt?: string | null;
    ge?: string | null;
    gt?: string | null;
    contains?: string | null;
    notContains?: string | null;
    between?: Array<string | null> | null;
    beginsWith?: string | null;
    attributeExists?: boolean | null;
    attributeType?: ModelAttributeTypes | null;
    size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
    binary = "binary",
    binarySet = "binarySet",
    bool = "bool",
    list = "list",
    map = "map",
    number = "number",
    numberSet = "numberSet",
    string = "string",
    stringSet = "stringSet",
    _null = "_null",
}

export type ModelSizeInput = {
    ne?: number | null;
    eq?: number | null;
    le?: number | null;
    lt?: number | null;
    ge?: number | null;
    gt?: number | null;
    between?: Array<number | null> | null;
};

export type User = {
    __typename: "User";
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    rooms?: ModelRoomConnection | null;
    updateComments?: ModelUpdateCommentsConnection | null;
    roomAccessRequests?: ModelRoomAccessRequestsConnection | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
};

export type ModelRoomConnection = {
    __typename: "ModelRoomConnection";
    items: Array<Room | null>;
    nextToken?: string | null;
};

export type Room = {
    __typename: "Room";
    id: string;
    user_id: string;
    private: boolean;
    name: string;
    private_code?: string | null;
    allowComments?: boolean | null;
    room_updates?: ModelRoomUpdateConnection | null;
    room_recipients?: ModelRoomRecipientsConnection | null;
    sent_texts?: ModelSentTextsConnection | null;
    room_access_requests?: ModelRoomAccessRequestsConnection | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
};

export type ModelRoomUpdateConnection = {
    __typename: "ModelRoomUpdateConnection";
    items: Array<RoomUpdate | null>;
    nextToken?: string | null;
};

export type RoomUpdate = {
    __typename: "RoomUpdate";
    id: string;
    update?: string | null;
    image?: string | null;
    video?: string | null;
    created_at: string;
    show_time?: boolean | null;
    room_id: string;
    comments?: ModelUpdateCommentsConnection | null;
    reactions?: ModelUpdateReactionsConnection | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
};

export type ModelUpdateCommentsConnection = {
    __typename: "ModelUpdateCommentsConnection";
    items: Array<UpdateComments | null>;
    nextToken?: string | null;
};

export type UpdateComments = {
    __typename: "UpdateComments";
    id: string;
    comment: string;
    room_update_id: string;
    user_id: string;
    user_info?: User | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
};

export type ModelUpdateReactionsConnection = {
    __typename: "ModelUpdateReactionsConnection";
    items: Array<UpdateReactions | null>;
    nextToken?: string | null;
};

export type UpdateReactions = {
    __typename: "UpdateReactions";
    id: string;
    type: string;
    room_update_id: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
};

export type ModelRoomRecipientsConnection = {
    __typename: "ModelRoomRecipientsConnection";
    items: Array<RoomRecipients | null>;
    nextToken?: string | null;
};

export type RoomRecipients = {
    __typename: "RoomRecipients";
    id: string;
    phone_number: string;
    created_at: string;
    room_id: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
};

export type ModelSentTextsConnection = {
    __typename: "ModelSentTextsConnection";
    items: Array<SentTexts | null>;
    nextToken?: string | null;
};

export type SentTexts = {
    __typename: "SentTexts";
    id: string;
    message: string;
    phone_number: string;
    success: boolean;
    created_at: string;
    room_id: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
};

export type ModelRoomAccessRequestsConnection = {
    __typename: "ModelRoomAccessRequestsConnection";
    items: Array<RoomAccessRequests | null>;
    nextToken?: string | null;
};

export type RoomAccessRequests = {
    __typename: "RoomAccessRequests";
    id: string;
    room_id: string;
    user_id: string;
    user_info?: User | null;
    approved: boolean;
    reviewed: boolean;
    created_at: string;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
};

export type UpdateUserInput = {
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
};

export type DeleteUserInput = {
    id: string;
};

export type CreateRoomInput = {
    id?: string | null;
    user_id: string;
    private: boolean;
    name: string;
    private_code?: string | null;
    allowComments?: boolean | null;
};

export type ModelRoomConditionInput = {
    user_id?: ModelIDInput | null;
    private?: ModelBooleanInput | null;
    name?: ModelStringInput | null;
    private_code?: ModelStringInput | null;
    allowComments?: ModelBooleanInput | null;
    and?: Array<ModelRoomConditionInput | null> | null;
    or?: Array<ModelRoomConditionInput | null> | null;
    not?: ModelRoomConditionInput | null;
};

export type ModelIDInput = {
    ne?: string | null;
    eq?: string | null;
    le?: string | null;
    lt?: string | null;
    ge?: string | null;
    gt?: string | null;
    contains?: string | null;
    notContains?: string | null;
    between?: Array<string | null> | null;
    beginsWith?: string | null;
    attributeExists?: boolean | null;
    attributeType?: ModelAttributeTypes | null;
    size?: ModelSizeInput | null;
};

export type ModelBooleanInput = {
    ne?: boolean | null;
    eq?: boolean | null;
    attributeExists?: boolean | null;
    attributeType?: ModelAttributeTypes | null;
};

export type UpdateRoomInput = {
    id: string;
    user_id?: string | null;
    private?: boolean | null;
    name?: string | null;
    private_code?: string | null;
    allowComments?: boolean | null;
};

export type DeleteRoomInput = {
    id: string;
};

export type CreateRoomUpdateInput = {
    id?: string | null;
    update?: string | null;
    image?: string | null;
    video?: string | null;
    created_at: string;
    show_time?: boolean | null;
    room_id: string;
};

export type ModelRoomUpdateConditionInput = {
    update?: ModelStringInput | null;
    image?: ModelStringInput | null;
    video?: ModelStringInput | null;
    created_at?: ModelStringInput | null;
    show_time?: ModelBooleanInput | null;
    room_id?: ModelIDInput | null;
    and?: Array<ModelRoomUpdateConditionInput | null> | null;
    or?: Array<ModelRoomUpdateConditionInput | null> | null;
    not?: ModelRoomUpdateConditionInput | null;
};

export type UpdateRoomUpdateInput = {
    id: string;
    update?: string | null;
    image?: string | null;
    video?: string | null;
    created_at?: string | null;
    show_time?: boolean | null;
    room_id?: string | null;
};

export type DeleteRoomUpdateInput = {
    id: string;
};

export type CreateRoomRecipientsInput = {
    id?: string | null;
    phone_number: string;
    created_at: string;
    room_id: string;
};

export type ModelRoomRecipientsConditionInput = {
    phone_number?: ModelStringInput | null;
    created_at?: ModelStringInput | null;
    room_id?: ModelIDInput | null;
    and?: Array<ModelRoomRecipientsConditionInput | null> | null;
    or?: Array<ModelRoomRecipientsConditionInput | null> | null;
    not?: ModelRoomRecipientsConditionInput | null;
};

export type UpdateRoomRecipientsInput = {
    id: string;
    phone_number?: string | null;
    created_at?: string | null;
    room_id?: string | null;
};

export type DeleteRoomRecipientsInput = {
    id: string;
};

export type CreateSentTextsInput = {
    id?: string | null;
    message: string;
    phone_number: string;
    success: boolean;
    created_at: string;
    room_id: string;
};

export type ModelSentTextsConditionInput = {
    message?: ModelStringInput | null;
    phone_number?: ModelStringInput | null;
    success?: ModelBooleanInput | null;
    created_at?: ModelStringInput | null;
    room_id?: ModelIDInput | null;
    and?: Array<ModelSentTextsConditionInput | null> | null;
    or?: Array<ModelSentTextsConditionInput | null> | null;
    not?: ModelSentTextsConditionInput | null;
};

export type UpdateSentTextsInput = {
    id: string;
    message?: string | null;
    phone_number?: string | null;
    success?: boolean | null;
    created_at?: string | null;
    room_id?: string | null;
};

export type DeleteSentTextsInput = {
    id: string;
};

export type CreateRoomAccessRequestsInput = {
    id?: string | null;
    room_id: string;
    user_id: string;
    approved: boolean;
    reviewed: boolean;
    created_at: string;
};

export type ModelRoomAccessRequestsConditionInput = {
    room_id?: ModelIDInput | null;
    user_id?: ModelIDInput | null;
    approved?: ModelBooleanInput | null;
    reviewed?: ModelBooleanInput | null;
    created_at?: ModelStringInput | null;
    and?: Array<ModelRoomAccessRequestsConditionInput | null> | null;
    or?: Array<ModelRoomAccessRequestsConditionInput | null> | null;
    not?: ModelRoomAccessRequestsConditionInput | null;
};

export type UpdateRoomAccessRequestsInput = {
    id: string;
    room_id?: string | null;
    user_id?: string | null;
    approved?: boolean | null;
    reviewed?: boolean | null;
    created_at?: string | null;
};

export type DeleteRoomAccessRequestsInput = {
    id: string;
};

export type CreateUpdateCommentsInput = {
    id?: string | null;
    comment: string;
    room_update_id: string;
    user_id: string;
};

export type ModelUpdateCommentsConditionInput = {
    comment?: ModelStringInput | null;
    room_update_id?: ModelIDInput | null;
    user_id?: ModelIDInput | null;
    and?: Array<ModelUpdateCommentsConditionInput | null> | null;
    or?: Array<ModelUpdateCommentsConditionInput | null> | null;
    not?: ModelUpdateCommentsConditionInput | null;
};

export type UpdateUpdateCommentsInput = {
    id: string;
    comment?: string | null;
    room_update_id?: string | null;
    user_id?: string | null;
};

export type DeleteUpdateCommentsInput = {
    id: string;
};

export type CreateUpdateReactionsInput = {
    id?: string | null;
    type: string;
    room_update_id: string;
};

export type ModelUpdateReactionsConditionInput = {
    type?: ModelStringInput | null;
    room_update_id?: ModelIDInput | null;
    and?: Array<ModelUpdateReactionsConditionInput | null> | null;
    or?: Array<ModelUpdateReactionsConditionInput | null> | null;
    not?: ModelUpdateReactionsConditionInput | null;
};

export type UpdateUpdateReactionsInput = {
    id: string;
    type?: string | null;
    room_update_id?: string | null;
};

export type DeleteUpdateReactionsInput = {
    id: string;
};

export type ModelUserFilterInput = {
    id?: ModelIDInput | null;
    first_name?: ModelStringInput | null;
    last_name?: ModelStringInput | null;
    email?: ModelStringInput | null;
    and?: Array<ModelUserFilterInput | null> | null;
    or?: Array<ModelUserFilterInput | null> | null;
    not?: ModelUserFilterInput | null;
};

export type ModelUserConnection = {
    __typename: "ModelUserConnection";
    items: Array<User | null>;
    nextToken?: string | null;
};

export type ModelRoomFilterInput = {
    id?: ModelIDInput | null;
    user_id?: ModelIDInput | null;
    private?: ModelBooleanInput | null;
    name?: ModelStringInput | null;
    private_code?: ModelStringInput | null;
    allowComments?: ModelBooleanInput | null;
    and?: Array<ModelRoomFilterInput | null> | null;
    or?: Array<ModelRoomFilterInput | null> | null;
    not?: ModelRoomFilterInput | null;
};

export enum ModelSortDirection {
    ASC = "ASC",
    DESC = "DESC",
}

export type ModelIDKeyConditionInput = {
    eq?: string | null;
    le?: string | null;
    lt?: string | null;
    ge?: string | null;
    gt?: string | null;
    between?: Array<string | null> | null;
    beginsWith?: string | null;
};

export type ModelRoomUpdateFilterInput = {
    id?: ModelIDInput | null;
    update?: ModelStringInput | null;
    image?: ModelStringInput | null;
    video?: ModelStringInput | null;
    created_at?: ModelStringInput | null;
    show_time?: ModelBooleanInput | null;
    room_id?: ModelIDInput | null;
    and?: Array<ModelRoomUpdateFilterInput | null> | null;
    or?: Array<ModelRoomUpdateFilterInput | null> | null;
    not?: ModelRoomUpdateFilterInput | null;
};

export type ModelStringKeyConditionInput = {
    eq?: string | null;
    le?: string | null;
    lt?: string | null;
    ge?: string | null;
    gt?: string | null;
    between?: Array<string | null> | null;
    beginsWith?: string | null;
};

export type ModelRoomRecipientsFilterInput = {
    id?: ModelIDInput | null;
    phone_number?: ModelStringInput | null;
    created_at?: ModelStringInput | null;
    room_id?: ModelIDInput | null;
    and?: Array<ModelRoomRecipientsFilterInput | null> | null;
    or?: Array<ModelRoomRecipientsFilterInput | null> | null;
    not?: ModelRoomRecipientsFilterInput | null;
};

export type ModelSentTextsFilterInput = {
    id?: ModelIDInput | null;
    message?: ModelStringInput | null;
    phone_number?: ModelStringInput | null;
    success?: ModelBooleanInput | null;
    created_at?: ModelStringInput | null;
    room_id?: ModelIDInput | null;
    and?: Array<ModelSentTextsFilterInput | null> | null;
    or?: Array<ModelSentTextsFilterInput | null> | null;
    not?: ModelSentTextsFilterInput | null;
};

export type ModelRoomAccessRequestsFilterInput = {
    id?: ModelIDInput | null;
    room_id?: ModelIDInput | null;
    user_id?: ModelIDInput | null;
    approved?: ModelBooleanInput | null;
    reviewed?: ModelBooleanInput | null;
    created_at?: ModelStringInput | null;
    and?: Array<ModelRoomAccessRequestsFilterInput | null> | null;
    or?: Array<ModelRoomAccessRequestsFilterInput | null> | null;
    not?: ModelRoomAccessRequestsFilterInput | null;
};

export type ModelUpdateCommentsFilterInput = {
    id?: ModelIDInput | null;
    comment?: ModelStringInput | null;
    room_update_id?: ModelIDInput | null;
    user_id?: ModelIDInput | null;
    and?: Array<ModelUpdateCommentsFilterInput | null> | null;
    or?: Array<ModelUpdateCommentsFilterInput | null> | null;
    not?: ModelUpdateCommentsFilterInput | null;
};

export type ModelUpdateReactionsFilterInput = {
    id?: ModelIDInput | null;
    type?: ModelStringInput | null;
    room_update_id?: ModelIDInput | null;
    and?: Array<ModelUpdateReactionsFilterInput | null> | null;
    or?: Array<ModelUpdateReactionsFilterInput | null> | null;
    not?: ModelUpdateReactionsFilterInput | null;
};

export type ModelSubscriptionUserFilterInput = {
    id?: ModelSubscriptionIDInput | null;
    first_name?: ModelSubscriptionStringInput | null;
    last_name?: ModelSubscriptionStringInput | null;
    email?: ModelSubscriptionStringInput | null;
    and?: Array<ModelSubscriptionUserFilterInput | null> | null;
    or?: Array<ModelSubscriptionUserFilterInput | null> | null;
};

export type ModelSubscriptionIDInput = {
    ne?: string | null;
    eq?: string | null;
    le?: string | null;
    lt?: string | null;
    ge?: string | null;
    gt?: string | null;
    contains?: string | null;
    notContains?: string | null;
    between?: Array<string | null> | null;
    beginsWith?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
};

export type ModelSubscriptionStringInput = {
    ne?: string | null;
    eq?: string | null;
    le?: string | null;
    lt?: string | null;
    ge?: string | null;
    gt?: string | null;
    contains?: string | null;
    notContains?: string | null;
    between?: Array<string | null> | null;
    beginsWith?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
};

export type ModelSubscriptionRoomFilterInput = {
    id?: ModelSubscriptionIDInput | null;
    user_id?: ModelSubscriptionIDInput | null;
    private?: ModelSubscriptionBooleanInput | null;
    name?: ModelSubscriptionStringInput | null;
    private_code?: ModelSubscriptionStringInput | null;
    allowComments?: ModelSubscriptionBooleanInput | null;
    and?: Array<ModelSubscriptionRoomFilterInput | null> | null;
    or?: Array<ModelSubscriptionRoomFilterInput | null> | null;
};

export type ModelSubscriptionBooleanInput = {
    ne?: boolean | null;
    eq?: boolean | null;
};

export type ModelSubscriptionRoomUpdateFilterInput = {
    id?: ModelSubscriptionIDInput | null;
    update?: ModelSubscriptionStringInput | null;
    image?: ModelSubscriptionStringInput | null;
    video?: ModelSubscriptionStringInput | null;
    created_at?: ModelSubscriptionStringInput | null;
    show_time?: ModelSubscriptionBooleanInput | null;
    room_id?: ModelSubscriptionIDInput | null;
    and?: Array<ModelSubscriptionRoomUpdateFilterInput | null> | null;
    or?: Array<ModelSubscriptionRoomUpdateFilterInput | null> | null;
};

export type ModelSubscriptionRoomRecipientsFilterInput = {
    id?: ModelSubscriptionIDInput | null;
    phone_number?: ModelSubscriptionStringInput | null;
    created_at?: ModelSubscriptionStringInput | null;
    room_id?: ModelSubscriptionIDInput | null;
    and?: Array<ModelSubscriptionRoomRecipientsFilterInput | null> | null;
    or?: Array<ModelSubscriptionRoomRecipientsFilterInput | null> | null;
};

export type ModelSubscriptionSentTextsFilterInput = {
    id?: ModelSubscriptionIDInput | null;
    message?: ModelSubscriptionStringInput | null;
    phone_number?: ModelSubscriptionStringInput | null;
    success?: ModelSubscriptionBooleanInput | null;
    created_at?: ModelSubscriptionStringInput | null;
    room_id?: ModelSubscriptionIDInput | null;
    and?: Array<ModelSubscriptionSentTextsFilterInput | null> | null;
    or?: Array<ModelSubscriptionSentTextsFilterInput | null> | null;
};

export type ModelSubscriptionRoomAccessRequestsFilterInput = {
    id?: ModelSubscriptionIDInput | null;
    room_id?: ModelSubscriptionIDInput | null;
    user_id?: ModelSubscriptionIDInput | null;
    approved?: ModelSubscriptionBooleanInput | null;
    reviewed?: ModelSubscriptionBooleanInput | null;
    created_at?: ModelSubscriptionStringInput | null;
    and?: Array<ModelSubscriptionRoomAccessRequestsFilterInput | null> | null;
    or?: Array<ModelSubscriptionRoomAccessRequestsFilterInput | null> | null;
};

export type ModelSubscriptionUpdateCommentsFilterInput = {
    id?: ModelSubscriptionIDInput | null;
    comment?: ModelSubscriptionStringInput | null;
    room_update_id?: ModelSubscriptionIDInput | null;
    user_id?: ModelSubscriptionIDInput | null;
    and?: Array<ModelSubscriptionUpdateCommentsFilterInput | null> | null;
    or?: Array<ModelSubscriptionUpdateCommentsFilterInput | null> | null;
};

export type ModelSubscriptionUpdateReactionsFilterInput = {
    id?: ModelSubscriptionIDInput | null;
    type?: ModelSubscriptionStringInput | null;
    room_update_id?: ModelSubscriptionIDInput | null;
    and?: Array<ModelSubscriptionUpdateReactionsFilterInput | null> | null;
    or?: Array<ModelSubscriptionUpdateReactionsFilterInput | null> | null;
};

export type CreateUserMutationVariables = {
    input: CreateUserInput;
    condition?: ModelUserConditionInput | null;
};

export type CreateUserMutation = {
    createUser?: {
        __typename: "User";
        id: string;
        first_name: string;
        last_name: string;
        email: string;
        rooms?: {
            __typename: "ModelRoomConnection";
            nextToken?: string | null;
        } | null;
        updateComments?: {
            __typename: "ModelUpdateCommentsConnection";
            nextToken?: string | null;
        } | null;
        roomAccessRequests?: {
            __typename: "ModelRoomAccessRequestsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type UpdateUserMutationVariables = {
    input: UpdateUserInput;
    condition?: ModelUserConditionInput | null;
};

export type UpdateUserMutation = {
    updateUser?: {
        __typename: "User";
        id: string;
        first_name: string;
        last_name: string;
        email: string;
        rooms?: {
            __typename: "ModelRoomConnection";
            nextToken?: string | null;
        } | null;
        updateComments?: {
            __typename: "ModelUpdateCommentsConnection";
            nextToken?: string | null;
        } | null;
        roomAccessRequests?: {
            __typename: "ModelRoomAccessRequestsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type DeleteUserMutationVariables = {
    input: DeleteUserInput;
    condition?: ModelUserConditionInput | null;
};

export type DeleteUserMutation = {
    deleteUser?: {
        __typename: "User";
        id: string;
        first_name: string;
        last_name: string;
        email: string;
        rooms?: {
            __typename: "ModelRoomConnection";
            nextToken?: string | null;
        } | null;
        updateComments?: {
            __typename: "ModelUpdateCommentsConnection";
            nextToken?: string | null;
        } | null;
        roomAccessRequests?: {
            __typename: "ModelRoomAccessRequestsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type CreateRoomMutationVariables = {
    input: CreateRoomInput;
    condition?: ModelRoomConditionInput | null;
};

export type CreateRoomMutation = {
    createRoom?: {
        __typename: "Room";
        id: string;
        user_id: string;
        private: boolean;
        name: string;
        private_code?: string | null;
        allowComments?: boolean | null;
        room_updates?: {
            __typename: "ModelRoomUpdateConnection";
            nextToken?: string | null;
        } | null;
        room_recipients?: {
            __typename: "ModelRoomRecipientsConnection";
            nextToken?: string | null;
        } | null;
        sent_texts?: {
            __typename: "ModelSentTextsConnection";
            nextToken?: string | null;
        } | null;
        room_access_requests?: {
            __typename: "ModelRoomAccessRequestsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type UpdateRoomMutationVariables = {
    input: UpdateRoomInput;
    condition?: ModelRoomConditionInput | null;
};

export type UpdateRoomMutation = {
    updateRoom?: {
        __typename: "Room";
        id: string;
        user_id: string;
        private: boolean;
        name: string;
        private_code?: string | null;
        allowComments?: boolean | null;
        room_updates?: {
            __typename: "ModelRoomUpdateConnection";
            nextToken?: string | null;
        } | null;
        room_recipients?: {
            __typename: "ModelRoomRecipientsConnection";
            nextToken?: string | null;
        } | null;
        sent_texts?: {
            __typename: "ModelSentTextsConnection";
            nextToken?: string | null;
        } | null;
        room_access_requests?: {
            __typename: "ModelRoomAccessRequestsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type DeleteRoomMutationVariables = {
    input: DeleteRoomInput;
    condition?: ModelRoomConditionInput | null;
};

export type DeleteRoomMutation = {
    deleteRoom?: {
        __typename: "Room";
        id: string;
        user_id: string;
        private: boolean;
        name: string;
        private_code?: string | null;
        allowComments?: boolean | null;
        room_updates?: {
            __typename: "ModelRoomUpdateConnection";
            nextToken?: string | null;
        } | null;
        room_recipients?: {
            __typename: "ModelRoomRecipientsConnection";
            nextToken?: string | null;
        } | null;
        sent_texts?: {
            __typename: "ModelSentTextsConnection";
            nextToken?: string | null;
        } | null;
        room_access_requests?: {
            __typename: "ModelRoomAccessRequestsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type CreateRoomUpdateMutationVariables = {
    input: CreateRoomUpdateInput;
    condition?: ModelRoomUpdateConditionInput | null;
};

export type CreateRoomUpdateMutation = {
    createRoomUpdate?: {
        __typename: "RoomUpdate";
        id: string;
        update?: string | null;
        image?: string | null;
        video?: string | null;
        created_at: string;
        show_time?: boolean | null;
        room_id: string;
        comments?: {
            __typename: "ModelUpdateCommentsConnection";
            nextToken?: string | null;
        } | null;
        reactions?: {
            __typename: "ModelUpdateReactionsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type UpdateRoomUpdateMutationVariables = {
    input: UpdateRoomUpdateInput;
    condition?: ModelRoomUpdateConditionInput | null;
};

export type UpdateRoomUpdateMutation = {
    updateRoomUpdate?: {
        __typename: "RoomUpdate";
        id: string;
        update?: string | null;
        image?: string | null;
        video?: string | null;
        created_at: string;
        show_time?: boolean | null;
        room_id: string;
        comments?: {
            __typename: "ModelUpdateCommentsConnection";
            nextToken?: string | null;
        } | null;
        reactions?: {
            __typename: "ModelUpdateReactionsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type DeleteRoomUpdateMutationVariables = {
    input: DeleteRoomUpdateInput;
    condition?: ModelRoomUpdateConditionInput | null;
};

export type DeleteRoomUpdateMutation = {
    deleteRoomUpdate?: {
        __typename: "RoomUpdate";
        id: string;
        update?: string | null;
        image?: string | null;
        video?: string | null;
        created_at: string;
        show_time?: boolean | null;
        room_id: string;
        comments?: {
            __typename: "ModelUpdateCommentsConnection";
            nextToken?: string | null;
        } | null;
        reactions?: {
            __typename: "ModelUpdateReactionsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type CreateRoomRecipientsMutationVariables = {
    input: CreateRoomRecipientsInput;
    condition?: ModelRoomRecipientsConditionInput | null;
};

export type CreateRoomRecipientsMutation = {
    createRoomRecipients?: {
        __typename: "RoomRecipients";
        id: string;
        phone_number: string;
        created_at: string;
        room_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type UpdateRoomRecipientsMutationVariables = {
    input: UpdateRoomRecipientsInput;
    condition?: ModelRoomRecipientsConditionInput | null;
};

export type UpdateRoomRecipientsMutation = {
    updateRoomRecipients?: {
        __typename: "RoomRecipients";
        id: string;
        phone_number: string;
        created_at: string;
        room_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type DeleteRoomRecipientsMutationVariables = {
    input: DeleteRoomRecipientsInput;
    condition?: ModelRoomRecipientsConditionInput | null;
};

export type DeleteRoomRecipientsMutation = {
    deleteRoomRecipients?: {
        __typename: "RoomRecipients";
        id: string;
        phone_number: string;
        created_at: string;
        room_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type CreateSentTextsMutationVariables = {
    input: CreateSentTextsInput;
    condition?: ModelSentTextsConditionInput | null;
};

export type CreateSentTextsMutation = {
    createSentTexts?: {
        __typename: "SentTexts";
        id: string;
        message: string;
        phone_number: string;
        success: boolean;
        created_at: string;
        room_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type UpdateSentTextsMutationVariables = {
    input: UpdateSentTextsInput;
    condition?: ModelSentTextsConditionInput | null;
};

export type UpdateSentTextsMutation = {
    updateSentTexts?: {
        __typename: "SentTexts";
        id: string;
        message: string;
        phone_number: string;
        success: boolean;
        created_at: string;
        room_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type DeleteSentTextsMutationVariables = {
    input: DeleteSentTextsInput;
    condition?: ModelSentTextsConditionInput | null;
};

export type DeleteSentTextsMutation = {
    deleteSentTexts?: {
        __typename: "SentTexts";
        id: string;
        message: string;
        phone_number: string;
        success: boolean;
        created_at: string;
        room_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type CreateRoomAccessRequestsMutationVariables = {
    input: CreateRoomAccessRequestsInput;
    condition?: ModelRoomAccessRequestsConditionInput | null;
};

export type CreateRoomAccessRequestsMutation = {
    createRoomAccessRequests?: {
        __typename: "RoomAccessRequests";
        id: string;
        room_id: string;
        user_id: string;
        user_info?: {
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null;
        approved: boolean;
        reviewed: boolean;
        created_at: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type UpdateRoomAccessRequestsMutationVariables = {
    input: UpdateRoomAccessRequestsInput;
    condition?: ModelRoomAccessRequestsConditionInput | null;
};

export type UpdateRoomAccessRequestsMutation = {
    updateRoomAccessRequests?: {
        __typename: "RoomAccessRequests";
        id: string;
        room_id: string;
        user_id: string;
        user_info?: {
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null;
        approved: boolean;
        reviewed: boolean;
        created_at: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type DeleteRoomAccessRequestsMutationVariables = {
    input: DeleteRoomAccessRequestsInput;
    condition?: ModelRoomAccessRequestsConditionInput | null;
};

export type DeleteRoomAccessRequestsMutation = {
    deleteRoomAccessRequests?: {
        __typename: "RoomAccessRequests";
        id: string;
        room_id: string;
        user_id: string;
        user_info?: {
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null;
        approved: boolean;
        reviewed: boolean;
        created_at: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type CreateUpdateCommentsMutationVariables = {
    input: CreateUpdateCommentsInput;
    condition?: ModelUpdateCommentsConditionInput | null;
};

export type CreateUpdateCommentsMutation = {
    createUpdateComments?: {
        __typename: "UpdateComments";
        id: string;
        comment: string;
        room_update_id: string;
        user_id: string;
        user_info?: {
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type UpdateUpdateCommentsMutationVariables = {
    input: UpdateUpdateCommentsInput;
    condition?: ModelUpdateCommentsConditionInput | null;
};

export type UpdateUpdateCommentsMutation = {
    updateUpdateComments?: {
        __typename: "UpdateComments";
        id: string;
        comment: string;
        room_update_id: string;
        user_id: string;
        user_info?: {
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type DeleteUpdateCommentsMutationVariables = {
    input: DeleteUpdateCommentsInput;
    condition?: ModelUpdateCommentsConditionInput | null;
};

export type DeleteUpdateCommentsMutation = {
    deleteUpdateComments?: {
        __typename: "UpdateComments";
        id: string;
        comment: string;
        room_update_id: string;
        user_id: string;
        user_info?: {
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type CreateUpdateReactionsMutationVariables = {
    input: CreateUpdateReactionsInput;
    condition?: ModelUpdateReactionsConditionInput | null;
};

export type CreateUpdateReactionsMutation = {
    createUpdateReactions?: {
        __typename: "UpdateReactions";
        id: string;
        type: string;
        room_update_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type UpdateUpdateReactionsMutationVariables = {
    input: UpdateUpdateReactionsInput;
    condition?: ModelUpdateReactionsConditionInput | null;
};

export type UpdateUpdateReactionsMutation = {
    updateUpdateReactions?: {
        __typename: "UpdateReactions";
        id: string;
        type: string;
        room_update_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type DeleteUpdateReactionsMutationVariables = {
    input: DeleteUpdateReactionsInput;
    condition?: ModelUpdateReactionsConditionInput | null;
};

export type DeleteUpdateReactionsMutation = {
    deleteUpdateReactions?: {
        __typename: "UpdateReactions";
        id: string;
        type: string;
        room_update_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type GetUserQueryVariables = {
    id: string;
};

export type GetUserQuery = {
    getUser?: {
        __typename: "User";
        id: string;
        first_name: string;
        last_name: string;
        email: string;
        rooms?: {
            __typename: "ModelRoomConnection";
            nextToken?: string | null;
        } | null;
        updateComments?: {
            __typename: "ModelUpdateCommentsConnection";
            nextToken?: string | null;
        } | null;
        roomAccessRequests?: {
            __typename: "ModelRoomAccessRequestsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type ListUsersQueryVariables = {
    filter?: ModelUserFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListUsersQuery = {
    listUsers?: {
        __typename: "ModelUserConnection";
        items: Array<{
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type GetRoomQueryVariables = {
    id: string;
};

export type GetRoomQuery = {
    getRoom?: {
        __typename: "Room";
        id: string;
        user_id: string;
        private: boolean;
        name: string;
        private_code?: string | null;
        allowComments?: boolean | null;
        room_updates?: {
            __typename: "ModelRoomUpdateConnection";
            nextToken?: string | null;
        } | null;
        room_recipients?: {
            __typename: "ModelRoomRecipientsConnection";
            nextToken?: string | null;
        } | null;
        sent_texts?: {
            __typename: "ModelSentTextsConnection";
            nextToken?: string | null;
        } | null;
        room_access_requests?: {
            __typename: "ModelRoomAccessRequestsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type ListRoomsQueryVariables = {
    filter?: ModelRoomFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListRoomsQuery = {
    listRooms?: {
        __typename: "ModelRoomConnection";
        items: Array<{
            __typename: "Room";
            id: string;
            user_id: string;
            private: boolean;
            name: string;
            private_code?: string | null;
            allowComments?: boolean | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type RoomsByUser_idQueryVariables = {
    user_id: string;
    sortDirection?: ModelSortDirection | null;
    filter?: ModelRoomFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type RoomsByUser_idQuery = {
    roomsByUser_id?: {
        __typename: "ModelRoomConnection";
        items: Array<{
            __typename: "Room";
            id: string;
            user_id: string;
            private: boolean;
            name: string;
            private_code?: string | null;
            allowComments?: boolean | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type RoomByNameQueryVariables = {
    name: string;
    id?: ModelIDKeyConditionInput | null;
    sortDirection?: ModelSortDirection | null;
    filter?: ModelRoomFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type RoomByNameQuery = {
    roomByName?: {
        __typename: "ModelRoomConnection";
        items: Array<{
            __typename: "Room";
            id: string;
            user_id: string;
            private: boolean;
            name: string;
            private_code?: string | null;
            allowComments?: boolean | null;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type GetRoomUpdateQueryVariables = {
    id: string;
};

export type GetRoomUpdateQuery = {
    getRoomUpdate?: {
        __typename: "RoomUpdate";
        id: string;
        update?: string | null;
        image?: string | null;
        video?: string | null;
        created_at: string;
        show_time?: boolean | null;
        room_id: string;
        comments?: {
            __typename: "ModelUpdateCommentsConnection";
            nextToken?: string | null;
        } | null;
        reactions?: {
            __typename: "ModelUpdateReactionsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type ListRoomUpdatesQueryVariables = {
    filter?: ModelRoomUpdateFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListRoomUpdatesQuery = {
    listRoomUpdates?: {
        __typename: "ModelRoomUpdateConnection";
        items: Array<{
            __typename: "RoomUpdate";
            id: string;
            update?: string | null;
            image?: string | null;
            video?: string | null;
            created_at: string;
            show_time?: boolean | null;
            room_id: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type RoomUpdatesByRoom_idAndCreated_atQueryVariables = {
    room_id: string;
    created_at?: ModelStringKeyConditionInput | null;
    sortDirection?: ModelSortDirection | null;
    filter?: ModelRoomUpdateFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type RoomUpdatesByRoom_idAndCreated_atQuery = {
    roomUpdatesByRoom_idAndCreated_at?: {
        __typename: "ModelRoomUpdateConnection";
        items: Array<{
            __typename: "RoomUpdate";
            id: string;
            update?: string | null;
            image?: string | null;
            video?: string | null;
            created_at: string;
            show_time?: boolean | null;
            room_id: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type GetRoomRecipientsQueryVariables = {
    id: string;
};

export type GetRoomRecipientsQuery = {
    getRoomRecipients?: {
        __typename: "RoomRecipients";
        id: string;
        phone_number: string;
        created_at: string;
        room_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type ListRoomRecipientsQueryVariables = {
    filter?: ModelRoomRecipientsFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListRoomRecipientsQuery = {
    listRoomRecipients?: {
        __typename: "ModelRoomRecipientsConnection";
        items: Array<{
            __typename: "RoomRecipients";
            id: string;
            phone_number: string;
            created_at: string;
            room_id: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type RoomRecipientsByRoom_idAndCreated_atQueryVariables = {
    room_id: string;
    created_at?: ModelStringKeyConditionInput | null;
    sortDirection?: ModelSortDirection | null;
    filter?: ModelRoomRecipientsFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type RoomRecipientsByRoom_idAndCreated_atQuery = {
    roomRecipientsByRoom_idAndCreated_at?: {
        __typename: "ModelRoomRecipientsConnection";
        items: Array<{
            __typename: "RoomRecipients";
            id: string;
            phone_number: string;
            created_at: string;
            room_id: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type GetSentTextsQueryVariables = {
    id: string;
};

export type GetSentTextsQuery = {
    getSentTexts?: {
        __typename: "SentTexts";
        id: string;
        message: string;
        phone_number: string;
        success: boolean;
        created_at: string;
        room_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type ListSentTextsQueryVariables = {
    filter?: ModelSentTextsFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListSentTextsQuery = {
    listSentTexts?: {
        __typename: "ModelSentTextsConnection";
        items: Array<{
            __typename: "SentTexts";
            id: string;
            message: string;
            phone_number: string;
            success: boolean;
            created_at: string;
            room_id: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type SentTextsByRoom_idAndCreated_atQueryVariables = {
    room_id: string;
    created_at?: ModelStringKeyConditionInput | null;
    sortDirection?: ModelSortDirection | null;
    filter?: ModelSentTextsFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type SentTextsByRoom_idAndCreated_atQuery = {
    sentTextsByRoom_idAndCreated_at?: {
        __typename: "ModelSentTextsConnection";
        items: Array<{
            __typename: "SentTexts";
            id: string;
            message: string;
            phone_number: string;
            success: boolean;
            created_at: string;
            room_id: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type GetRoomAccessRequestsQueryVariables = {
    id: string;
};

export type GetRoomAccessRequestsQuery = {
    getRoomAccessRequests?: {
        __typename: "RoomAccessRequests";
        id: string;
        room_id: string;
        user_id: string;
        user_info?: {
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null;
        approved: boolean;
        reviewed: boolean;
        created_at: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type ListRoomAccessRequestsQueryVariables = {
    filter?: ModelRoomAccessRequestsFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListRoomAccessRequestsQuery = {
    listRoomAccessRequests?: {
        __typename: "ModelRoomAccessRequestsConnection";
        items: Array<{
            __typename: "RoomAccessRequests";
            id: string;
            room_id: string;
            user_id: string;
            approved: boolean;
            reviewed: boolean;
            created_at: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type RoomAccessRequestsByRoom_idAndCreated_atQueryVariables = {
    room_id: string;
    created_at?: ModelStringKeyConditionInput | null;
    sortDirection?: ModelSortDirection | null;
    filter?: ModelRoomAccessRequestsFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type RoomAccessRequestsByRoom_idAndCreated_atQuery = {
    roomAccessRequestsByRoom_idAndCreated_at?: {
        __typename: "ModelRoomAccessRequestsConnection";
        items: Array<{
            __typename: "RoomAccessRequests";
            id: string;
            room_id: string;
            user_id: string;
            approved: boolean;
            reviewed: boolean;
            created_at: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type RoomAccessRequestsByUser_idQueryVariables = {
    user_id: string;
    sortDirection?: ModelSortDirection | null;
    filter?: ModelRoomAccessRequestsFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type RoomAccessRequestsByUser_idQuery = {
    roomAccessRequestsByUser_id?: {
        __typename: "ModelRoomAccessRequestsConnection";
        items: Array<{
            __typename: "RoomAccessRequests";
            id: string;
            room_id: string;
            user_id: string;
            approved: boolean;
            reviewed: boolean;
            created_at: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type GetUpdateCommentsQueryVariables = {
    id: string;
};

export type GetUpdateCommentsQuery = {
    getUpdateComments?: {
        __typename: "UpdateComments";
        id: string;
        comment: string;
        room_update_id: string;
        user_id: string;
        user_info?: {
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type ListUpdateCommentsQueryVariables = {
    filter?: ModelUpdateCommentsFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListUpdateCommentsQuery = {
    listUpdateComments?: {
        __typename: "ModelUpdateCommentsConnection";
        items: Array<{
            __typename: "UpdateComments";
            id: string;
            comment: string;
            room_update_id: string;
            user_id: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type UpdateCommentsByRoom_update_idQueryVariables = {
    room_update_id: string;
    sortDirection?: ModelSortDirection | null;
    filter?: ModelUpdateCommentsFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type UpdateCommentsByRoom_update_idQuery = {
    updateCommentsByRoom_update_id?: {
        __typename: "ModelUpdateCommentsConnection";
        items: Array<{
            __typename: "UpdateComments";
            id: string;
            comment: string;
            room_update_id: string;
            user_id: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type UpdateCommentsByUser_idQueryVariables = {
    user_id: string;
    sortDirection?: ModelSortDirection | null;
    filter?: ModelUpdateCommentsFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type UpdateCommentsByUser_idQuery = {
    updateCommentsByUser_id?: {
        __typename: "ModelUpdateCommentsConnection";
        items: Array<{
            __typename: "UpdateComments";
            id: string;
            comment: string;
            room_update_id: string;
            user_id: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type GetUpdateReactionsQueryVariables = {
    id: string;
};

export type GetUpdateReactionsQuery = {
    getUpdateReactions?: {
        __typename: "UpdateReactions";
        id: string;
        type: string;
        room_update_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type ListUpdateReactionsQueryVariables = {
    filter?: ModelUpdateReactionsFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListUpdateReactionsQuery = {
    listUpdateReactions?: {
        __typename: "ModelUpdateReactionsConnection";
        items: Array<{
            __typename: "UpdateReactions";
            id: string;
            type: string;
            room_update_id: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type UpdateReactionsByRoom_update_idQueryVariables = {
    room_update_id: string;
    sortDirection?: ModelSortDirection | null;
    filter?: ModelUpdateReactionsFilterInput | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type UpdateReactionsByRoom_update_idQuery = {
    updateReactionsByRoom_update_id?: {
        __typename: "ModelUpdateReactionsConnection";
        items: Array<{
            __typename: "UpdateReactions";
            id: string;
            type: string;
            room_update_id: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null>;
        nextToken?: string | null;
    } | null;
};

export type OnCreateUserSubscriptionVariables = {
    filter?: ModelSubscriptionUserFilterInput | null;
    owner?: string | null;
};

export type OnCreateUserSubscription = {
    onCreateUser?: {
        __typename: "User";
        id: string;
        first_name: string;
        last_name: string;
        email: string;
        rooms?: {
            __typename: "ModelRoomConnection";
            nextToken?: string | null;
        } | null;
        updateComments?: {
            __typename: "ModelUpdateCommentsConnection";
            nextToken?: string | null;
        } | null;
        roomAccessRequests?: {
            __typename: "ModelRoomAccessRequestsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnUpdateUserSubscriptionVariables = {
    filter?: ModelSubscriptionUserFilterInput | null;
    owner?: string | null;
};

export type OnUpdateUserSubscription = {
    onUpdateUser?: {
        __typename: "User";
        id: string;
        first_name: string;
        last_name: string;
        email: string;
        rooms?: {
            __typename: "ModelRoomConnection";
            nextToken?: string | null;
        } | null;
        updateComments?: {
            __typename: "ModelUpdateCommentsConnection";
            nextToken?: string | null;
        } | null;
        roomAccessRequests?: {
            __typename: "ModelRoomAccessRequestsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnDeleteUserSubscriptionVariables = {
    filter?: ModelSubscriptionUserFilterInput | null;
    owner?: string | null;
};

export type OnDeleteUserSubscription = {
    onDeleteUser?: {
        __typename: "User";
        id: string;
        first_name: string;
        last_name: string;
        email: string;
        rooms?: {
            __typename: "ModelRoomConnection";
            nextToken?: string | null;
        } | null;
        updateComments?: {
            __typename: "ModelUpdateCommentsConnection";
            nextToken?: string | null;
        } | null;
        roomAccessRequests?: {
            __typename: "ModelRoomAccessRequestsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnCreateRoomSubscriptionVariables = {
    filter?: ModelSubscriptionRoomFilterInput | null;
    owner?: string | null;
};

export type OnCreateRoomSubscription = {
    onCreateRoom?: {
        __typename: "Room";
        id: string;
        user_id: string;
        private: boolean;
        name: string;
        private_code?: string | null;
        allowComments?: boolean | null;
        room_updates?: {
            __typename: "ModelRoomUpdateConnection";
            nextToken?: string | null;
        } | null;
        room_recipients?: {
            __typename: "ModelRoomRecipientsConnection";
            nextToken?: string | null;
        } | null;
        sent_texts?: {
            __typename: "ModelSentTextsConnection";
            nextToken?: string | null;
        } | null;
        room_access_requests?: {
            __typename: "ModelRoomAccessRequestsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnUpdateRoomSubscriptionVariables = {
    filter?: ModelSubscriptionRoomFilterInput | null;
    owner?: string | null;
};

export type OnUpdateRoomSubscription = {
    onUpdateRoom?: {
        __typename: "Room";
        id: string;
        user_id: string;
        private: boolean;
        name: string;
        private_code?: string | null;
        allowComments?: boolean | null;
        room_updates?: {
            __typename: "ModelRoomUpdateConnection";
            nextToken?: string | null;
        } | null;
        room_recipients?: {
            __typename: "ModelRoomRecipientsConnection";
            nextToken?: string | null;
        } | null;
        sent_texts?: {
            __typename: "ModelSentTextsConnection";
            nextToken?: string | null;
        } | null;
        room_access_requests?: {
            __typename: "ModelRoomAccessRequestsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnDeleteRoomSubscriptionVariables = {
    filter?: ModelSubscriptionRoomFilterInput | null;
    owner?: string | null;
};

export type OnDeleteRoomSubscription = {
    onDeleteRoom?: {
        __typename: "Room";
        id: string;
        user_id: string;
        private: boolean;
        name: string;
        private_code?: string | null;
        allowComments?: boolean | null;
        room_updates?: {
            __typename: "ModelRoomUpdateConnection";
            nextToken?: string | null;
        } | null;
        room_recipients?: {
            __typename: "ModelRoomRecipientsConnection";
            nextToken?: string | null;
        } | null;
        sent_texts?: {
            __typename: "ModelSentTextsConnection";
            nextToken?: string | null;
        } | null;
        room_access_requests?: {
            __typename: "ModelRoomAccessRequestsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnCreateRoomUpdateSubscriptionVariables = {
    filter?: ModelSubscriptionRoomUpdateFilterInput | null;
    owner?: string | null;
};

export type OnCreateRoomUpdateSubscription = {
    onCreateRoomUpdate?: {
        __typename: "RoomUpdate";
        id: string;
        update?: string | null;
        image?: string | null;
        video?: string | null;
        created_at: string;
        show_time?: boolean | null;
        room_id: string;
        comments?: {
            __typename: "ModelUpdateCommentsConnection";
            nextToken?: string | null;
        } | null;
        reactions?: {
            __typename: "ModelUpdateReactionsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnUpdateRoomUpdateSubscriptionVariables = {
    filter?: ModelSubscriptionRoomUpdateFilterInput | null;
    owner?: string | null;
};

export type OnUpdateRoomUpdateSubscription = {
    onUpdateRoomUpdate?: {
        __typename: "RoomUpdate";
        id: string;
        update?: string | null;
        image?: string | null;
        video?: string | null;
        created_at: string;
        show_time?: boolean | null;
        room_id: string;
        comments?: {
            __typename: "ModelUpdateCommentsConnection";
            nextToken?: string | null;
        } | null;
        reactions?: {
            __typename: "ModelUpdateReactionsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnDeleteRoomUpdateSubscriptionVariables = {
    filter?: ModelSubscriptionRoomUpdateFilterInput | null;
    owner?: string | null;
};

export type OnDeleteRoomUpdateSubscription = {
    onDeleteRoomUpdate?: {
        __typename: "RoomUpdate";
        id: string;
        update?: string | null;
        image?: string | null;
        video?: string | null;
        created_at: string;
        show_time?: boolean | null;
        room_id: string;
        comments?: {
            __typename: "ModelUpdateCommentsConnection";
            nextToken?: string | null;
        } | null;
        reactions?: {
            __typename: "ModelUpdateReactionsConnection";
            nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnCreateRoomRecipientsSubscriptionVariables = {
    filter?: ModelSubscriptionRoomRecipientsFilterInput | null;
    owner?: string | null;
};

export type OnCreateRoomRecipientsSubscription = {
    onCreateRoomRecipients?: {
        __typename: "RoomRecipients";
        id: string;
        phone_number: string;
        created_at: string;
        room_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnUpdateRoomRecipientsSubscriptionVariables = {
    filter?: ModelSubscriptionRoomRecipientsFilterInput | null;
    owner?: string | null;
};

export type OnUpdateRoomRecipientsSubscription = {
    onUpdateRoomRecipients?: {
        __typename: "RoomRecipients";
        id: string;
        phone_number: string;
        created_at: string;
        room_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnDeleteRoomRecipientsSubscriptionVariables = {
    filter?: ModelSubscriptionRoomRecipientsFilterInput | null;
    owner?: string | null;
};

export type OnDeleteRoomRecipientsSubscription = {
    onDeleteRoomRecipients?: {
        __typename: "RoomRecipients";
        id: string;
        phone_number: string;
        created_at: string;
        room_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnCreateSentTextsSubscriptionVariables = {
    filter?: ModelSubscriptionSentTextsFilterInput | null;
    owner?: string | null;
};

export type OnCreateSentTextsSubscription = {
    onCreateSentTexts?: {
        __typename: "SentTexts";
        id: string;
        message: string;
        phone_number: string;
        success: boolean;
        created_at: string;
        room_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnUpdateSentTextsSubscriptionVariables = {
    filter?: ModelSubscriptionSentTextsFilterInput | null;
    owner?: string | null;
};

export type OnUpdateSentTextsSubscription = {
    onUpdateSentTexts?: {
        __typename: "SentTexts";
        id: string;
        message: string;
        phone_number: string;
        success: boolean;
        created_at: string;
        room_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnDeleteSentTextsSubscriptionVariables = {
    filter?: ModelSubscriptionSentTextsFilterInput | null;
    owner?: string | null;
};

export type OnDeleteSentTextsSubscription = {
    onDeleteSentTexts?: {
        __typename: "SentTexts";
        id: string;
        message: string;
        phone_number: string;
        success: boolean;
        created_at: string;
        room_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnCreateRoomAccessRequestsSubscriptionVariables = {
    filter?: ModelSubscriptionRoomAccessRequestsFilterInput | null;
    owner?: string | null;
};

export type OnCreateRoomAccessRequestsSubscription = {
    onCreateRoomAccessRequests?: {
        __typename: "RoomAccessRequests";
        id: string;
        room_id: string;
        user_id: string;
        user_info?: {
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null;
        approved: boolean;
        reviewed: boolean;
        created_at: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnUpdateRoomAccessRequestsSubscriptionVariables = {
    filter?: ModelSubscriptionRoomAccessRequestsFilterInput | null;
    owner?: string | null;
};

export type OnUpdateRoomAccessRequestsSubscription = {
    onUpdateRoomAccessRequests?: {
        __typename: "RoomAccessRequests";
        id: string;
        room_id: string;
        user_id: string;
        user_info?: {
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null;
        approved: boolean;
        reviewed: boolean;
        created_at: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnDeleteRoomAccessRequestsSubscriptionVariables = {
    filter?: ModelSubscriptionRoomAccessRequestsFilterInput | null;
    owner?: string | null;
};

export type OnDeleteRoomAccessRequestsSubscription = {
    onDeleteRoomAccessRequests?: {
        __typename: "RoomAccessRequests";
        id: string;
        room_id: string;
        user_id: string;
        user_info?: {
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null;
        approved: boolean;
        reviewed: boolean;
        created_at: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnCreateUpdateCommentsSubscriptionVariables = {
    filter?: ModelSubscriptionUpdateCommentsFilterInput | null;
    owner?: string | null;
};

export type OnCreateUpdateCommentsSubscription = {
    onCreateUpdateComments?: {
        __typename: "UpdateComments";
        id: string;
        comment: string;
        room_update_id: string;
        user_id: string;
        user_info?: {
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnUpdateUpdateCommentsSubscriptionVariables = {
    filter?: ModelSubscriptionUpdateCommentsFilterInput | null;
    owner?: string | null;
};

export type OnUpdateUpdateCommentsSubscription = {
    onUpdateUpdateComments?: {
        __typename: "UpdateComments";
        id: string;
        comment: string;
        room_update_id: string;
        user_id: string;
        user_info?: {
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnDeleteUpdateCommentsSubscriptionVariables = {
    filter?: ModelSubscriptionUpdateCommentsFilterInput | null;
    owner?: string | null;
};

export type OnDeleteUpdateCommentsSubscription = {
    onDeleteUpdateComments?: {
        __typename: "UpdateComments";
        id: string;
        comment: string;
        room_update_id: string;
        user_id: string;
        user_info?: {
            __typename: "User";
            id: string;
            first_name: string;
            last_name: string;
            email: string;
            createdAt: string;
            updatedAt: string;
            owner?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnCreateUpdateReactionsSubscriptionVariables = {
    filter?: ModelSubscriptionUpdateReactionsFilterInput | null;
    owner?: string | null;
};

export type OnCreateUpdateReactionsSubscription = {
    onCreateUpdateReactions?: {
        __typename: "UpdateReactions";
        id: string;
        type: string;
        room_update_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnUpdateUpdateReactionsSubscriptionVariables = {
    filter?: ModelSubscriptionUpdateReactionsFilterInput | null;
    owner?: string | null;
};

export type OnUpdateUpdateReactionsSubscription = {
    onUpdateUpdateReactions?: {
        __typename: "UpdateReactions";
        id: string;
        type: string;
        room_update_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};

export type OnDeleteUpdateReactionsSubscriptionVariables = {
    filter?: ModelSubscriptionUpdateReactionsFilterInput | null;
    owner?: string | null;
};

export type OnDeleteUpdateReactionsSubscription = {
    onDeleteUpdateReactions?: {
        __typename: "UpdateReactions";
        id: string;
        type: string;
        room_update_id: string;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
    } | null;
};
