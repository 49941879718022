/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
    __generatedMutationInput: InputType;
    __generatedMutationOutput: OutputType;
};

export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    first_name
    last_name
    email
    rooms {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateUserMutationVariables, APITypes.CreateUserMutation>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    first_name
    last_name
    email
    rooms {
      nextToken
      __typename
    }
    updateComments {
      nextToken
      __typename
    }
    roomAccessRequests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateUserMutationVariables, APITypes.UpdateUserMutation>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    first_name
    last_name
    email
    rooms {
      nextToken
      __typename
    }
    updateComments {
      nextToken
      __typename
    }
    roomAccessRequests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteUserMutationVariables, APITypes.DeleteUserMutation>;
export const createRoom = /* GraphQL */ `mutation CreateRoom(
  $input: CreateRoomInput!
  $condition: ModelRoomConditionInput
) {
  createRoom(input: $input, condition: $condition) {
    id
    user_id
    private
    name
    private_code
    allowComments
    room_updates {
      nextToken
      __typename
    }
    room_recipients {
      nextToken
      __typename
    }
    sent_texts {
      nextToken
      __typename
    }
    room_access_requests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateRoomMutationVariables, APITypes.CreateRoomMutation>;
export const updateRoom = /* GraphQL */ `mutation UpdateRoom(
  $input: UpdateRoomInput!
  $condition: ModelRoomConditionInput
) {
  updateRoom(input: $input, condition: $condition) {
    id
    user_id
    private
    name
    private_code
    allowComments
    room_updates {
      nextToken
      __typename
    }
    room_recipients {
      nextToken
      __typename
    }
    sent_texts {
      nextToken
      __typename
    }
    room_access_requests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateRoomMutationVariables, APITypes.UpdateRoomMutation>;
export const deleteRoom = /* GraphQL */ `mutation DeleteRoom(
  $input: DeleteRoomInput!
  $condition: ModelRoomConditionInput
) {
  deleteRoom(input: $input, condition: $condition) {
    id
    user_id
    private
    name
    private_code
    allowComments
    room_updates {
      nextToken
      __typename
    }
    room_recipients {
      nextToken
      __typename
    }
    sent_texts {
      nextToken
      __typename
    }
    room_access_requests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteRoomMutationVariables, APITypes.DeleteRoomMutation>;
export const createRoomUpdate = /* GraphQL */ `mutation CreateRoomUpdate(
  $input: CreateRoomUpdateInput!
  $condition: ModelRoomUpdateConditionInput
) {
  createRoomUpdate(input: $input, condition: $condition) {
    id
    update
    image
    video
    created_at
    show_time
    room_id
    comments {
      nextToken
      __typename
    }
    reactions {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateRoomUpdateMutationVariables, APITypes.CreateRoomUpdateMutation>;
export const updateRoomUpdate = /* GraphQL */ `mutation UpdateRoomUpdate(
  $input: UpdateRoomUpdateInput!
  $condition: ModelRoomUpdateConditionInput
) {
  updateRoomUpdate(input: $input, condition: $condition) {
    id
    update
    image
    video
    created_at
    show_time
    room_id
    comments {
      nextToken
      __typename
    }
    reactions {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateRoomUpdateMutationVariables, APITypes.UpdateRoomUpdateMutation>;
export const deleteRoomUpdate = /* GraphQL */ `mutation DeleteRoomUpdate(
  $input: DeleteRoomUpdateInput!
  $condition: ModelRoomUpdateConditionInput
) {
  deleteRoomUpdate(input: $input, condition: $condition) {
    id
    update
    image
    video
    created_at
    show_time
    room_id
    comments {
      nextToken
      __typename
    }
    reactions {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteRoomUpdateMutationVariables, APITypes.DeleteRoomUpdateMutation>;
export const createRoomRecipients = /* GraphQL */ `mutation CreateRoomRecipients(
  $input: CreateRoomRecipientsInput!
  $condition: ModelRoomRecipientsConditionInput
) {
  createRoomRecipients(input: $input, condition: $condition) {
    id
    phone_number
    created_at
    room_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateRoomRecipientsMutationVariables, APITypes.CreateRoomRecipientsMutation>;
export const updateRoomRecipients = /* GraphQL */ `mutation UpdateRoomRecipients(
  $input: UpdateRoomRecipientsInput!
  $condition: ModelRoomRecipientsConditionInput
) {
  updateRoomRecipients(input: $input, condition: $condition) {
    id
    phone_number
    created_at
    room_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateRoomRecipientsMutationVariables, APITypes.UpdateRoomRecipientsMutation>;
export const deleteRoomRecipients = /* GraphQL */ `mutation DeleteRoomRecipients(
  $input: DeleteRoomRecipientsInput!
  $condition: ModelRoomRecipientsConditionInput
) {
  deleteRoomRecipients(input: $input, condition: $condition) {
    id
    phone_number
    created_at
    room_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteRoomRecipientsMutationVariables, APITypes.DeleteRoomRecipientsMutation>;
export const createSentTexts = /* GraphQL */ `mutation CreateSentTexts(
  $input: CreateSentTextsInput!
  $condition: ModelSentTextsConditionInput
) {
  createSentTexts(input: $input, condition: $condition) {
    id
    message
    phone_number
    success
    created_at
    room_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateSentTextsMutationVariables, APITypes.CreateSentTextsMutation>;
export const updateSentTexts = /* GraphQL */ `mutation UpdateSentTexts(
  $input: UpdateSentTextsInput!
  $condition: ModelSentTextsConditionInput
) {
  updateSentTexts(input: $input, condition: $condition) {
    id
    message
    phone_number
    success
    created_at
    room_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateSentTextsMutationVariables, APITypes.UpdateSentTextsMutation>;
export const deleteSentTexts = /* GraphQL */ `mutation DeleteSentTexts(
  $input: DeleteSentTextsInput!
  $condition: ModelSentTextsConditionInput
) {
  deleteSentTexts(input: $input, condition: $condition) {
    id
    message
    phone_number
    success
    created_at
    room_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteSentTextsMutationVariables, APITypes.DeleteSentTextsMutation>;
export const createRoomAccessRequests = /* GraphQL */ `mutation CreateRoomAccessRequests(
  $input: CreateRoomAccessRequestsInput!
  $condition: ModelRoomAccessRequestsConditionInput
) {
  createRoomAccessRequests(input: $input, condition: $condition) {
    id
    room_id
    user_id
    user_info {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    approved
    reviewed
    created_at
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateRoomAccessRequestsMutationVariables, APITypes.CreateRoomAccessRequestsMutation>;
export const updateRoomAccessRequests = /* GraphQL */ `mutation UpdateRoomAccessRequests(
  $input: UpdateRoomAccessRequestsInput!
  $condition: ModelRoomAccessRequestsConditionInput
) {
  updateRoomAccessRequests(input: $input, condition: $condition) {
    id
    room_id
    user_id
    user_info {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    approved
    reviewed
    created_at
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateRoomAccessRequestsMutationVariables, APITypes.UpdateRoomAccessRequestsMutation>;
export const deleteRoomAccessRequests = /* GraphQL */ `mutation DeleteRoomAccessRequests(
  $input: DeleteRoomAccessRequestsInput!
  $condition: ModelRoomAccessRequestsConditionInput
) {
  deleteRoomAccessRequests(input: $input, condition: $condition) {
    id
    room_id
    user_id
    user_info {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    approved
    reviewed
    created_at
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteRoomAccessRequestsMutationVariables, APITypes.DeleteRoomAccessRequestsMutation>;
export const createUpdateComments = /* GraphQL */ `mutation CreateUpdateComments(
  $input: CreateUpdateCommentsInput!
  $condition: ModelUpdateCommentsConditionInput
) {
  createUpdateComments(input: $input, condition: $condition) {
    id
    comment
    room_update_id
    user_id
    user_info {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateUpdateCommentsMutationVariables, APITypes.CreateUpdateCommentsMutation>;
export const updateUpdateComments = /* GraphQL */ `mutation UpdateUpdateComments(
  $input: UpdateUpdateCommentsInput!
  $condition: ModelUpdateCommentsConditionInput
) {
  updateUpdateComments(input: $input, condition: $condition) {
    id
    comment
    room_update_id
    user_id
    user_info {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateUpdateCommentsMutationVariables, APITypes.UpdateUpdateCommentsMutation>;
export const deleteUpdateComments = /* GraphQL */ `mutation DeleteUpdateComments(
  $input: DeleteUpdateCommentsInput!
  $condition: ModelUpdateCommentsConditionInput
) {
  deleteUpdateComments(input: $input, condition: $condition) {
    id
    comment
    room_update_id
    user_id
    user_info {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteUpdateCommentsMutationVariables, APITypes.DeleteUpdateCommentsMutation>;
export const createUpdateReactions = /* GraphQL */ `mutation CreateUpdateReactions(
  $input: CreateUpdateReactionsInput!
  $condition: ModelUpdateReactionsConditionInput
) {
  createUpdateReactions(input: $input, condition: $condition) {
    id
    type
    room_update_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateUpdateReactionsMutationVariables, APITypes.CreateUpdateReactionsMutation>;
export const updateUpdateReactions = /* GraphQL */ `mutation UpdateUpdateReactions(
  $input: UpdateUpdateReactionsInput!
  $condition: ModelUpdateReactionsConditionInput
) {
  updateUpdateReactions(input: $input, condition: $condition) {
    id
    type
    room_update_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateUpdateReactionsMutationVariables, APITypes.UpdateUpdateReactionsMutation>;
export const deleteUpdateReactions = /* GraphQL */ `mutation DeleteUpdateReactions(
  $input: DeleteUpdateReactionsInput!
  $condition: ModelUpdateReactionsConditionInput
) {
  deleteUpdateReactions(input: $input, condition: $condition) {
    id
    type
    room_update_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteUpdateReactionsMutationVariables, APITypes.DeleteUpdateReactionsMutation>;
