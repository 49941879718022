import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Container from "@mui/joy/Container";
import { typographyClasses } from "@mui/joy/Typography";
import Image from "./adam&eve.png";

export default function TwoSidedLayout({ children, reversed }: React.PropsWithChildren<{ reversed?: boolean }>) {
    return (
        <Container
            sx={(theme) => ({
                position: "relative",
                minHeight: "100vh",
                display: "flex",
                flexDirection: reversed ? "column-reverse" : "column",
                alignItems: "center",
                py: 10,
                gap: 4,
                [theme.breakpoints.up(834)]: {
                    flexDirection: "row",
                    gap: 6,
                },
                [theme.breakpoints.up(1199)]: {
                    gap: 12,
                },
            })}
        >
            <Box
                sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "1rem",
                    maxWidth: "50ch",
                    textAlign: "center",
                    flexShrink: 999,
                    [theme.breakpoints.up(834)]: {
                        minWidth: 420,
                        alignItems: "flex-start",
                        textAlign: "initial",
                    },
                    [`& .${typographyClasses.root}`]: {
                        textWrap: "balance",
                    },
                })}
            >
                {children}
            </Box>
            <AspectRatio
                ratio={600 / 520}
                sx={(theme) => ({
                    minWidth: 300,
                    maxWidth: "100%",
                    alignSelf: "stretch",
                    [theme.breakpoints.up(834)]: {
                        alignSelf: "initial",
                        flexGrow: 1,
                        "--AspectRatio-maxHeight": "520px",
                        "--AspectRatio-minHeight": "400px",
                    },
                    [theme.breakpoints.down("md")]: {
                        "--AspectRatio-maxHeight": "600px",
                    },
                    bgcolor: "background.level2",
                    flexBasis: "50%",
                    borderRadius: "10px",
                    overflow: "hidden",
                })}
            >
                <img
                    src={Image}
                    alt="adam&eve"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center top",
                    }}
                />
            </AspectRatio>
        </Container>
    );
}
