import React from "react";
import "@aws-amplify/ui-react/styles.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Room } from "./Components/Room/index";
import { Home } from "./Components/Home/index";
import Dashboard from "./Components/Dashboard/index";
import { CssBaseline, CssVarsProvider } from "@mui/joy";
import framesxTheme from "./theme";
import { SignIn } from "./Components/SignIn/index";
import { ForgotPassword } from "./Components/ForgotPassword/index";
import { SignUp } from "./Components/Signup/index";
import DashboardLayout from "./Components/Dashboard/Layout";
import { CreateRoomModal } from "./Components/CreateRoomModal/index";
import { ModalProvider } from "./context/modalContext";
import { QueryClient, QueryClientProvider } from "react-query";

const App = () => {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <ModalProvider>
                <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
                    <CssBaseline />
                    <BrowserRouter>
                        <CreateRoomModal />
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/signin" element={<SignIn />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/signup" element={<SignUp />} />
                            <Route path="/signup/:email" element={<SignUp />} />
                            <Route element={<DashboardLayout />}>
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/room/:id" element={<Room />} />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </CssVarsProvider>
            </ModalProvider>
        </QueryClientProvider>
    );
};

export default App;
