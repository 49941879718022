import React, { useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, NavLink } from "react-router-dom";
import { signIn } from "aws-amplify/auth";
import { Box, FormControl, FormLabel, Input, Link, Stack, Typography, formLabelClasses, Button } from "@mui/joy";
import { useMutation } from "react-query";
import { useForm } from "@tanstack/react-form";
import ValidationFieldInfo from "../ValidationFieldInfo";
import { getErrorMessage } from "../../ui-components/utils";

interface FormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement;
    password: HTMLInputElement;
}

interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

interface SignInParams {
    email: string;
    password: string;
}

const signInMutation = async ({ email, password }: SignInParams) => {
    const { isSignedIn, nextStep } = await signIn({ username: email, password });
    return { isSignedIn, nextStep };
};

export const SignIn = () => {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]); // authenticated | unauthenticated
    const navigate = useNavigate();
    const [authError, setAuthError] = useState(false);

    const form = useForm({
        defaultValues: {
            email: "",
            password: "",
        },
        onSubmit: async ({ value }) => {
            await mutate({
                email: value.email,
                password: value.password,
            });
        },
    });

    // TODO: Probably better way todo this than to set the url as email
    const { mutate, isLoading } = useMutation(signInMutation, {
        onSuccess: ({ isSignedIn, nextStep }) => {
            if (nextStep?.signInStep === "CONFIRM_SIGN_UP") {
                navigate("/signup/" + form.getFieldValue("email"));
            }

            if (isSignedIn) {
                const params = new URLSearchParams(window.location.search);
                const redirectUrl = params.get("redirect");
                navigate(redirectUrl || "/dashboard");
            }
        },
        onError: (error) => {
            if (error && getErrorMessage(error).endsWith("Incorrect username or password.")) {
                setAuthError(true);
            }
            console.error("Error signing in:", error);
        },
    });

    useEffect(() => {
        if (authStatus === "authenticated") {
            navigate("/dashboard");
        }
    }, [authStatus]);

    return (
        <>
            <Box
                sx={(theme) => ({
                    width: "clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)",
                    transition: "width var(--Transition-duration)",
                    transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
                    position: "relative",
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "center",
                    backdropFilter: "blur(12px)",
                    backgroundColor: "rgba(255 255 255 / 0.2)",
                    [theme.getColorSchemeSelector("dark")]: {
                        backgroundColor: "rgba(19 19 24 / 0.4)",
                    },
                })}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "100dvh",
                        width: "clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)",
                        maxWidth: "100%",
                        px: 2,
                    }}
                >
                    <Box
                        component="main"
                        sx={{
                            my: "auto",
                            py: 2,
                            pb: 5,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            width: 400,
                            maxWidth: "100%",
                            mx: "auto",
                            borderRadius: "sm",
                            "& form": {
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: "hidden",
                            },
                        }}
                    >
                        <Stack gap={4}>
                            <Stack gap={1}>
                                <Typography component="h1" level="h3">
                                    Sign in
                                </Typography>
                                <Typography level="body-sm">
                                    New to Stork Status?{" "}
                                    <Link component={NavLink} to="/signup" level="title-sm">
                                        Sign up!
                                    </Link>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack gap={4} sx={{ mt: 2 }}>
                            <form
                                onSubmit={async (event: React.FormEvent<SignInFormElement>) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    form.handleSubmit();
                                }}
                            >
                                <form.Field
                                    name="email"
                                    children={(field) => {
                                        return (
                                            <FormControl
                                                required
                                                error={
                                                    field.state.meta.touchedErrors.length > 0 || authError
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <FormLabel>Email</FormLabel>
                                                <Input
                                                    type="email"
                                                    value={field.state.value}
                                                    onChange={(e) => {
                                                        setAuthError(false);
                                                        field.handleChange(e.target.value);
                                                    }}
                                                />
                                                <ValidationFieldInfo
                                                    field={field}
                                                    errorMessage={authError ? "Incorrect username or password." : ""}
                                                />
                                            </FormControl>
                                        );
                                    }}
                                />
                                <form.Field
                                    name="password"
                                    children={(field) => {
                                        return (
                                            <FormControl
                                                required
                                                error={
                                                    field.state.meta.touchedErrors.length > 0 || authError
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <FormLabel>Password</FormLabel>
                                                <Input
                                                    type="password"
                                                    value={field.state.value}
                                                    onChange={(e) => {
                                                        field.handleChange(e.target.value);
                                                        setAuthError(false);
                                                    }}
                                                />
                                                <ValidationFieldInfo
                                                    field={field}
                                                    errorMessage={authError ? "Incorrect username or password." : ""}
                                                />
                                            </FormControl>
                                        );
                                    }}
                                />
                                <Stack gap={4} sx={{ mt: 2 }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Link level="title-sm" component={NavLink} to="/forgot-password">
                                            Forgot your password?
                                        </Link>
                                    </Box>
                                    <Button type="submit" loading={isLoading}>
                                        Sign in
                                    </Button>
                                </Stack>
                            </form>
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
