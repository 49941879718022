import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";
import { Box, FormControl, FormLabel, Input, Link, Stack, Typography, formLabelClasses, Button } from "@mui/joy";

interface ForgotPasswordFormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement;
}

interface ResetPasswordFormElements extends HTMLFormControlsCollection {
    code: HTMLInputElement;
    newPassword: HTMLInputElement;
    confirmPassword: HTMLInputElement;
}

interface ForgotPasswordFormElement extends HTMLFormElement {
    readonly elements: ForgotPasswordFormElements;
}

interface ResetPasswordFormElement extends HTMLFormElement {
    readonly elements: ResetPasswordFormElements;
}

export const ForgotPassword = () => {
    const [codeStep, setCodeStep] = useState(false);
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    return (
        <>
            <Box
                sx={(theme) => ({
                    width: "clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)",
                    transition: "width var(--Transition-duration)",
                    transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
                    position: "relative",
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "center",
                    backdropFilter: "blur(12px)",
                    backgroundColor: "rgba(255 255 255 / 0.2)",
                    [theme.getColorSchemeSelector("dark")]: {
                        backgroundColor: "rgba(19 19 24 / 0.4)",
                    },
                })}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "100dvh",
                        width: "clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)",
                        maxWidth: "100%",
                        px: 2,
                    }}
                >
                    <Box
                        component="main"
                        sx={{
                            my: "auto",
                            py: 2,
                            pb: 5,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            width: 400,
                            maxWidth: "100%",
                            mx: "auto",
                            borderRadius: "sm",
                            "& form": {
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: "hidden",
                            },
                        }}
                    >
                        <Stack gap={4}>
                            <Stack gap={1}>
                                <Typography component="h1" level="h3">
                                    Reset Password
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack gap={4} sx={{ mt: 1 }}>
                            {codeStep ? (
                                <form
                                    onSubmit={async (event: React.FormEvent<ResetPasswordFormElement>) => {
                                        event.preventDefault();
                                        const formElements = event.currentTarget.elements;

                                        try {
                                            await confirmResetPassword({
                                                confirmationCode: formElements.code.value,
                                                newPassword: formElements.confirmPassword.value,
                                                username: email,
                                            });

                                            navigate("/signin");
                                        } catch (error) {
                                            console.error("Error sending code:", error);
                                        }
                                    }}
                                >
                                    <FormControl required>
                                        <FormLabel>Code *</FormLabel>
                                        <Input type="number" name="code" />
                                    </FormControl>
                                    <FormControl required>
                                        <FormLabel>New Password</FormLabel>
                                        <Input type="password" name="newPassword" />
                                    </FormControl>
                                    <FormControl required>
                                        <FormLabel>Confirm Password</FormLabel>
                                        <Input type="password" name="confirmPassword" />
                                    </FormControl>
                                    <Stack gap={3} sx={{ mt: 2 }}>
                                        <Button type="submit">Submit</Button>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Button
                                                onClick={async () =>
                                                    await resetPassword({
                                                        username: email,
                                                    })
                                                }
                                                variant="plain"
                                                size="sm"
                                                sx={[
                                                    {
                                                        p: 0,
                                                        m: 0,
                                                        fontWeight: 500,
                                                    },
                                                    {
                                                        "&:hover": {
                                                            background: "none",
                                                        },
                                                    },
                                                ]}
                                            >
                                                Resend Code
                                            </Button>
                                        </Box>
                                    </Stack>
                                </form>
                            ) : (
                                <form
                                    onSubmit={async (event: React.FormEvent<ForgotPasswordFormElement>) => {
                                        event.preventDefault();
                                        const formElements = event.currentTarget.elements;

                                        try {
                                            setEmail(formElements.email.value);
                                            await resetPassword({
                                                username: formElements.email.value,
                                            });
                                            setCodeStep(true);
                                        } catch (error) {
                                            console.error("Error sending code:", error);
                                        }
                                    }}
                                >
                                    <FormControl required>
                                        <FormLabel>Email</FormLabel>
                                        <Input type="email" name="email" />
                                    </FormControl>
                                    <Stack gap={3} sx={{ mt: 2 }}>
                                        <Button type="submit">Send code</Button>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Link level="title-sm" component={NavLink} to="/signin">
                                                Back to Sign In
                                            </Link>
                                        </Box>
                                    </Stack>
                                </form>
                            )}
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
