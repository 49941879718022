import React from "react";
import { Box, Button, Modal, ModalDialog, Typography } from "@mui/joy";

export const AreYouSureModal = ({
    handleSendText,
    setIsOpen,
}: {
    handleSendText: () => void;
    setIsOpen: (value: boolean) => void;
}) => {
    return (
        <Modal open onClose={() => setIsOpen(false)}>
            <ModalDialog
                aria-labelledby="nested-modal-title"
                aria-describedby="nested-modal-description"
                sx={(theme) => ({
                    [theme.breakpoints.only("xs")]: {
                        top: "unset",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        borderRadius: 0,
                        transform: "none",
                        maxWidth: "unset",
                    },
                })}
            >
                <Typography id="nested-modal-title" level="h2">
                    Are you sure?
                </Typography>
                <Typography id="nested-modal-description" textColor="text.tertiary">
                    This action cannot be undone. This will send text messages to all recipients.
                </Typography>
                <Box
                    sx={{
                        mt: 1,
                        display: "flex",
                        gap: 1,
                        flexDirection: { xs: "column", sm: "row-reverse" },
                    }}
                >
                    <Button
                        variant="solid"
                        color="primary"
                        onClick={() => {
                            handleSendText();
                            setIsOpen(false);
                        }}
                    >
                        Send Texts
                    </Button>
                    <Button variant="outlined" color="neutral" onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                </Box>
            </ModalDialog>
        </Modal>
    );
};
