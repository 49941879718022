import React, { useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { signUp, confirmSignUp, resendSignUpCode, signIn } from "aws-amplify/auth";
import { Box, FormControl, FormLabel, Input, Link, Stack, Typography, formLabelClasses, Button } from "@mui/joy";
import { encryptEmail } from "../../utils";
import { createUser } from "../../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { useMutation } from "react-query";
import { Check, Close } from "@mui/icons-material";

interface FormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement;
    firstName: HTMLInputElement;
    lastName: HTMLInputElement;
    password: HTMLInputElement;
    confirmPassword: HTMLInputElement;
}

interface ConfirmationFormElements extends HTMLFormControlsCollection {
    confirmationCode: HTMLInputElement;
}

interface SignUpFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

interface ConfirmationFormElement extends HTMLFormElement {
    readonly elements: ConfirmationFormElements;
}

export const SignUp = () => {
    const client = generateClient();
    const [codeSent, setCodeSent] = useState(false);
    const [email, setEmail] = useState("");
    const { authStatus } = useAuthenticator((context) => [context.authStatus]); // authenticated | unauthenticated
    const navigate = useNavigate();
    const location = useLocation();

    const typeOfSignup = location.pathname.split("/").pop();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        number: false,
        symbol: false,
        capital: false,
        lowercase: false,
        match: false,
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const [confirmPasswordStarted, setConfirmPasswordStarted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [navigationLoading, setNavigationLoading] = useState(false);

    const params = new URLSearchParams(window.location.search);
    const redirectUrl = params.get("redirect");

    const validatePassword = (password: string, confirmPassword: string) => {
        const validations = {
            length: password.length >= 8,
            number: /\d/.test(password),
            symbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
            capital: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            match: password === confirmPassword,
        };
        setPasswordValidations(validations);
        setIsFormValid(
            validations.length &&
                validations.number &&
                validations.symbol &&
                validations.capital &&
                validations.lowercase &&
                validations.match &&
                email !== "" &&
                firstName !== "" &&
                lastName !== ""
        );
    };

    useEffect(() => {
        if (authStatus === "authenticated") {
            navigate("/dashboard");
        }
    }, [authStatus]);

    useEffect(() => {
        if (typeof typeOfSignup === "string" && typeOfSignup !== "signup") {
            setEmail(typeOfSignup);
        }

        resendSignUpCodeMutation(undefined, {
            onError: (error) => {
                console.error("Error resending code:", error);
            },
        });
    }, [typeOfSignup]);

    const { mutate: signUpMutation, isLoading: signUpLoading } = useMutation(async (formElements: FormElements) => {
        const { userId } = await signUp({
            username: formElements.email.value,
            password: formElements.confirmPassword.value,
            options: {
                userAttributes: {
                    given_name: formElements.firstName.value,
                    family_name: formElements.lastName.value,
                },
            },
        });
        await client.graphql({
            query: createUser,
            variables: {
                input: {
                    id: userId,
                    email: formElements.email.value,
                    first_name: formElements.firstName.value,
                    last_name: formElements.lastName.value,
                },
            },
            authMode: "iam",
        });
    });

    const { mutate: confirmSignUpMutation, isLoading: confirmSignUpLoading } = useMutation(
        async (formElements: ConfirmationFormElements) => {
            await confirmSignUp({
                username: email,
                confirmationCode: formElements.confirmationCode.value,
            });
        }
    );

    const { mutate: resendSignUpCodeMutation, isLoading: resendSignUpCodeLoading } = useMutation(async () => {
        await resendSignUpCode({
            username: email,
        });
    });

    const fadeInOutStyle = (visible: boolean) => ({
        height: visible ? "auto" : "0px",
        opacity: visible ? 1 : 0,
        overflow: "hidden",
        transition: "height 500ms, opacity 500ms",
    });

    return (
        <>
            <Box
                sx={(theme) => ({
                    width: "clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)",
                    transition: "width var(--Transition-duration)",
                    transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
                    position: "relative",
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "center",
                    backdropFilter: "blur(12px)",
                    backgroundColor: "rgba(255 255 255 / 0.2)",
                    [theme.getColorSchemeSelector("dark")]: {
                        backgroundColor: "rgba(19 19 24 / 0.4)",
                    },
                })}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "100dvh",
                        width: "clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)",
                        maxWidth: "100%",
                        px: 2,
                    }}
                >
                    <Box
                        component="main"
                        sx={{
                            my: "auto",
                            py: 2,
                            pb: 5,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            width: 400,
                            maxWidth: "100%",
                            mx: "auto",
                            borderRadius: "sm",
                            "& form": {
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: "hidden",
                            },
                        }}
                    >
                        {codeSent || (typeof typeOfSignup === "string" && typeOfSignup !== "signup" && email !== "") ? (
                            <>
                                <Stack gap={4}>
                                    <Stack gap={1}>
                                        <Typography component="h1" level="h3">
                                            We Emailed You
                                        </Typography>
                                        <Typography level="body-sm">
                                            Your code is on the way. To log in, enter the code we emailed to{" "}
                                            {encryptEmail(email)}. It may take a minute to arrive.
                                        </Typography>
                                        <Typography level="body-sm">
                                            Please check your spam folder if you don't see it in your inbox.
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack gap={4} sx={{ mt: 2 }}>
                                    <form
                                        onSubmit={async (event: React.FormEvent<ConfirmationFormElement>) => {
                                            event.preventDefault();
                                            const formElements = event.currentTarget.elements;

                                            confirmSignUpMutation(formElements, {
                                                onSuccess: async () => {
                                                    setNavigationLoading(true);
                                                    if (confirmPassword !== "") {
                                                        const { isSignedIn } = await signIn({
                                                            username: email,
                                                            password: confirmPassword,
                                                        });

                                                        if (isSignedIn) {
                                                            const params = new URLSearchParams(window.location.search);
                                                            const redirectUrl2 = params.get("redirect");
                                                            setNavigationLoading(false);
                                                            navigate(redirectUrl2 || "/dashboard");
                                                        } else {
                                                            setNavigationLoading(false);
                                                            navigate(
                                                                redirectUrl
                                                                    ? `/signin?redirect=${encodeURIComponent(
                                                                          redirectUrl
                                                                      )}`
                                                                    : "/signin"
                                                            );
                                                        }
                                                    } else {
                                                        setNavigationLoading(false);
                                                        navigate(
                                                            redirectUrl
                                                                ? `/signin?redirect=${encodeURIComponent(redirectUrl)}`
                                                                : "/signin"
                                                        );
                                                    }
                                                },
                                                onError: (error: any) => {
                                                    console.error("Error signing in:", error);
                                                    setNavigationLoading(false);
                                                    const errorMessage =
                                                        error?.message?.split(":")?.pop()?.trim() || "";
                                                    setErrorMessage(errorMessage);
                                                },
                                            });
                                        }}
                                    >
                                        <FormControl
                                            required
                                            error={errorMessage.startsWith("Invalid verification code provided")}
                                        >
                                            <FormLabel>Confirmation Code</FormLabel>
                                            <Input type="number" name="confirmationCode" />
                                        </FormControl>
                                        {errorMessage && (
                                            <Typography
                                                level="body-sm"
                                                color="danger"
                                                sx={fadeInOutStyle(!!errorMessage)}
                                            >
                                                {errorMessage}
                                            </Typography>
                                        )}
                                        <Stack gap={4} sx={{ mt: 2 }}>
                                            <Button type="submit" loading={confirmSignUpLoading || navigationLoading}>
                                                Confirm
                                            </Button>
                                            <Button
                                                type="button"
                                                color="neutral"
                                                variant="outlined"
                                                loading={resendSignUpCodeLoading}
                                                onClick={async () => {
                                                    resendSignUpCodeMutation(undefined, {
                                                        onError: (error) => {
                                                            console.error("Error resending code:", error);
                                                        },
                                                    });
                                                }}
                                            >
                                                Resend Code
                                            </Button>
                                        </Stack>
                                    </form>
                                </Stack>
                            </>
                        ) : (
                            <>
                                <Stack gap={4}>
                                    <Stack gap={1}>
                                        <Typography component="h1" level="h3">
                                            Sign up
                                        </Typography>
                                        <Typography level="body-sm">
                                            Already have an account?{" "}
                                            <Link
                                                component={NavLink}
                                                to={
                                                    redirectUrl
                                                        ? `/signin?redirect=${encodeURIComponent(redirectUrl)}`
                                                        : "/signin"
                                                }
                                                level="title-sm"
                                            >
                                                Sign in
                                            </Link>
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack gap={4} sx={{ mt: 2 }}>
                                    <form
                                        onSubmit={async (event: React.FormEvent<SignUpFormElement>) => {
                                            event.preventDefault();
                                            const formElements = event.currentTarget.elements;
                                            setEmail(formElements.email.value);

                                            signUpMutation(formElements, {
                                                onSuccess: () => {
                                                    setCodeSent(true);
                                                },
                                                onError: (error: any) => {
                                                    const errorMessage =
                                                        error?.message?.split(":")?.pop()?.trim() || "";
                                                    setErrorMessage(errorMessage);
                                                },
                                            });
                                        }}
                                    >
                                        <FormControl required error={errorMessage === "User already exists"}>
                                            <FormLabel>Email</FormLabel>
                                            <Input
                                                type="email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    validatePassword(password, confirmPassword);
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl required>
                                            <FormLabel>First Name</FormLabel>
                                            <Input
                                                type="string"
                                                name="firstName"
                                                value={firstName}
                                                onChange={(e) => {
                                                    setFirstName(e.target.value);
                                                    validatePassword(password, confirmPassword);
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl required>
                                            <FormLabel>Last Name</FormLabel>
                                            <Input
                                                type="string"
                                                name="lastName"
                                                value={lastName}
                                                onChange={(e) => {
                                                    setLastName(e.target.value);
                                                    validatePassword(password, confirmPassword);
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl required>
                                            <FormLabel>Password</FormLabel>
                                            <Input
                                                type="password"
                                                name="password"
                                                value={password}
                                                onChange={(e) => {
                                                    setPassword(e.target.value);
                                                    validatePassword(e.target.value, confirmPassword);
                                                }}
                                            />
                                            <Stack
                                                gap={1}
                                                sx={{ mt: password !== "" ? 1 : 0, ...fadeInOutStyle(password !== "") }}
                                            >
                                                <Typography
                                                    level="body-xs"
                                                    color={passwordValidations.length ? "success" : "danger"}
                                                    startDecorator={passwordValidations.length ? <Check /> : <Close />}
                                                >
                                                    At least 8 characters
                                                </Typography>
                                                <Typography
                                                    level="body-xs"
                                                    color={passwordValidations.capital ? "success" : "danger"}
                                                    startDecorator={passwordValidations.capital ? <Check /> : <Close />}
                                                >
                                                    Contains a capital letter
                                                </Typography>
                                                <Typography
                                                    level="body-xs"
                                                    color={passwordValidations.lowercase ? "success" : "danger"}
                                                    startDecorator={
                                                        passwordValidations.lowercase ? <Check /> : <Close />
                                                    }
                                                >
                                                    Contains a lowercase letter
                                                </Typography>
                                                <Typography
                                                    level="body-xs"
                                                    color={passwordValidations.number ? "success" : "danger"}
                                                    startDecorator={passwordValidations.number ? <Check /> : <Close />}
                                                >
                                                    Contains a number
                                                </Typography>
                                                <Typography
                                                    level="body-xs"
                                                    color={passwordValidations.symbol ? "success" : "danger"}
                                                    startDecorator={passwordValidations.symbol ? <Check /> : <Close />}
                                                >
                                                    Contains a symbol
                                                </Typography>
                                            </Stack>
                                        </FormControl>
                                        <FormControl required>
                                            <FormLabel>Confirm Password</FormLabel>
                                            <Input
                                                type="password"
                                                name="confirmPassword"
                                                value={confirmPassword}
                                                onChange={(e) => {
                                                    setConfirmPassword(e.target.value);
                                                    setConfirmPasswordStarted(true);
                                                    validatePassword(password, e.target.value);
                                                }}
                                            />
                                            <Stack
                                                gap={1}
                                                sx={{
                                                    mt: confirmPasswordStarted ? 1 : 0,
                                                    ...fadeInOutStyle(confirmPasswordStarted),
                                                }}
                                            >
                                                <Typography
                                                    startDecorator={passwordValidations.match ? <Check /> : <Close />}
                                                    level="body-xs"
                                                    color={passwordValidations.match ? "success" : "danger"}
                                                >
                                                    Passwords match
                                                </Typography>
                                            </Stack>
                                        </FormControl>
                                        {errorMessage && (
                                            <Typography
                                                level="body-sm"
                                                color="danger"
                                                sx={fadeInOutStyle(!!errorMessage)}
                                            >
                                                {errorMessage}
                                            </Typography>
                                        )}
                                        <Stack gap={4} sx={{ mt: 2 }}>
                                            <Button type="submit" loading={signUpLoading} disabled={!isFormValid}>
                                                Sign up
                                            </Button>
                                        </Stack>
                                    </form>
                                </Stack>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};
