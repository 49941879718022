/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
    __generatedQueryInput: InputType;
    __generatedQueryOutput: OutputType;
};

export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    first_name
    last_name
    email
    rooms {
      nextToken
      __typename
    }
    updateComments {
      nextToken
      __typename
    }
    roomAccessRequests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getRoom = /* GraphQL */ `query GetRoom($id: ID!) {
  getRoom(id: $id) {
    id
    user_id
    private
    name
    private_code
    allowComments
    room_updates {
      nextToken
      __typename
    }
    room_recipients {
      nextToken
      __typename
    }
    sent_texts {
      nextToken
      __typename
    }
    room_access_requests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRoomQueryVariables, APITypes.GetRoomQuery>;
export const listRooms = /* GraphQL */ `query ListRooms(
  $filter: ModelRoomFilterInput
  $limit: Int
  $nextToken: String
) {
  listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user_id
      private
      name
      private_code
      allowComments
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRoomsQueryVariables, APITypes.ListRoomsQuery>;
export const roomsByUser_id = /* GraphQL */ `query RoomsByUser_id(
  $user_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRoomFilterInput
  $limit: Int
  $nextToken: String
) {
  roomsByUser_id(
    user_id: $user_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      private
      name
      private_code
      allowComments
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.RoomsByUser_idQueryVariables, APITypes.RoomsByUser_idQuery>;
export const roomByName = /* GraphQL */ `query RoomByName(
  $name: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRoomFilterInput
  $limit: Int
  $nextToken: String
) {
  roomByName(
    name: $name
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      private
      name
      private_code
      allowComments
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.RoomByNameQueryVariables, APITypes.RoomByNameQuery>;
export const getRoomUpdate = /* GraphQL */ `query GetRoomUpdate($id: ID!) {
  getRoomUpdate(id: $id) {
    id
    update
    image
    video
    created_at
    show_time
    room_id
    comments {
      nextToken
      __typename
    }
    reactions {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRoomUpdateQueryVariables, APITypes.GetRoomUpdateQuery>;
export const listRoomUpdates = /* GraphQL */ `query ListRoomUpdates(
  $filter: ModelRoomUpdateFilterInput
  $limit: Int
  $nextToken: String
) {
  listRoomUpdates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      update
      image
      video
      created_at
      show_time
      room_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRoomUpdatesQueryVariables, APITypes.ListRoomUpdatesQuery>;
export const roomUpdatesByRoom_idAndCreated_at = /* GraphQL */ `query RoomUpdatesByRoom_idAndCreated_at(
  $room_id: ID!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRoomUpdateFilterInput
  $limit: Int
  $nextToken: String
) {
  roomUpdatesByRoom_idAndCreated_at(
    room_id: $room_id
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      update
      image
      video
      created_at
      show_time
      room_id
      show_time
      createdAt
      updatedAt
      owner
      comments {
        items {
          id
          comment
          room_update_id
          createdAt
          updatedAt
          user_id
          user_info {
            first_name
            last_name
            email
          }
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.RoomUpdatesByRoom_idAndCreated_atQueryVariables,
    APITypes.RoomUpdatesByRoom_idAndCreated_atQuery
>;

export const guestRoomUpdatesByRoom_idAndCreated_at = /* GraphQL */ `query RoomUpdatesByRoom_idAndCreated_at(
  $room_id: ID!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRoomUpdateFilterInput
  $limit: Int
  $nextToken: String
) {
  roomUpdatesByRoom_idAndCreated_at(
    room_id: $room_id
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      update
      image
      video
      created_at
      room_id
      show_time
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.RoomUpdatesByRoom_idAndCreated_atQueryVariables,
    APITypes.RoomUpdatesByRoom_idAndCreated_atQuery
>;
export const getRoomRecipients = /* GraphQL */ `query GetRoomRecipients($id: ID!) {
  getRoomRecipients(id: $id) {
    id
    phone_number
    created_at
    room_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRoomRecipientsQueryVariables, APITypes.GetRoomRecipientsQuery>;
export const listRoomRecipients = /* GraphQL */ `query ListRoomRecipients(
  $filter: ModelRoomRecipientsFilterInput
  $limit: Int
  $nextToken: String
) {
  listRoomRecipients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      phone_number
      created_at
      room_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRoomRecipientsQueryVariables, APITypes.ListRoomRecipientsQuery>;
export const roomRecipientsByRoom_idAndCreated_at = /* GraphQL */ `query RoomRecipientsByRoom_idAndCreated_at(
  $room_id: ID!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRoomRecipientsFilterInput
  $limit: Int
  $nextToken: String
) {
  roomRecipientsByRoom_idAndCreated_at(
    room_id: $room_id
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      phone_number
      created_at
      room_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.RoomRecipientsByRoom_idAndCreated_atQueryVariables,
    APITypes.RoomRecipientsByRoom_idAndCreated_atQuery
>;
export const getSentTexts = /* GraphQL */ `query GetSentTexts($id: ID!) {
  getSentTexts(id: $id) {
    id
    message
    phone_number
    success
    created_at
    room_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSentTextsQueryVariables, APITypes.GetSentTextsQuery>;
export const listSentTexts = /* GraphQL */ `query ListSentTexts(
  $filter: ModelSentTextsFilterInput
  $limit: Int
  $nextToken: String
) {
  listSentTexts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      message
      phone_number
      success
      created_at
      room_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSentTextsQueryVariables, APITypes.ListSentTextsQuery>;
export const sentTextsByRoom_idAndCreated_at = /* GraphQL */ `query SentTextsByRoom_idAndCreated_at(
  $room_id: ID!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSentTextsFilterInput
  $limit: Int
  $nextToken: String
) {
  sentTextsByRoom_idAndCreated_at(
    room_id: $room_id
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      message
      phone_number
      success
      created_at
      room_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.SentTextsByRoom_idAndCreated_atQueryVariables,
    APITypes.SentTextsByRoom_idAndCreated_atQuery
>;
export const getRoomAccessRequests = /* GraphQL */ `query GetRoomAccessRequests($id: ID!) {
  getRoomAccessRequests(id: $id) {
    id
    room_id
    user_id
    user_info {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    approved
    reviewed
    created_at
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRoomAccessRequestsQueryVariables, APITypes.GetRoomAccessRequestsQuery>;
export const listRoomAccessRequests = /* GraphQL */ `query ListRoomAccessRequests(
  $filter: ModelRoomAccessRequestsFilterInput
  $limit: Int
  $nextToken: String
) {
  listRoomAccessRequests(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      room_id
      user_id
      user_info {
        id
        first_name
        last_name
        __typename
      }
      approved
      reviewed
      created_at
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRoomAccessRequestsQueryVariables, APITypes.ListRoomAccessRequestsQuery>;
export const roomAccessRequestsByRoom_idAndCreated_at = /* GraphQL */ `query RoomAccessRequestsByRoom_idAndCreated_at(
  $room_id: ID!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRoomAccessRequestsFilterInput
  $limit: Int
  $nextToken: String
) {
  roomAccessRequestsByRoom_idAndCreated_at(
    room_id: $room_id
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      room_id
      user_id
      approved
      reviewed
      created_at
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.RoomAccessRequestsByRoom_idAndCreated_atQueryVariables,
    APITypes.RoomAccessRequestsByRoom_idAndCreated_atQuery
>;
export const roomAccessRequestsByUser_id = /* GraphQL */ `query RoomAccessRequestsByUser_id(
  $user_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRoomAccessRequestsFilterInput
  $limit: Int
  $nextToken: String
) {
  roomAccessRequestsByUser_id(
    user_id: $user_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      room_id
      user_id
      approved
      reviewed
      created_at
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.RoomAccessRequestsByUser_idQueryVariables, APITypes.RoomAccessRequestsByUser_idQuery>;
export const getUpdateComments = /* GraphQL */ `query GetUpdateComments($id: ID!) {
  getUpdateComments(id: $id) {
    id
    comment
    room_update_id
    user_id
    user_info {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUpdateCommentsQueryVariables, APITypes.GetUpdateCommentsQuery>;
export const listUpdateComments = /* GraphQL */ `query ListUpdateComments(
  $filter: ModelUpdateCommentsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUpdateComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      comment
      room_update_id
      user_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUpdateCommentsQueryVariables, APITypes.ListUpdateCommentsQuery>;
export const updateCommentsByRoom_update_id = /* GraphQL */ `query UpdateCommentsByRoom_update_id(
  $room_update_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUpdateCommentsFilterInput
  $limit: Int
  $nextToken: String
) {
  updateCommentsByRoom_update_id(
    room_update_id: $room_update_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      comment
      room_update_id
      user_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.UpdateCommentsByRoom_update_idQueryVariables,
    APITypes.UpdateCommentsByRoom_update_idQuery
>;
export const updateCommentsByUser_id = /* GraphQL */ `query UpdateCommentsByUser_id(
  $user_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUpdateCommentsFilterInput
  $limit: Int
  $nextToken: String
) {
  updateCommentsByUser_id(
    user_id: $user_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      comment
      room_update_id
      user_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.UpdateCommentsByUser_idQueryVariables, APITypes.UpdateCommentsByUser_idQuery>;
export const getUpdateReactions = /* GraphQL */ `query GetUpdateReactions($id: ID!) {
  getUpdateReactions(id: $id) {
    id
    type
    room_update_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUpdateReactionsQueryVariables, APITypes.GetUpdateReactionsQuery>;
export const listUpdateReactions = /* GraphQL */ `query ListUpdateReactions(
  $filter: ModelUpdateReactionsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUpdateReactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      room_update_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUpdateReactionsQueryVariables, APITypes.ListUpdateReactionsQuery>;
export const updateReactionsByRoom_update_id = /* GraphQL */ `query UpdateReactionsByRoom_update_id(
  $room_update_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUpdateReactionsFilterInput
  $limit: Int
  $nextToken: String
) {
  updateReactionsByRoom_update_id(
    room_update_id: $room_update_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      room_update_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.UpdateReactionsByRoom_update_idQueryVariables,
    APITypes.UpdateReactionsByRoom_update_idQuery
>;
