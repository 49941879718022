import { Box } from "@mui/joy";
import React from "react";
import { GetStarted } from "../GetStarted/index";

export const Home = () => {
    return (
        <div>
            <Box
                sx={{
                    height: "100vh",
                    overflowY: "scroll",
                    scrollSnapType: "y mandatory",
                    "& > div": {
                        scrollSnapAlign: "start",
                    },
                }}
            >
                <GetStarted />
            </Box>
        </div>
    );
};
