import {
    Button,
    Modal,
    ModalDialog,
    ModalClose,
    FormControl,
    FormLabel,
    Input,
    DialogTitle,
    DialogContent,
    CardContent,
    Chip,
    Card,
    LinearProgress,
} from "@mui/joy";
import { generateClient } from "aws-amplify/api";
import React, { useEffect, useState } from "react";
import { deleteRoomUpdate, updateRoomUpdate } from "../../graphql/mutations";
import { useMutation } from "react-query";
import { uploadData } from "aws-amplify/storage";
import TimeInput from "../TimeInput";
import { Upload as UploadIcon, Done as CheckboxIcon } from "@mui/icons-material";

export const UpdateRoomUpdateModal = ({
    update,
    isOpen,
    setIsOpen,
}: {
    update?: any;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}) => {
    const client = generateClient();

    const [isManualChange, setIsManualChange] = useState(true);
    const [fileReady, setFileReady] = useState(update.image ? true : false);
    const [formData, setFormData] = useState({
        update: update.update,
        created_at: update.created_at,
        image: update.image,
        file: null as File | null,
        show_time: update?.show_time,
    });
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);

    useEffect(() => {
        setFormData({
            update: update.update,
            created_at: update.created_at,
            image: update.image,
            file: null,
            show_time: update?.show_time,
        });

        setFileReady(update.image ? true : false);
    }, [update, isOpen]);

    const updateRoomUpdateMutation = async (updatedFormData: any) => {
        const { data } = await client.graphql({
            query: updateRoomUpdate,
            variables: {
                input: {
                    id: update.id,
                    update: updatedFormData.update,
                    created_at: updatedFormData.created_at,
                    image: updatedFormData.image,
                    show_time: updatedFormData.show_time,
                },
            },
        });
        return data.updateRoomUpdate;
    };

    const deleteRoomUpdateMutation = async () => {
        const { data } = await client.graphql({
            query: deleteRoomUpdate,
            variables: { input: { id: update.id } },
        });
        return data.deleteRoomUpdate;
    };

    const { mutate, isLoading } = useMutation(updateRoomUpdateMutation, {
        onSuccess: (data) => {
            console.log("Successfully updated the update");
            setIsOpen(false);
            setFormData({ update: "", created_at: "", image: "", file: null, show_time: true });
        },
        onError: (error) => {
            console.error("Error creating room:", error);
        },
    });

    const { mutate: deleteRoomUpdateMutate, isLoading: deleteRoomUpdateIsLoading } = useMutation(
        deleteRoomUpdateMutation,
        {
            onSuccess: () => {
                console.log("Successfully deleted room update");
                setIsOpen(false);
                setFormData({ update: "", created_at: "", image: "", file: null, show_time: true });
            },
            onError: (error) => {
                console.error("Error deleting room update:", error);
            },
        }
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            setFormData((prevData) => ({ ...prevData, image: file.name, file }));
            setFileReady(true);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!formData.update.trim() || isNaN(Date.parse(formData.created_at))) {
            return;
        }

        const form = new FormData(e.target as HTMLFormElement);
        const time = form.get("time") as string;
        const showTime = form.has("show_time");

        // Update formData with the new values
        const updatedFormData = {
            ...formData,
            created_at: time,
            show_time: showTime,
        };

        try {
            if (updatedFormData.image !== update.image && updatedFormData.file) {
                try {
                    const uploadResult = uploadData({
                        key: updatedFormData.image,
                        data: updatedFormData.file,
                        options: {
                            onProgress: ({ transferredBytes, totalBytes }) => {
                                if (totalBytes) {
                                    setUploadProgress(Math.round((transferredBytes / totalBytes) * 100));
                                }
                            },
                        },
                    });

                    await uploadResult.result;
                    setUploadProgress(null);
                } catch (error) {
                    console.error("Error uploading file:", error);
                    setUploadProgress(null);
                    return;
                }
            }

            mutate(updatedFormData);

            setFormData({ update: "", created_at: "", image: "", file: null, show_time: true });
            setFileReady(false);
        } catch (error) {
            console.error("Error creating room update:", error);
        }
    };

    return (
        <Modal
            open={isOpen}
            sx={{ zIndex: 100000000 }}
            onClose={() => {
                setFormData({ update: "", created_at: "", image: "", file: null, show_time: true });
                setIsOpen(false);
            }}
        >
            <ModalDialog variant="soft">
                <ModalClose />
                <DialogTitle>Update Room Update</DialogTitle>
                <DialogContent>
                    <form noValidate onSubmit={handleSubmit}>
                        <Card
                            variant="soft"
                            sx={(theme) => ({
                                [theme.breakpoints.up(595)]: {
                                    minWidth: 420,
                                },
                            })}
                            color="neutral"
                            size="md"
                        >
                            <CardContent orientation="horizontal">
                                <Button
                                    component="label"
                                    role={undefined}
                                    tabIndex={-1}
                                    startDecorator={fileReady ? <CheckboxIcon /> : <UploadIcon />}
                                    sx={{ width: "100%" }}
                                    variant="solid"
                                    disabled={typeof uploadProgress === "number"}
                                >
                                    {fileReady ? "File Ready" : "Upload a picture or video"}
                                    <Input
                                        type="file"
                                        name="file"
                                        sx={{
                                            clip: "rect(0 0 0 0)",
                                            clipPath: "inset(50%)",
                                            overflow: "hidden",
                                            position: "absolute",
                                            bottom: 0,
                                            left: 0,
                                            whiteSpace: "nowrap",
                                            width: "1px",
                                            height: "1px",
                                        }}
                                        onChange={handleFileChange}
                                        disabled={typeof uploadProgress === "number"}
                                    />
                                </Button>
                                {fileReady && (
                                    <Chip
                                        size="md"
                                        variant="solid"
                                        color="danger"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setFormData((prevData) => ({ ...prevData, image: "" }));
                                            setFileReady(false);
                                        }}
                                        sx={{
                                            "--Chip-radius": "6px",
                                            fontWeight: 600,
                                        }}
                                        disabled={typeof uploadProgress === "number"}
                                    >
                                        Remove
                                    </Chip>
                                )}
                            </CardContent>
                            <CardContent>
                                <FormControl>
                                    <FormLabel>Update</FormLabel>
                                    <Input
                                        required
                                        placeholder="We are hungry"
                                        name="update"
                                        value={formData.update}
                                        onChange={handleInputChange}
                                        disabled={typeof uploadProgress === "number"}
                                    />
                                </FormControl>
                            </CardContent>
                            <TimeInput
                                isManualChange={isManualChange}
                                setIsManualChange={setIsManualChange}
                                currentTime={update.created_at || formData.created_at}
                                currentShowTime={formData.show_time}
                            />
                            <CardContent orientation="horizontal" sx={{ flexDirection: { xs: "column", sm: "row" } }}>
                                {uploadProgress ? (
                                    <CardContent>
                                        <LinearProgress size="lg" determinate value={uploadProgress} />
                                    </CardContent>
                                ) : (
                                    <>
                                        <Button
                                            role={undefined}
                                            tabIndex={-1}
                                            sx={{
                                                width: { xs: "100%", sm: "62%" },
                                                mb: { xs: 1, sm: 0 },
                                            }}
                                            variant="solid"
                                            loading={isLoading}
                                            type="submit"
                                            disabled={typeof uploadProgress === "number"}
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            size="md"
                                            variant="solid"
                                            color="danger"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                deleteRoomUpdateMutate();
                                            }}
                                            sx={{
                                                "--Chip-radius": "6px",
                                                fontWeight: 600,
                                                width: { xs: "100%", sm: "auto" },
                                            }}
                                            loading={deleteRoomUpdateIsLoading}
                                            disabled={typeof uploadProgress === "number"}
                                        >
                                            Delete Update
                                        </Button>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </form>
                </DialogContent>
            </ModalDialog>
        </Modal>
    );
};
