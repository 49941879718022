import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../Header/index";

const DashboardLayout = () => {
    return (
        <div style={{ display: "flex", height: "100vh" }}>
            <Header />
            <div style={{ flexGrow: 1, overflowY: "auto" }}>
                <Outlet />
            </div>
        </div>
    );
};

export default DashboardLayout;
