/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import TwoSidedLayout from "../TwoSidedLayout/index";
import { NavLink } from "react-router-dom";

export const GetStarted = () => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <TwoSidedLayout>
            <Typography color="primary" fontSize="lg" fontWeight="lg">
                Share Your{" "}
                <span onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    {isHovered ? "Journie ❤️" : "Journey"}
                </span>
            </Typography>
            <Typography level="h1" fontWeight="xl" fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)">
                Live Updates from the Delivery Room
            </Typography>
            <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
                Keep friends and family in the loop. Share every moment of your labor and delivery experience without
                the hassle.
            </Typography>
            <Button size="lg" endDecorator={<ArrowForward fontSize="inherit" />} component={NavLink} to="/signup">
                Get Started
            </Button>
            <Typography>
                Already a member?{" "}
                <Link fontWeight="lg" component={NavLink} to="/signin">
                    Sign in
                </Link>
            </Typography>
        </TwoSidedLayout>
    );
};
