import React from "react";
import { FormHelperText } from "@mui/joy";
import { FieldApi } from "@tanstack/react-form";

interface FieldInfoProps {
    field: FieldApi<any, any, any, any>;
    errorMessage?: string;
}

const ValidationFieldInfo: React.FC<FieldInfoProps> = ({ field, errorMessage }) => {
    return (
        <>
            {errorMessage ? <FormHelperText>{errorMessage}</FormHelperText> : null}
            {field.state.meta.touchedErrors ? <FormHelperText>{field.state.meta.touchedErrors}</FormHelperText> : null}
            {field.state.meta.isValidating ? <FormHelperText>Validating...</FormHelperText> : null}
        </>
    );
};

export default ValidationFieldInfo;
