export const openSidebar = () => {
    if (typeof window !== "undefined") {
        document.body.style.overflow = "hidden";
        document.documentElement.style.setProperty("--SideNavigation-slideIn", "1");
    }
};

export const closeSidebar = () => {
    if (typeof window !== "undefined") {
        document.documentElement.style.removeProperty("--SideNavigation-slideIn");
        document.body.style.removeProperty("overflow");
    }
};

export const toggleSidebar = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
        const slideIn = window.getComputedStyle(document.documentElement).getPropertyValue("--SideNavigation-slideIn");
        if (slideIn) {
            closeSidebar();
        } else {
            openSidebar();
        }
    }
};

export const encryptEmail = (email: string) => {
    const [localPart, domain] = email.split("@");
    const encryptedLocalPart = localPart[0] + "***";
    const encryptedDomain = domain[0] + "***";
    return `${encryptedLocalPart}@${encryptedDomain}`;
};
