import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { StyledEngineProvider } from "@mui/material";
import App from "./App";

import { Amplify } from "aws-amplify";
import config from "./aws-exports.js";
import { Authenticator } from "@aws-amplify/ui-react";

Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
    <React.StrictMode>
        <Authenticator.Provider>
            <StyledEngineProvider injectFirst>
                <App />
            </StyledEngineProvider>
        </Authenticator.Provider>
    </React.StrictMode>
);
