import React, { createContext, useContext, useState, ReactNode } from "react";

interface ModalContextType {
    createRoomModalOpen: boolean;
    setCreateRoomModalOpen: (open: boolean) => void;
    needsRefetch: boolean;
    setNeedsRefetch: (refetch: boolean) => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

interface ModalProviderProps {
    children: ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
    const [createRoomModalOpen, setCreateRoomModalOpen] = useState(false);
    const [needsRefetch, setNeedsRefetch] = useState(true);

    return (
        <ModalContext.Provider
            value={{
                createRoomModalOpen,
                setCreateRoomModalOpen,
                needsRefetch,
                setNeedsRefetch,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export const useModalContext = () => {
    const context = useContext(ModalContext);
    if (context === undefined) {
        throw new Error("useModal must be used within a ModalProvider");
    }
    return context;
};
