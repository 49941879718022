import { extendTheme } from "@mui/joy/styles";
import { inputClasses } from "@mui/joy/Input";

export default extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    50: "#E6F2EF",
                    100: "#CDE5DF",
                    200: "#B3D8CF",
                    300: "#99CBBF",
                    400: "#80BEAF",
                    500: "#66B19F",
                    600: "#42AB9B",
                    700: "#328D7F",
                    800: "#216F63",
                    900: "#105147",
                    solidBg: "var(--joy-palette-primary-600)",
                    solidHoverBg: "var(--joy-palette-primary-500)",
                    solidActiveBg: "var(--joy-palette-primary-400)",
                },
            },
        },
        dark: {
            palette: {
                primary: {
                    50: "#105147",
                    100: "#216F63",
                    200: "#328D7F",
                    300: "#42AB9B",
                    400: "#66B19F",
                    500: "#80BEAF",
                    600: "#99CBBF",
                    700: "#B3D8CF",
                    800: "#CDE5DF",
                    900: "#E6F2EF",
                    solidBg: "var(--joy-palette-primary-700)",
                    solidColor: "var(--joy-palette-common-black)",
                    solidHoverBg: "var(--joy-palette-primary-600)",
                    solidActiveBg: "var(--joy-palette-primary-400)",
                },
                background: {
                    body: "var(--joy-palette-common-black)",
                    surface: "var(--joy-palette-neutral-900)",
                },
            },
        },
    },
    fontFamily: {
        display: "'Inter', var(--joy-fontFamily-fallback)",
        body: "'Inter', var(--joy-fontFamily-fallback)",
    },
    components: {
        JoyInput: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    ...(ownerState.variant === "outlined" && {
                        [`&:not(.${inputClasses.focused}):hover::before`]: {
                            boxShadow: `inset 0 0 0 2px ${theme.vars.palette?.[ownerState.color!]?.outlinedBorder}`,
                        },
                    }),
                }),
                input: {
                    caretColor: "var(--Input-focusedHighlight)",
                },
            },
        },
    },
});
