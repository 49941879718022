import { CardContent, FormLabel, Input, Switch, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";

const TimeInput = ({
    isManualChange,
    setIsManualChange,
    currentTime,
    currentShowTime,
}: {
    isManualChange: boolean;
    setIsManualChange: (value: boolean) => void;
    currentTime?: string;
    currentShowTime?: boolean;
}) => {
    const [time, setTime] = useState<string>(
        currentTime
            ? new Date(currentTime).toLocaleString("sv-SE").replace(" ", "T")
            : new Date().toLocaleString("sv-SE").replace(" ", "T")
    );
    const [showTime, setShowTime] = useState<boolean>(currentShowTime ?? true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!isManualChange) {
                setTime(new Date().toLocaleString("sv-SE").replace(" ", "T"));
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [isManualChange]);

    return (
        <CardContent>
            <div>
                <FormLabel sx={{ width: "100%", mb: "6px" }}>
                    Time{" "}
                    {!isManualChange && showTime && (
                        <Typography level="body-xs" color="danger" sx={{ marginLeft: 1 }}>
                            Live •
                        </Typography>
                    )}
                    <Switch
                        checked={showTime}
                        startDecorator={
                            showTime ? (
                                <Typography level="body-xs">Don't Show Time</Typography>
                            ) : (
                                <Typography level="body-xs">Show Time</Typography>
                            )
                        }
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setTime(
                                currentTime
                                    ? new Date(currentTime).toLocaleString("sv-SE").replace(" ", "T")
                                    : new Date().toLocaleString("sv-SE").replace(" ", "T")
                            );
                            setIsManualChange(false);
                            setShowTime(event.target.checked);
                        }}
                        slotProps={{
                            input: { name: "show_time" },
                            root: { sx: { marginLeft: "auto" } },
                        }}
                    />
                </FormLabel>
                <Input
                    type={showTime ? "datetime-local" : "date"}
                    name="time"
                    value={showTime ? time : time.split("T")[0]}
                    onChange={(e) => {
                        setIsManualChange(true);
                        setTime(e.target.value);
                    }}
                />
            </div>
        </CardContent>
    );
};

export default TimeInput;
