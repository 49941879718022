import { useAuthenticator } from "@aws-amplify/ui-react";
import {
    Box,
    Button,
    Container,
    Typography,
    typographyClasses,
    Card,
    CardContent,
    Grid,
    CircularProgress,
    LinearProgress,
    Divider,
    Chip,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../context/modalContext";
import { useQuery } from "react-query";
import { fetchUserData } from "../../react-query/user";
import { generateClient } from "aws-amplify/api";
import { listRooms } from "../../graphql/queries";
import { ArrowDownward } from "@mui/icons-material";

type User = {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
};

type Room = {
    id: string;
    name: string;
    createdAt: string;
    user_id: string;
    allowComments: boolean;
    private: boolean;
};

const Dashboard = () => {
    const client = generateClient();
    const { setCreateRoomModalOpen } = useModalContext();
    const { needsRefetch, setNeedsRefetch } = useModalContext();
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const navigate = useNavigate();

    const [rooms, setRooms] = useState<Room[]>([]);
    const [userData, setUserData] = useState<User | null>(null);

    useEffect(() => {
        if (authStatus === "unauthenticated") {
            navigate("/signin");
        }
    }, [authStatus]);

    const fetchRooms = async (userId: string) => {
        const { data } = await client.graphql({
            query: listRooms,
            variables: { filter: { user_id: { eq: userId } } },
        });
        return data.listRooms.items;
    };

    const { refetch: refetchUserData, isLoading: userDataIsLoading } = useQuery("userData", fetchUserData, {
        onSuccess: (data: User) => {
            setUserData(data);
        },
    });
    const { refetch: refetchRooms, isLoading: roomsIsLoading } = useQuery(
        ["rooms", userData?.userId],
        () => fetchRooms(userData?.userId ?? ""),
        {
            enabled: !!userData?.userId,
            onSuccess: (data: Room[]) => {
                setRooms(data);
            },
        }
    );

    useEffect(() => {
        if (needsRefetch && authStatus === "authenticated") {
            refetchUserData();
            refetchRooms();
            setNeedsRefetch(false);
        }
    }, [needsRefetch, authStatus, refetchUserData, refetchRooms, setNeedsRefetch]);

    return (
        <Container
            sx={(theme) => ({
                position: "relative",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                py: 10,
                gap: 4,
                [theme.breakpoints.up(834)]: {
                    flexDirection: "column",
                    gap: 6,
                },
            })}
        >
            <Box
                sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "1rem",
                    textAlign: "center",
                    flexShrink: 999,
                    [theme.breakpoints.up(834)]: {
                        minWidth: 420,
                        alignItems: "center",
                        textAlign: "center",
                    },
                    [`& .${typographyClasses.root}`]: {
                        textWrap: "balance",
                    },
                })}
            >
                {userDataIsLoading ? (
                    <Box sx={{ width: "100px" }}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <Typography color="primary" fontSize="lg" fontWeight="md">
                        Welcome, {userData?.firstName || "Human"}
                    </Typography>
                )}
                <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg" fontWeight="lg">
                    To get started, please create a room or join an existing one.
                </Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid>
                    <Card variant="soft" invertedColors={true} color="neutral">
                        <div>
                            <Typography level="title-lg">Create Room</Typography>
                            <Typography level="body-sm">Create a new room and invite friends to join.</Typography>
                        </div>
                        <CardContent>
                            <Button
                                variant="solid"
                                fullWidth
                                color="primary"
                                aria-label="Create Room"
                                sx={{
                                    ml: "auto",
                                    alignSelf: "center",
                                    fontWeight: 600,
                                }}
                                onClick={() => setCreateRoomModalOpen(true)}
                            >
                                Create
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {roomsIsLoading ? (
                <CircularProgress />
            ) : (
                rooms &&
                rooms?.length > 0 && (
                    <>
                        <Divider>
                            <Chip variant="soft" color="neutral" size="lg" endDecorator={<ArrowDownward />}>
                                Existing Rooms
                            </Chip>
                        </Divider>
                        <Grid container spacing={2}>
                            {rooms.map((room) => (
                                <Grid
                                    xs={rooms.length > 1 ? 12 : undefined}
                                    sm={rooms.length > 1 ? 6 : undefined}
                                    md={rooms.length > 1 ? 6 : undefined}
                                    key={room.id}
                                >
                                    <Card
                                        variant="soft"
                                        invertedColors={true}
                                        color="neutral"
                                        sx={{ width: "100%", minWidth: 220 }}
                                    >
                                        <div>
                                            <Typography level="title-lg">{room.name}</Typography>
                                            <Typography level="body-sm">
                                                {new Date(room.createdAt).toDateString()}
                                            </Typography>
                                        </div>
                                        <CardContent>
                                            <Button
                                                variant="solid"
                                                fullWidth={rooms.length > 1}
                                                color="primary"
                                                aria-label={`Join ${room.name}`}
                                                sx={(theme) => ({
                                                    ml: "auto",
                                                    alignSelf: "center",
                                                    fontWeight: 600,
                                                    [theme.breakpoints.up("sm")]: {
                                                        width: "auto",
                                                    },
                                                })}
                                                onClick={() => navigate(`/room/${room.name}`)}
                                            >
                                                Join
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )
            )}
        </Container>
    );
};

export default Dashboard;
