import React, { useMemo } from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import { ColorSchemeToggle } from "../ColorSchemeToggle/index";
import { Home, Logout } from "@mui/icons-material";

import { CONNECTION_STATE_CHANGE, ConnectionState } from "aws-amplify/api";
import { Hub } from "aws-amplify/utils";
import { Box, Typography } from "@mui/joy";
import { useLocation, useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import { useAuthenticator } from "@aws-amplify/ui-react";

export const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { authStatus } = useAuthenticator((context) => [context.authStatus]);

    const authenticated = useMemo(() => authStatus === "authenticated", [authStatus]);

    const [connectionState, setConnectionState] = React.useState<any>();

    Hub.listen("api", (data: any) => {
        const { payload } = data;
        if (payload.event === CONNECTION_STATE_CHANGE) {
            const connectionState = payload.data.connectionState as ConnectionState;
            setConnectionState(connectionState);
        }
    });

    const renderConnectionState = (connectionState: string) => {
        if (connectionState === "Connected") {
            return (
                <Typography level="body-sm" sx={{ fontSize: { xs: "0.6rem", sm: "0.875rem" } }}>
                    No need to refresh. Updates will appear automatically
                </Typography>
            );
        }

        if (
            connectionState === "Disconnected" ||
            connectionState === "ConnectionDisrupted" ||
            connectionState === "ConnectionDisruptedPendingNetwork" ||
            connectionState === "ConnectedPendingNetwork" ||
            connectionState === "ConnectedPendingKeepAlive" ||
            connectionState === "ConnectedPendingDisconnect"
        ) {
            return (
                <Typography level="body-sm" color="danger" sx={{ fontSize: { xs: "0.6rem", sm: "0.875rem" } }}>
                    Disconnected. Please refresh the page to get the latest data.
                </Typography>
            );
        }
    };

    return (
        <Sheet
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "fixed",
                top: 0,
                width: "100vw",
                height: "var(--Header-height)",
                zIndex: 9998,
                p: 2,
                gap: 1,
                borderBottom: "1px solid",
                borderColor: "background.level1",
                boxShadow: "sm",
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ":root": {
                        "--Header-height": "52px",
                    },
                })}
            />
            <IconButton
                onClick={() => navigate(authenticated ? "/dashboard" : "/")}
                variant="outlined"
                color="neutral"
                size="sm"
            >
                <Home />
            </IconButton>
            {location.pathname.includes("/room") && (
                <Box sx={{ flex: 1, display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            textAlign: "center",
                        }}
                    >
                        <Typography level="title-sm">{connectionState}</Typography>
                        {renderConnectionState(connectionState)}
                    </div>
                </Box>
            )}
            <ColorSchemeToggle sx={{ ml: "auto" }} />
            {authenticated && (
                <IconButton
                    onClick={async () => {
                        await signOut();
                        navigate("/");
                    }}
                    variant="outlined"
                    color="neutral"
                    size="sm"
                >
                    <Logout />
                </IconButton>
            )}
        </Sheet>
    );
};
