import React, { Fragment, useEffect, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    Snackbar,
    Stack,
    TabPanel,
    Textarea,
    Tooltip,
    Typography,
} from "@mui/joy";
import { ExpandMore } from "@mui/icons-material";
import PhoneInput from "../../PhoneInput";
import { useMutation, useQuery } from "react-query";
import { fetchUserAttributes } from "aws-amplify/auth";
import { roomRecipientsByRoom_idAndCreated_at, sentTextsByRoom_idAndCreated_at } from "../../../graphql/queries";
import { ModelSortDirection } from "../../../API";
import { createRoomRecipients, createSentTexts, deleteRoomRecipients } from "../../../graphql/mutations";
import { onCreateSentTexts } from "../../../graphql/subscriptions";
import { AreYouSureModal } from "./AreYouSureModal";

type TTextUpdatePanelProps = {
    roomId: string;
    isRoomPrivate: boolean;
    privateRoomCode: string | null;
    client: any;
};

export const TextUpdatePanel = ({ roomId, isRoomPrivate, privateRoomCode, client }: TTextUpdatePanelProps) => {
    const [textValue, setTextValue] = useState("");
    const [recipients, setRecipients] = useState<{ id: string; phone_number: string }[]>([
        { id: "", phone_number: "" },
    ]);
    const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false);
    const [sentTexts, setSentTexts] = useState<
        { id: string; success: boolean; number: string; sentDate: string; message: string }[]
    >([]);
    const [sentTextAccordianExpanded, setSentTextAccordianExpanded] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [textsAreSending, setTextsAreSending] = useState(false);

    const { data: userInfo } = useQuery("currentUserInfo", fetchUserAttributes);

    useQuery(["sentTexts", roomId], () => getSentTexts(roomId), {
        enabled: !!roomId,
        onSuccess: (data) => {
            const sentTextsData = data.map(
                (text: { id: string; phone_number: string; success: boolean; message: string; created_at: Date }) => ({
                    id: text.id,
                    number: text.phone_number,
                    success: text.success,
                    message: text.message,
                    sentDate: text.created_at,
                })
            );
            setSentTexts([...sentTextsData]);
        },
    });

    useQuery(["roomRecipients", roomId], () => getRoomRecipients(roomId), {
        enabled: !!roomId,
        onSuccess: (data) => {
            const recipientsData = data.map((recipient: { id: string; phone_number: string }) => ({
                id: recipient.id,
                phone_number: recipient.phone_number,
            }));
            setRecipients([...recipientsData, { id: "", phone_number: "" }]);
        },
    });

    const getSentTexts = async (roomId: string) => {
        const { data } = await client.graphql({
            query: sentTextsByRoom_idAndCreated_at,
            variables: { room_id: roomId, sortDirection: ModelSortDirection.DESC },
            authMode: "userPool",
        });

        return data?.sentTextsByRoom_idAndCreated_at?.items;
    };

    const getRoomRecipients = async (roomId: string) => {
        const { data } = await client.graphql({
            query: roomRecipientsByRoom_idAndCreated_at,
            variables: { room_id: roomId, sortDirection: ModelSortDirection.ASC },
            authMode: "userPool",
        });

        return data?.roomRecipientsByRoom_idAndCreated_at?.items || [""];
    };

    const handleAddSendText = async ({ success, number }: { success: boolean; number: string }) => {
        try {
            await client.graphql({
                query: createSentTexts,
                variables: {
                    input: {
                        success,
                        message: textValue,
                        phone_number: number,
                        created_at: new Date().toLocaleString("sv-SE").replace(" ", "T"),
                        room_id: roomId,
                    },
                },
            });
        } catch (error) {
            console.error("Error sending text", error);
        }
    };

    const handleSendText = async () => {
        setTextsAreSending(true);
        const spacedText = textValue.replace(/(?<!\n)\n(?!\n)/g, "\n\n");
        for (const [index, { phone_number }] of recipients.entries()) {
            if (phone_number.length === 14) {
                const number = phone_number.replace(/\D/g, "");
                try {
                    const response = await fetch("https://textbelt.com/text", {
                        method: "post",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            phone: number,
                            message: spacedText,
                            key: process.env.REACT_APP_TEXT_API_SECRET,
                        }),
                    });
                    const data = await response.json();

                    await handleAddSendText({ success: data.success, number: phone_number });
                } catch (error) {
                    console.error(phone_number, "Error sending text:", error);
                }
            }
        }
        setTextsAreSending(false);
        setNotificationOpen(true);
    };

    const handleAddRecipient = async (number: string) => {
        try {
            await client.graphql({
                query: createRoomRecipients,
                variables: {
                    input: {
                        phone_number: number,
                        created_at: new Date().toLocaleString("sv-SE").replace(" ", "T"),
                        room_id: roomId,
                    },
                },
            });
        } catch (error) {
            console.error("Error adding new recipient", error);
        }

        setRecipients([...recipients, { id: "", phone_number: "" }]);
    };

    const handleRemoveRecipient = async (id: string) => {
        try {
            await client.graphql({
                query: deleteRoomRecipients,
                variables: { input: { id } },
            });
            setRecipients(recipients.filter((recipient) => recipient.id !== id));
        } catch (error) {
            console.error("Error deleting recipient", error);
        }
    };

    const handleRecipientChange = (index: number, value: string) => {
        const newRecipients = [...recipients];
        newRecipients[index].phone_number = value;
        setRecipients(newRecipients);
    };

    useEffect(() => {
        if (userInfo) {
            setTextValue(
                `Exciting news! ${userInfo.given_name!} and Spouse are on their way to the hospital to welcome their new baby into the world.\nFollow along and get real-time updates by going to ${
                    window.location.href
                }${isRoomPrivate ? `\nThe code to enter is: ${privateRoomCode}` : ``}`
            );
        }
    }, [userInfo, isRoomPrivate, privateRoomCode]);

    const subscribeToSentTexts = useMutation(
        async () => {
            const subscription = client
                .graphql({
                    query: onCreateSentTexts,
                    filter: { room_id: { eq: roomId } },
                    authMode: "userPool",
                })
                .subscribe({
                    next: ({ data }: any) => {
                        if (data.onCreateSentTexts.room_id !== roomId) return;

                        setSentTextAccordianExpanded(true);

                        setSentTexts(
                            (
                                prevUpdates: {
                                    id: string;
                                    success: boolean;
                                    number: string;
                                    sentDate: string;
                                    message: string;
                                }[]
                            ) => {
                                const isDuplicate = prevUpdates.some(
                                    (text) =>
                                        text.number === data.onCreateSentTexts.phone_number &&
                                        text.sentDate === data.onCreateSentTexts.created_at
                                );

                                if (isDuplicate) return prevUpdates;

                                return [
                                    {
                                        id: data.onCreateSentTexts.id,
                                        number: data.onCreateSentTexts.phone_number,
                                        success: data.onCreateSentTexts.success,
                                        message: data.onCreateSentTexts.message,
                                        sentDate: data.onCreateSentTexts.created_at,
                                    },
                                    ...prevUpdates,
                                ];
                            }
                        );
                    },
                    error: (error: any) => console.error("Error on sent text creation subscription: ", error),
                });
            return subscription;
        },
        {
            onError: (error) => {
                console.error("Error subscribing to comments: ", error);
            },
        }
    );

    useEffect(() => {
        if (roomId !== "" && roomId !== undefined) {
            const subscriptionPromise = subscribeToSentTexts.mutateAsync();
            let subscription: { unsubscribe: () => void } | undefined;

            subscriptionPromise.then((sub) => {
                subscription = sub;
            });

            const timeoutId = setTimeout(() => {
                subscription?.unsubscribe();
            }, 3 * 60 * 60 * 1000);

            return () => {
                clearTimeout(timeoutId);
                subscription?.unsubscribe();
            };
        }
    }, [roomId]);

    return (
        <>
            <TabPanel value={0}>
                <form
                    noValidate
                    onSubmit={async (e) => {
                        e.preventDefault();
                        try {
                        } catch (error) {
                            console.error(error);
                        }
                    }}
                >
                    <Card
                        variant="soft"
                        sx={(theme) => ({
                            maxWidth: 340,
                            [theme.breakpoints.up(440)]: {
                                maxWidth: 420,
                            },
                        })}
                        color="neutral"
                        size="md"
                    >
                        <CardContent>
                            <FormControl sx={{ marginBottom: "16px" }}>
                                <Textarea
                                    minRows={2}
                                    maxRows={9}
                                    value={textValue}
                                    onChange={(e) => setTextValue(e.target.value)}
                                    sx={{ paddingTop: "15px", paddingBottom: "15px" }}
                                />
                                <FormHelperText>
                                    This text is pre-filled with an initial message to encourage viewership but can be
                                    fully customized.
                                </FormHelperText>
                            </FormControl>
                            <Button fullWidth onClick={() => setAreYouSureModalOpen(true)} loading={textsAreSending}>
                                Send text
                            </Button>
                            <Accordion sx={{ marginTop: "16px" }}>
                                <AccordionSummary indicator={<ExpandMore />}>Recipients</AccordionSummary>
                                <AccordionDetails>
                                    <Grid
                                        container
                                        rowSpacing={2}
                                        columnSpacing={2}
                                        sx={{
                                            maxHeight: recipients.length >= 4 ? "210px" : "auto",
                                            overflowY: "auto",
                                            marginTop: "4px",
                                        }}
                                    >
                                        {recipients.map((recipient, index) => {
                                            return (
                                                <Fragment key={recipient.id}>
                                                    <Grid xs={9}>
                                                        <Input
                                                            value={recipient.phone_number}
                                                            onChange={(e) =>
                                                                handleRecipientChange(index, e.target.value)
                                                            }
                                                            placeholder={`Recipient ${index + 1}`}
                                                            slotProps={{
                                                                input: { component: PhoneInput },
                                                            }}
                                                            disabled={index !== recipients.length - 1}
                                                        />
                                                    </Grid>
                                                    <Grid xs>
                                                        <Button
                                                            variant="outlined"
                                                            color={
                                                                index === recipients.length - 1 ? "primary" : "danger"
                                                            }
                                                            onClick={() =>
                                                                index === recipients.length - 1
                                                                    ? handleAddRecipient(recipient.phone_number)
                                                                    : handleRemoveRecipient(recipient.id)
                                                            }
                                                            disabled={recipient.phone_number.length !== 14}
                                                            sx={{ width: "100%" }}
                                                        >
                                                            {index === recipients.length - 1 ? "Add" : "Remove"}
                                                        </Button>
                                                    </Grid>
                                                </Fragment>
                                            );
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{ marginTop: "16px" }}
                                expanded={sentTextAccordianExpanded}
                                onChange={(event, expanded) => {
                                    setSentTextAccordianExpanded(expanded);
                                }}
                            >
                                <AccordionSummary indicator={<ExpandMore />}>Sent Texts</AccordionSummary>
                                <AccordionDetails>
                                    <Grid
                                        container
                                        rowSpacing={2}
                                        columnSpacing={2}
                                        sx={{
                                            maxHeight: sentTexts.length >= 4 ? "210px" : "auto",
                                            overflowY: "auto",
                                            marginTop: "4px",
                                        }}
                                    >
                                        {sentTexts.map((text) => {
                                            return (
                                                <Fragment key={text.id}>
                                                    <Grid xs={9}>
                                                        <FormControl>
                                                            <Input
                                                                value={text.number}
                                                                slotProps={{
                                                                    input: { component: PhoneInput },
                                                                }}
                                                                disabled
                                                            />
                                                            <FormHelperText>
                                                                {new Date(text.sentDate).toLocaleString()}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid xs>
                                                        <Tooltip
                                                            title={
                                                                <Box sx={{ maxWidth: 320 }}>
                                                                    <Typography level="body-xs">
                                                                        {text.message}
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                            variant="soft"
                                                            size="sm"
                                                            arrow
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                color={text.success ? "success" : "danger"}
                                                                sx={{ width: "100%" }}
                                                            >
                                                                {text.success ? "Sent" : "Failed"}
                                                            </Button>
                                                        </Tooltip>
                                                    </Grid>
                                                </Fragment>
                                            );
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                </form>
            </TabPanel>
            {areYouSureModalOpen && (
                <AreYouSureModal handleSendText={handleSendText} setIsOpen={setAreYouSureModalOpen} />
            )}
            <Snackbar
                open={notificationOpen}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                color={"success"}
                autoHideDuration={2000}
                style={{ zIndex: 9999 }}
                variant="soft"
                onClose={() => {
                    setNotificationOpen(false);
                }}
            >
                <Stack spacing={0.5}>
                    <Typography level="title-md">Successfully Sent Texts</Typography>
                    <Typography level="body-sm">Check the Sent Texts Accordion below to see all details</Typography>
                </Stack>
            </Snackbar>
        </>
    );
};
