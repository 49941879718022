/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
    __generatedSubscriptionInput: InputType;
    __generatedSubscriptionOutput: OutputType;
};

export const onCreateUser = /* GraphQL */ `subscription OnCreateUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onCreateUser(filter: $filter, owner: $owner) {
    id
    first_name
    last_name
    email
    rooms {
      nextToken
      __typename
    }
    updateComments {
      nextToken
      __typename
    }
    roomAccessRequests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<APITypes.OnCreateUserSubscriptionVariables, APITypes.OnCreateUserSubscription>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onUpdateUser(filter: $filter, owner: $owner) {
    id
    first_name
    last_name
    email
    rooms {
      nextToken
      __typename
    }
    updateComments {
      nextToken
      __typename
    }
    roomAccessRequests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<APITypes.OnUpdateUserSubscriptionVariables, APITypes.OnUpdateUserSubscription>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onDeleteUser(filter: $filter, owner: $owner) {
    id
    first_name
    last_name
    email
    rooms {
      nextToken
      __typename
    }
    updateComments {
      nextToken
      __typename
    }
    roomAccessRequests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<APITypes.OnDeleteUserSubscriptionVariables, APITypes.OnDeleteUserSubscription>;
export const onCreateRoom = /* GraphQL */ `subscription OnCreateRoom(
  $filter: ModelSubscriptionRoomFilterInput
  $owner: String
) {
  onCreateRoom(filter: $filter, owner: $owner) {
    id
    user_id
    private
    name
    private_code
    allowComments
    room_updates {
      nextToken
      __typename
    }
    room_recipients {
      nextToken
      __typename
    }
    sent_texts {
      nextToken
      __typename
    }
    room_access_requests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<APITypes.OnCreateRoomSubscriptionVariables, APITypes.OnCreateRoomSubscription>;
export const onUpdateRoom = /* GraphQL */ `subscription OnUpdateRoom(
  $filter: ModelSubscriptionRoomFilterInput
  $owner: String
) {
  onUpdateRoom(filter: $filter, owner: $owner) {
    id
    user_id
    private
    name
    private_code
    allowComments
    room_updates {
      nextToken
      __typename
    }
    room_recipients {
      nextToken
      __typename
    }
    sent_texts {
      nextToken
      __typename
    }
    room_access_requests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<APITypes.OnUpdateRoomSubscriptionVariables, APITypes.OnUpdateRoomSubscription>;
export const onDeleteRoom = /* GraphQL */ `subscription OnDeleteRoom(
  $filter: ModelSubscriptionRoomFilterInput
  $owner: String
) {
  onDeleteRoom(filter: $filter, owner: $owner) {
    id
    user_id
    private
    name
    private_code
    allowComments
    room_updates {
      nextToken
      __typename
    }
    room_recipients {
      nextToken
      __typename
    }
    sent_texts {
      nextToken
      __typename
    }
    room_access_requests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<APITypes.OnDeleteRoomSubscriptionVariables, APITypes.OnDeleteRoomSubscription>;
export const onCreateRoomUpdate = /* GraphQL */ `subscription OnCreateRoomUpdate(
  $filter: ModelSubscriptionRoomUpdateFilterInput
  $owner: String
) {
  onCreateRoomUpdate(filter: $filter, owner: $owner) {
    id
    update
    image
    video
    created_at
    show_time
    room_id
    comments {
      nextToken
      __typename
    }
    reactions {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<APITypes.OnCreateRoomUpdateSubscriptionVariables, APITypes.OnCreateRoomUpdateSubscription>;
export const onUpdateRoomUpdate = /* GraphQL */ `subscription OnUpdateRoomUpdate(
  $filter: ModelSubscriptionRoomUpdateFilterInput
  $owner: String
) {
  onUpdateRoomUpdate(filter: $filter, owner: $owner) {
    id
    update
    image
    video
    created_at
    show_time
    room_id
    comments {
      nextToken
      __typename
    }
    reactions {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<APITypes.OnUpdateRoomUpdateSubscriptionVariables, APITypes.OnUpdateRoomUpdateSubscription>;
export const onDeleteRoomUpdate = /* GraphQL */ `subscription OnDeleteRoomUpdate(
  $filter: ModelSubscriptionRoomUpdateFilterInput
  $owner: String
) {
  onDeleteRoomUpdate(filter: $filter, owner: $owner) {
    id
    update
    image
    video
    created_at
    show_time
    room_id
    comments {
      nextToken
      __typename
    }
    reactions {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<APITypes.OnDeleteRoomUpdateSubscriptionVariables, APITypes.OnDeleteRoomUpdateSubscription>;
export const onCreateRoomRecipients = /* GraphQL */ `subscription OnCreateRoomRecipients(
  $filter: ModelSubscriptionRoomRecipientsFilterInput
  $owner: String
) {
  onCreateRoomRecipients(filter: $filter, owner: $owner) {
    id
    phone_number
    created_at
    room_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateRoomRecipientsSubscriptionVariables,
    APITypes.OnCreateRoomRecipientsSubscription
>;
export const onUpdateRoomRecipients = /* GraphQL */ `subscription OnUpdateRoomRecipients(
  $filter: ModelSubscriptionRoomRecipientsFilterInput
  $owner: String
) {
  onUpdateRoomRecipients(filter: $filter, owner: $owner) {
    id
    phone_number
    created_at
    room_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateRoomRecipientsSubscriptionVariables,
    APITypes.OnUpdateRoomRecipientsSubscription
>;
export const onDeleteRoomRecipients = /* GraphQL */ `subscription OnDeleteRoomRecipients(
  $filter: ModelSubscriptionRoomRecipientsFilterInput
  $owner: String
) {
  onDeleteRoomRecipients(filter: $filter, owner: $owner) {
    id
    phone_number
    created_at
    room_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteRoomRecipientsSubscriptionVariables,
    APITypes.OnDeleteRoomRecipientsSubscription
>;
export const onCreateSentTexts = /* GraphQL */ `subscription OnCreateSentTexts(
  $filter: ModelSubscriptionSentTextsFilterInput
  $owner: String
) {
  onCreateSentTexts(filter: $filter, owner: $owner) {
    id
    message
    phone_number
    success
    created_at
    room_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<APITypes.OnCreateSentTextsSubscriptionVariables, APITypes.OnCreateSentTextsSubscription>;
export const onUpdateSentTexts = /* GraphQL */ `subscription OnUpdateSentTexts(
  $filter: ModelSubscriptionSentTextsFilterInput
  $owner: String
) {
  onUpdateSentTexts(filter: $filter, owner: $owner) {
    id
    message
    phone_number
    success
    created_at
    room_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<APITypes.OnUpdateSentTextsSubscriptionVariables, APITypes.OnUpdateSentTextsSubscription>;
export const onDeleteSentTexts = /* GraphQL */ `subscription OnDeleteSentTexts(
  $filter: ModelSubscriptionSentTextsFilterInput
  $owner: String
) {
  onDeleteSentTexts(filter: $filter, owner: $owner) {
    id
    message
    phone_number
    success
    created_at
    room_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<APITypes.OnDeleteSentTextsSubscriptionVariables, APITypes.OnDeleteSentTextsSubscription>;
export const onCreateRoomAccessRequests = /* GraphQL */ `subscription OnCreateRoomAccessRequests(
  $filter: ModelSubscriptionRoomAccessRequestsFilterInput
  $owner: String
) {
  onCreateRoomAccessRequests(filter: $filter, owner: $owner) {
    id
    room_id
    user_id
    user_info {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    approved
    reviewed
    created_at
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateRoomAccessRequestsSubscriptionVariables,
    APITypes.OnCreateRoomAccessRequestsSubscription
>;
export const onUpdateRoomAccessRequests = /* GraphQL */ `subscription OnUpdateRoomAccessRequests(
  $filter: ModelSubscriptionRoomAccessRequestsFilterInput
  $owner: String
) {
  onUpdateRoomAccessRequests(filter: $filter, owner: $owner) {
    id
    room_id
    user_id
    user_info {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    approved
    reviewed
    created_at
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateRoomAccessRequestsSubscriptionVariables,
    APITypes.OnUpdateRoomAccessRequestsSubscription
>;
export const onDeleteRoomAccessRequests = /* GraphQL */ `subscription OnDeleteRoomAccessRequests(
  $filter: ModelSubscriptionRoomAccessRequestsFilterInput
  $owner: String
) {
  onDeleteRoomAccessRequests(filter: $filter, owner: $owner) {
    id
    room_id
    user_id
    user_info {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    approved
    reviewed
    created_at
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteRoomAccessRequestsSubscriptionVariables,
    APITypes.OnDeleteRoomAccessRequestsSubscription
>;
export const onCreateUpdateComments = /* GraphQL */ `subscription OnCreateUpdateComments(
  $filter: ModelSubscriptionUpdateCommentsFilterInput
  $owner: String
) {
  onCreateUpdateComments(filter: $filter, owner: $owner) {
    id
    comment
    room_update_id
    user_id
    user_info {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateUpdateCommentsSubscriptionVariables,
    APITypes.OnCreateUpdateCommentsSubscription
>;
export const onUpdateUpdateComments = /* GraphQL */ `subscription OnUpdateUpdateComments(
  $filter: ModelSubscriptionUpdateCommentsFilterInput
  $owner: String
) {
  onUpdateUpdateComments(filter: $filter, owner: $owner) {
    id
    comment
    room_update_id
    user_id
    user_info {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateUpdateCommentsSubscriptionVariables,
    APITypes.OnUpdateUpdateCommentsSubscription
>;
export const onDeleteUpdateComments = /* GraphQL */ `subscription OnDeleteUpdateComments(
  $filter: ModelSubscriptionUpdateCommentsFilterInput
  $owner: String
) {
  onDeleteUpdateComments(filter: $filter, owner: $owner) {
    id
    comment
    room_update_id
    user_id
    user_info {
      id
      first_name
      last_name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteUpdateCommentsSubscriptionVariables,
    APITypes.OnDeleteUpdateCommentsSubscription
>;
export const onCreateUpdateReactions = /* GraphQL */ `subscription OnCreateUpdateReactions(
  $filter: ModelSubscriptionUpdateReactionsFilterInput
  $owner: String
) {
  onCreateUpdateReactions(filter: $filter, owner: $owner) {
    id
    type
    room_update_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateUpdateReactionsSubscriptionVariables,
    APITypes.OnCreateUpdateReactionsSubscription
>;
export const onUpdateUpdateReactions = /* GraphQL */ `subscription OnUpdateUpdateReactions(
  $filter: ModelSubscriptionUpdateReactionsFilterInput
  $owner: String
) {
  onUpdateUpdateReactions(filter: $filter, owner: $owner) {
    id
    type
    room_update_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateUpdateReactionsSubscriptionVariables,
    APITypes.OnUpdateUpdateReactionsSubscription
>;
export const onDeleteUpdateReactions = /* GraphQL */ `subscription OnDeleteUpdateReactions(
  $filter: ModelSubscriptionUpdateReactionsFilterInput
  $owner: String
) {
  onDeleteUpdateReactions(filter: $filter, owner: $owner) {
    id
    type
    room_update_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteUpdateReactionsSubscriptionVariables,
    APITypes.OnDeleteUpdateReactionsSubscription
>;
