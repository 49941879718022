import React, { Fragment, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Input,
    LinearProgress,
    ListItemDecorator,
    Snackbar,
    Switch,
    TabPanel,
} from "@mui/joy";
import { useMutation, useQuery } from "react-query";
import { getRoom } from "../../../graphql/queries";
import { useForm } from "@tanstack/react-form";
import ValidationFieldInfo from "../../ValidationFieldInfo";
import { updateRoom, updateRoomAccessRequests } from "../../../graphql/mutations";
import { Block, Check } from "@mui/icons-material";
import { RoomAccessRequest } from "..";

type TTextUpdatePanelProps = {
    roomId: string;
    client: any;
    roomAccessRequests: RoomAccessRequest[];
    setRoomAccessRequests: any;
    requestsNeedReview: number;
};

export const SettingsPanel = ({
    roomId,
    client,
    roomAccessRequests,
    setRoomAccessRequests,
    requestsNeedReview,
}: TTextUpdatePanelProps) => {
    const [showPasscode, setShowPasscode] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [handlingRequest, setHandlingRequest] = useState<string | null>(null);

    const { data: roomInfoData, isLoading: roomInfoIsLoading } = useQuery(
        ["roomInfo", roomId],
        () => getRoomInfo(roomId),
        {
            enabled: !!roomId,
            onSuccess: (data) => {
                setShowPasscode(data.private);
            },
        }
    );

    const getRoomInfo = async (roomId: string) => {
        const { data } = await client.graphql({
            query: getRoom,
            variables: { id: roomId },
            authMode: "userPool",
        });

        return data?.getRoom;
    };

    const updateRoomMutation = async ({
        privateRoom,
        passcode,
        comments,
    }: {
        privateRoom: boolean;
        passcode: number;
        comments: boolean;
    }) => {
        const { data } = await client.graphql({
            query: updateRoom,
            variables: {
                id: roomId,
                input: { id: roomId, private: privateRoom, private_code: passcode, allowComments: comments },
            },
        });
        return data.updateRoom;
    };

    const { mutate, isLoading: mutationIsLoading } = useMutation(updateRoomMutation, {
        onSuccess: (data) => {
            setNotificationOpen(true);
            console.log("Room updated:", data);
        },
        onError: (error) => {
            console.error("Error creating room:", error);
        },
    });

    const handleRoomAccessRequestMutation = async ({ id, approved }: { id: string; approved: boolean }) => {
        const { data } = await client.graphql({
            query: updateRoomAccessRequests,
            variables: {
                id: id,
                input: { id: id, reviewed: true, approved },
            },
            authMode: "userPool",
        });
        return data.updateRoomAccessRequests;
    };

    const { mutate: mutateRoomAccessRequest, isLoading: mutateRoomAccessRequestIsLoading } = useMutation(
        handleRoomAccessRequestMutation,
        {
            onSuccess: (data) => {
                setRoomAccessRequests((prevRequests: RoomAccessRequest[]) =>
                    prevRequests.map((request: RoomAccessRequest) =>
                        request.id === data.id ? { ...request, reviewed: true, approved: data.approved } : request
                    )
                );
            },
            onError: (error) => {
                console.error("Error reviewing access request:", error);
            },
        }
    );

    const form = useForm({
        defaultValues: {
            privateRoom: roomInfoData?.private ?? false,
            passcode: roomInfoData?.private_code ?? Math.floor(100000 + Math.random() * 900000).toString(),
            comments: roomInfoData?.allowComments ?? true,
        },
        onSubmit: async ({ value }) => {
            mutate({
                privateRoom: value.privateRoom,
                passcode: value.privateRoom === true ? value.passcode : null,
                comments: value.comments,
            });
        },
    });

    if (roomInfoIsLoading) {
        return (
            <TabPanel value={2}>
                <Card
                    variant="soft"
                    sx={(theme) => ({
                        minWidth: 340,
                        [theme.breakpoints.up(440)]: {
                            minWidth: 420,
                        },
                    })}
                    color="neutral"
                    size="md"
                >
                    <CardContent>
                        <LinearProgress />
                    </CardContent>
                </Card>
            </TabPanel>
        );
    }

    return (
        <TabPanel value={2}>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    form.handleSubmit();
                }}
            >
                <Card
                    variant="soft"
                    sx={(theme) => ({
                        minWidth: 340,
                        [theme.breakpoints.up(440)]: {
                            minWidth: 420,
                            maxWidth: 420,
                        },
                    })}
                    color="neutral"
                    size="md"
                >
                    <CardContent orientation="horizontal">
                        <form.Field
                            name="privateRoom"
                            children={(field) => (
                                <>
                                    <FormControl>
                                        <FormLabel sx={{ mb: 0 }}>Private Room</FormLabel>
                                        <FormHelperText sx={{ mt: 0 }}>Require passcode to join</FormHelperText>
                                    </FormControl>
                                    <Switch
                                        checked={field.state.value}
                                        onChange={(event) => {
                                            field.handleChange(event.target.checked);
                                            setShowPasscode(event.target.checked);
                                        }}
                                        sx={{ ml: "auto", alignSelf: "center" }}
                                    />
                                </>
                            )}
                        />
                    </CardContent>
                    {showPasscode && (
                        <CardContent sx={{ width: "100%" }}>
                            <form.Field
                                name="passcode"
                                validators={{
                                    onSubmit: ({ value }) => {
                                        const isValid = /^\d{4}(\d{2})?$/.test(value);
                                        return !isValid ? "Must be a 4 or 6 digit number." : undefined;
                                    },
                                }}
                                children={(field) => (
                                    <FormControl error={field.state.meta.errors.length > 0}>
                                        <FormLabel sx={{ mb: 0 }}>Passcode</FormLabel>
                                        <Input
                                            type="number"
                                            value={field.state.value ?? ""}
                                            onChange={(event) => field.handleChange(event.target.value)}
                                        />
                                        <ValidationFieldInfo field={field} />
                                    </FormControl>
                                )}
                            />
                        </CardContent>
                    )}
                    <CardContent orientation="horizontal">
                        <form.Field
                            name="comments"
                            children={(field) => (
                                <>
                                    <FormControl>
                                        <FormLabel sx={{ mb: 0 }}>Comments</FormLabel>
                                        <FormHelperText sx={{ mt: 0 }}>Allow comments on your posts</FormHelperText>
                                        <FormHelperText sx={{ mt: 0, fontSize: "0.6rem" }}>
                                            Comments are visible only to you and the commenter
                                        </FormHelperText>
                                    </FormControl>
                                    <Switch
                                        checked={field.state.value}
                                        sx={{ ml: "auto", alignSelf: "center" }}
                                        onChange={(event) => field.handleChange(event.target.checked)}
                                    />
                                </>
                            )}
                        />
                    </CardContent>
                    <CardContent>
                        <Divider />
                    </CardContent>
                    <CardContent>
                        <Button sx={{ width: "100%" }} type="submit" loading={mutationIsLoading}>
                            Update
                        </Button>
                    </CardContent>
                    <Accordion defaultExpanded={requestsNeedReview > 0}>
                        <AccordionSummary>
                            {requestsNeedReview > 0 ? (
                                <ListItemDecorator>
                                    <Chip variant="solid" size="md" sx={{ mr: 1 }}>
                                        {requestsNeedReview}
                                    </Chip>
                                    Room Access Requests
                                </ListItemDecorator>
                            ) : (
                                "Room Access Requests"
                            )}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid
                                container
                                rowSpacing={2}
                                columnSpacing={1}
                                sx={{
                                    maxHeight: roomAccessRequests.length >= 4 ? "210px" : "auto",
                                    overflowY: "auto",
                                    marginTop: "4px",
                                }}
                            >
                                {roomAccessRequests.map((accessRequest) => {
                                    return (
                                        <Fragment key={accessRequest.id}>
                                            <Grid xs={8} md={9}>
                                                <FormControl>
                                                    <Input
                                                        value={
                                                            accessRequest.user_info.first_name +
                                                            " " +
                                                            accessRequest.user_info.last_name
                                                        }
                                                        disabled
                                                    />
                                                    <FormHelperText>
                                                        {accessRequest.reviewed
                                                            ? `${
                                                                  accessRequest.approved ? "Approved" : "Denied"
                                                              } at: ${new Date(
                                                                  accessRequest.updatedAt
                                                              ).toLocaleString()}`
                                                            : `Requested at: ${new Date(
                                                                  accessRequest.createdAt
                                                              ).toLocaleString()}`}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>

                                            <Grid xs sx={{ position: "relative" }}>
                                                <IconButton
                                                    variant="outlined"
                                                    color="success"
                                                    sx={{
                                                        position: "absolute",
                                                        opacity: accessRequest.reviewed
                                                            ? accessRequest.approved
                                                                ? 1
                                                                : 0
                                                            : 1,
                                                        transition: "opacity 0.3s ease-in-out",
                                                        pointerEvents: accessRequest.reviewed ? "none" : "auto",
                                                    }}
                                                    onClick={() => {
                                                        setHandlingRequest(accessRequest.id);
                                                        mutateRoomAccessRequest({
                                                            id: accessRequest.id,
                                                            approved: true,
                                                        });
                                                    }}
                                                    loading={
                                                        mutateRoomAccessRequestIsLoading &&
                                                        handlingRequest === accessRequest.id
                                                    }
                                                >
                                                    <Check />
                                                </IconButton>
                                            </Grid>
                                            <Grid xs sx={{ position: "relative" }}>
                                                <IconButton
                                                    variant="outlined"
                                                    color="danger"
                                                    sx={{
                                                        position: "absolute",
                                                        opacity: accessRequest.reviewed
                                                            ? !accessRequest.approved
                                                                ? 1
                                                                : 0
                                                            : 1,
                                                        transition: "opacity 0.3s ease-in-out",
                                                        pointerEvents: accessRequest.reviewed ? "none" : "auto",
                                                    }}
                                                    onClick={() => {
                                                        setHandlingRequest(accessRequest.id);
                                                        mutateRoomAccessRequest({
                                                            id: accessRequest.id,
                                                            approved: false,
                                                        });
                                                    }}
                                                    loading={
                                                        mutateRoomAccessRequestIsLoading &&
                                                        handlingRequest === accessRequest.id
                                                    }
                                                >
                                                    <Block />
                                                </IconButton>
                                            </Grid>
                                        </Fragment>
                                    );
                                })}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Card>
            </form>
            <Snackbar
                open={notificationOpen}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                color={"success"}
                autoHideDuration={2000}
                style={{ zIndex: 9999 }}
                variant="soft"
                onClose={() => {
                    setNotificationOpen(false);
                }}
            >
                Successfully updated room settings
            </Snackbar>
        </TabPanel>
    );
};
