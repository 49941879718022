import {
    Box,
    Container,
    Typography,
    typographyClasses,
    Card,
    CardContent,
    CardOverflow,
    AspectRatio,
    Input,
    Button,
    FormControl,
    FormLabel,
    Snackbar,
    Link,
    CircularProgress,
    FormHelperText,
    Divider,
    Chip,
} from "@mui/joy";
import { generateClient } from "aws-amplify/api";
import React, { Fragment, useEffect, useMemo, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { getCurrentUser, fetchUserAttributes } from "aws-amplify/auth";
import {
    guestRoomUpdatesByRoom_idAndCreated_at,
    listRooms,
    roomAccessRequestsByUser_id,
    roomUpdatesByRoom_idAndCreated_at,
    roomsByUser_id,
} from "../../graphql/queries";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ArrowDownward } from "@mui/icons-material";
import { getUrl } from "aws-amplify/storage";
import { createUpdateComments, createRoomAccessRequests } from "../../graphql/mutations";
import { ModelSortDirection } from "../../API";
import {
    onCreateRoomUpdate,
    onCreateUpdateComments,
    onDeleteRoomUpdate,
    onUpdateRoomUpdate,
} from "../../graphql/subscriptions";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useForm } from "@tanstack/react-form";
import ValidationFieldInfo from "../ValidationFieldInfo";
import { RoomAdminController } from "../RoomAdminController";
import { UpdateRoomUpdateModal } from "../UpdateRoomUpdateModal";
import { PlayArrow } from "@mui/icons-material";

interface Update {
    url?: {
        url: URL;
        expiresAt: Date;
    };
    videoUrl?: {
        url: URL;
        expiresAt: Date;
    };
    id: string;
    update?: string | null;
    image?: string | null;
    video?: string | null;
    comments?: any;
    show_time?: any;
    created_at: string;
}

export const Room = () => {
    const client = generateClient();
    const location = useLocation();
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);

    const [userId, setUserId] = useState<string | null>(null);
    const [isRoomAdmin, setIsRoomAdmin] = useState(false);
    const [roomId, setRoomId] = useState("");
    const [isRoomPrivate, setIsRoomPrivate] = useState<boolean>(false);
    const [privateRoomCode, setPrivateRoomCode] = useState<string | null>(null);
    const [updates, setUpdates] = useState<Update[]>([]);
    const [successfulNotificationOpen, setSuccessfulNotificationOpen] = useState(false);
    const [failedNotificationOpen, setFailedNotificationOpen] = useState(false);
    const [currentTabIndex, setCurrentTabIndex] = useState(1);
    const [accessGrantedUser, setAccessGrantedUser] = useState(false);
    const [requestedAccess, setRequestedAccess] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);
    const [allowedComments, setAllowedComments] = useState(true);

    const [editUpdateInfo, setEditUpdateInfo] = useState<Update | null>(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const [playVideo, setPlayVideo] = useState<string | null>(null);

    const roomName = location.pathname.substring(6);

    const isGuest = useMemo(() => authStatus !== "authenticated" && !userId, [authStatus, userId]);
    const unlockedPrivateRoom = useMemo(() => {
        if (isRoomPrivate) {
            if (accessGrantedUser || isRoomAdmin) {
                return true;
            }
            const expirationTime = localStorage.getItem(roomName + "-expirationTime");
            if (expirationTime) {
                const { room_id, expiresAt } = JSON.parse(expirationTime);
                if (room_id === roomId && new Date().getTime() < expiresAt) {
                    return true;
                }
            }
            return false;
        }
    }, [isRoomPrivate, roomId, roomName, accessGrantedUser, isRoomAdmin]);

    useQuery("currentUser", getCurrentUser, {
        onSuccess: (data) => setUserId(data.userId),
    });

    const { isLoading: roomIsLoading, error: roomError } = useQuery(
        ["room", roomName, isGuest],
        async () => {
            const apiData = await client.graphql({
                query: listRooms,
                variables: { filter: { name: { eq: roomName } } },
                authMode: isGuest ? "iam" : "userPool",
            });
            return apiData.data.listRooms.items[0];
        },
        {
            enabled: !!roomName || roomName.length > 0,
            onSuccess: (data) => {
                if (data) {
                    setRoomId(data.id);
                    setIsRoomPrivate(data.private);
                    setPrivateRoomCode(data?.private_code || null);
                    setAllowedComments(data.allowComments ?? true);
                }
            },
            onError: (error) => {
                console.error("Error fetching room: ", error);
            },
        }
    );

    const queryClient = useQueryClient();

    const { isLoading: updatesIsLoading, error: updatesError } = useQuery(
        ["roomUpdates", roomId, isGuest],
        async () => {
            if (!roomId) return [];

            const apiData = await client.graphql({
                query: isGuest ? guestRoomUpdatesByRoom_idAndCreated_at : roomUpdatesByRoom_idAndCreated_at,
                variables: { room_id: roomId, sortDirection: ModelSortDirection.DESC },
                authMode: isGuest ? "iam" : "userPool",
            });
            const updatesFromAPI = apiData.data.roomUpdatesByRoom_idAndCreated_at.items;
            return await Promise.all(
                updatesFromAPI.map(async (update) => {
                    let url;
                    let videoUrl;
                    if (update.image) {
                        url = await getUrl({ key: update.image });
                    }

                    if (update.video) {
                        videoUrl = await getUrl({ key: update.video });
                    }

                    return { ...update, url, videoUrl };
                })
            );
        },
        {
            enabled: !!roomId && roomId.length > 0,
            staleTime: 60000, // 1 minute
            cacheTime: 3600000, // 1 hour
            onSuccess: (data) => {
                if (data) {
                    setUpdates(data);
                }
            },
            onError: (error) => {
                console.error("Error fetching updates: ", error);
            },
        }
    );

    useEffect(() => {
        const invalidateQueries = async () => {
            if (roomId) {
                await queryClient.invalidateQueries({
                    queryKey: ["roomUpdates", "verifyRoomAdmin", "checkIfRequestedAccess", "currentUser"],
                });
            }
        };

        invalidateQueries();
    }, [roomId, isGuest, queryClient]);

    const subscribeToRoomUpdates = useMutation(
        async () => {
            let lastProcessedId: string | null = null;
            let debounceTimer: NodeJS.Timeout | null = null;

            const processUpdate = (newUpdate: Update) => {
                if (debounceTimer) {
                    clearTimeout(debounceTimer);
                }

                debounceTimer = setTimeout(async () => {
                    if (newUpdate.id === lastProcessedId) {
                        return; // Skip if this update was already processed
                    }
                    lastProcessedId = newUpdate.id;

                    let url;
                    let videoUrl;
                    if (newUpdate.image) {
                        url = await getUrl({ key: newUpdate.image });
                    }

                    if (newUpdate.video) {
                        videoUrl = await getUrl({ key: newUpdate.video });
                    }

                    const updateWithUrl = { ...newUpdate, url, videoUrl };
                    setUpdates((prevUpdates) => {
                        // Check if the update already exists
                        const updateExists = prevUpdates.some((update) => update.id === newUpdate.id);
                        if (updateExists) {
                            return prevUpdates; // Return existing updates if this one is already present
                        }
                        const sortedUpdates = [...prevUpdates, updateWithUrl].sort(
                            (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                        );
                        return sortedUpdates;
                    });
                }, 300); // Debounce for 300ms
            };

            const subscription = client
                .graphql({
                    query: onCreateRoomUpdate,
                    variables: { filter: { room_id: { eq: roomId } } },
                    authMode: isGuest ? "iam" : "userPool",
                })
                .subscribe({
                    next: ({ data }) => {
                        const newUpdate = data.onCreateRoomUpdate;
                        processUpdate(newUpdate);
                    },
                    error: (error) => console.error("Error during .subscribe: ", error),
                });
            return subscription;
        },
        {
            onError: (error) => {
                console.error("Error subscribing to room updates: ", error);
            },
        }
    );

    const subscribeToUpdatedRoomUpdates = useMutation(
        async () => {
            const subscription = client
                .graphql({
                    query: onUpdateRoomUpdate,
                    variables: { filter: { room_id: { eq: roomId } } },
                    authMode: isGuest ? "iam" : "userPool",
                })
                .subscribe({
                    next: async ({ data }) => {
                        const updatedUpdate = data.onUpdateRoomUpdate;
                        let url;
                        if (updatedUpdate.image) {
                            url = await getUrl({ key: updatedUpdate.image });
                        }
                        const updateWithUrl = { ...updatedUpdate, url };
                        setUpdates((prevUpdates) => {
                            const index = prevUpdates.findIndex((update) => update.id === updatedUpdate.id);
                            if (index === -1) return prevUpdates; // If not found, return the previous state

                            const newUpdates = [...prevUpdates];
                            newUpdates[index] = {
                                ...updateWithUrl,
                                comments: prevUpdates[index].comments,
                            };

                            // Check if sorting is necessary
                            if (
                                (index > 0 &&
                                    new Date(newUpdates[index - 1].created_at).getTime() <
                                        new Date(updateWithUrl.created_at).getTime()) ||
                                (index < newUpdates.length - 1 &&
                                    new Date(newUpdates[index + 1].created_at).getTime() >
                                        new Date(updateWithUrl.created_at).getTime())
                            ) {
                                return newUpdates.sort(
                                    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                                );
                            }

                            return newUpdates;
                        });
                    },
                    error: (error) => console.error("Error during .subscribe: ", error),
                });
            return subscription;
        },
        {
            onError: (error) => {
                console.error("Error subscribing to room updates: ", error);
            },
        }
    );

    const subscribeToDeletedRoomUpdates = useMutation(
        async () => {
            const subscription = client
                .graphql({
                    query: onDeleteRoomUpdate,
                    variables: { filter: { room_id: { eq: roomId } } },
                    authMode: isGuest ? "iam" : "userPool",
                })
                .subscribe({
                    next: async ({ data }) => {
                        const deletedUpdate = data.onDeleteRoomUpdate;
                        setUpdates((prevUpdates) => {
                            return prevUpdates.filter((update) => update.id !== deletedUpdate.id);
                        });
                    },
                    error: (error) => console.error("Error during .subscribe: ", error),
                });
            return subscription;
        },
        {
            onError: (error) => {
                console.error("Error subscribing to room updates: ", error);
            },
        }
    );

    const subscribeToComments = useMutation(
        async () => {
            const subscription = client
                .graphql({
                    query: onCreateUpdateComments,
                    authMode: "userPool",
                })
                .subscribe({
                    next: ({ data }) => {
                        const newComment = data.onCreateUpdateComments;
                        setUpdates((prevUpdates) =>
                            prevUpdates.map((update) => {
                                if (update.id === newComment.room_update_id) {
                                    return {
                                        ...update,
                                        comments: {
                                            items: [...(update?.comments?.items || []), newComment],
                                        },
                                    };
                                }
                                return update;
                            })
                        );
                    },
                    error: (error) => console.error("Error on comment creation subscription: ", error),
                });
            return subscription;
        },
        {
            onError: (error) => {
                console.error("Error subscribing to comments: ", error);
            },
        }
    );

    useEffect(() => {
        const subscribe = async () => {
            if (roomId !== "" && roomId !== undefined && (!isRoomPrivate || unlockedPrivateRoom)) {
                const roomUpdateSubscription = subscribeToRoomUpdates.mutateAsync();
                const updatedRoomUpdateSubscription = subscribeToUpdatedRoomUpdates.mutateAsync();
                const deletedRoomUpdateSubscription = subscribeToDeletedRoomUpdates.mutateAsync();
                let commentsSubscription;

                if (isRoomAdmin) {
                    commentsSubscription = subscribeToComments.mutateAsync();
                }

                let roomUpdateSub: any, updatedRoomUpdateSub: any, deletedRoomUpdateSub: any, commentsSub: any;

                roomUpdateSubscription.then((sub) => {
                    roomUpdateSub = sub;
                });

                updatedRoomUpdateSubscription.then((sub) => {
                    updatedRoomUpdateSub = sub;
                });

                deletedRoomUpdateSubscription.then((sub) => {
                    deletedRoomUpdateSub = sub;
                });

                if (isRoomAdmin && commentsSubscription) {
                    commentsSubscription.then((sub) => {
                        commentsSub = sub;
                    });
                }

                const timeoutId = setTimeout(() => {
                    roomUpdateSub?.unsubscribe();
                    updatedRoomUpdateSub?.unsubscribe();
                    deletedRoomUpdateSub?.unsubscribe();
                    commentsSub?.unsubscribe();
                }, 3 * 60 * 60 * 1000);

                return () => {
                    clearTimeout(timeoutId);
                    roomUpdateSub?.unsubscribe();
                    updatedRoomUpdateSub?.unsubscribe();
                    deletedRoomUpdateSub?.unsubscribe();
                    commentsSub?.unsubscribe();
                };
            }
        };

        subscribe();
    }, [roomId, isRoomPrivate, unlockedPrivateRoom, authStatus, isRoomAdmin]);

    const verifyRoomAdmin = async (userId: string, roomName: string) => {
        const { data } = await client.graphql({
            query: roomsByUser_id,
            variables: {
                user_id: userId,
                filter: {
                    name: { eq: roomName },
                },
            },
        });
        return data?.roomsByUser_id?.items?.length > 0;
    };

    useQuery(["verifyRoomAdmin", userId, roomName], () => verifyRoomAdmin(userId!, roomName), {
        enabled: !!userId && authStatus === "authenticated",
        onSuccess: (data) => setIsRoomAdmin(data),
    });

    const handleRequestAccess = useMutation(
        async (userId: string) => {
            await client.graphql({
                query: createRoomAccessRequests,
                variables: {
                    input: {
                        room_id: roomId,
                        user_id: userId,
                        approved: false,
                        reviewed: false,
                        created_at: new Date().toLocaleString("sv-SE").replace(" ", "T"),
                    },
                },
            });
        },
        {
            onSuccess: () => {
                setRequestedAccess(true);
            },
            onError: (error) => {
                console.error("Error requesting access: ", error);
            },
        }
    );

    const checkIfRequestedAccess = async (userId: string, roomId: string) => {
        const { data } = await client.graphql({
            query: roomAccessRequestsByUser_id,
            variables: {
                user_id: userId,
                filter: {
                    room_id: { eq: roomId },
                },
            },
        });
        return data?.roomAccessRequestsByUser_id?.items[0];
    };

    useQuery(["checkIfRequestedAccess", userId, roomId], () => checkIfRequestedAccess(userId!, roomId), {
        enabled: !!userId && authStatus === "authenticated",
        onSuccess: (data) => {
            if (data) {
                setRequestedAccess(true);
                setAccessGrantedUser(data.approved);
                // If it was reviewed and not approved
                !data.approved && data.reviewed && setAccessDenied(true);
            }
        },
    });

    const ImageWithAspectRatio = ({ src, alt }: { src: string; alt: string }) => {
        const [aspectRatio, setAspectRatio] = useState("3/4");

        const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
            const ratio = naturalWidth > naturalHeight ? "4/3" : "3/4";
            setAspectRatio(ratio);
        };

        return (
            <AspectRatio ratio={aspectRatio} objectFit="cover">
                <img src={src} onLoad={handleImageLoad} alt={alt} loading="lazy" />
            </AspectRatio>
        );
    };

    const VideoWithAspectRatio = ({ src, type }: { src: string; type: string }) => {
        const aspectRatioRef = useRef("9/16");
        const [videoError, setVideoError] = useState(false);
        const videoRef = useRef<HTMLVideoElement>(null);

        const handleMetadataLoad = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
            const video = e.target as HTMLVideoElement;
            const { videoWidth, videoHeight } = video;
            aspectRatioRef.current = videoWidth > videoHeight ? "16/9" : "9/16";
            setVideoError(false);
        };

        const handleVideoError = () => {
            setVideoError(true);
        };

        return (
            <AspectRatio variant="soft" ratio={aspectRatioRef.current} objectFit="contain">
                {videoError ? (
                    <Typography color="danger">Failed to load video</Typography>
                ) : (
                    <video
                        ref={videoRef}
                        key={src}
                        autoPlay
                        muted
                        playsInline
                        controls
                        onLoadedMetadata={handleMetadataLoad}
                        onError={handleVideoError}
                        preload="metadata"
                    >
                        <source src={src} type={type} />
                    </video>
                )}
            </AspectRatio>
        );
    };

    const VideoWithAspectRatioMemo = React.memo(VideoWithAspectRatio);
    const ImageWithAspectRatioMemo = React.memo(ImageWithAspectRatio);

    const privateCodeForm = useForm({
        defaultValues: {
            code: "",
        },
        onSubmit: async () => {
            const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000;
            localStorage.setItem(
                roomName + "-expirationTime",
                JSON.stringify({ room_id: roomId, expiresAt: expirationTime })
            );
            setIsRoomPrivate(false);
        },
    });

    const handlePlayButtonClick = (updateId: string) => {
        setPlayVideo(updateId);
    };

    if (roomIsLoading || updatesIsLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress determinate={false} size="lg" variant="plain" />
            </Box>
        );
    }

    if (roomError) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Typography variant="solid" color="danger">
                    Room not found
                </Typography>
            </Box>
        );
    }

    if (isRoomPrivate && !unlockedPrivateRoom && !isRoomAdmin) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Card>
                    <CardContent>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                privateCodeForm.handleSubmit();
                            }}
                        >
                            <privateCodeForm.Field
                                name="code"
                                validators={{
                                    onSubmit: ({ value }) => {
                                        if (value !== privateRoomCode) {
                                            return "Invalid code";
                                        }
                                    },
                                }}
                                children={(field) => (
                                    <FormControl required error={field.state.meta.errors.length > 0}>
                                        <FormLabel>Room requires a code to enter.</FormLabel>
                                        <Input
                                            id={field.name}
                                            value={field.state.value}
                                            onChange={(e) => field.handleChange(e.target.value)}
                                            placeholder="Enter the room code"
                                            type="number"
                                        />
                                        <FormHelperText>
                                            {requestedAccess ? (
                                                accessDenied ? (
                                                    "Request denied"
                                                ) : (
                                                    "Request pending"
                                                )
                                            ) : (
                                                <>
                                                    {isGuest ? (
                                                        <>
                                                            Don't have a code?
                                                            <Link
                                                                href={`/signup?redirect=${encodeURIComponent(
                                                                    location.pathname
                                                                )}`}
                                                                level="body-sm"
                                                                sx={{ mb: 0.1 }}
                                                            >
                                                                Sign up
                                                            </Link>{" "}
                                                            to request access
                                                        </>
                                                    ) : (
                                                        <>
                                                            Don't have a code?
                                                            <Button
                                                                variant="plain"
                                                                onClick={() => handleRequestAccess.mutateAsync(userId!)}
                                                                loading={handleRequestAccess.isLoading}
                                                            >
                                                                Request access
                                                            </Button>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </FormHelperText>
                                        <ValidationFieldInfo field={field} />
                                    </FormControl>
                                )}
                            />
                        </form>
                    </CardContent>
                </Card>
            </Box>
        );
    }

    if (updatesError) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Typography variant="solid" color="danger">
                    Error fetching updates
                </Typography>
            </Box>
        );
    }

    if (updates.length === 0 && !isRoomAdmin && (!isRoomPrivate || (isRoomPrivate && unlockedPrivateRoom))) {
        return (
            <Box
                sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: theme.breakpoints.down("sm") ? "30vh" : "20vh",
                    textAlign: "center",
                    gap: 2,
                    borderRadius: 2,
                    boxShadow: 1,
                    p: 3,
                })}
            >
                <Typography level="h4" color="neutral">
                    No updates yet!
                </Typography>
                <Typography level="body-md" color="neutral">
                    Stay tuned for the latest updates.
                </Typography>
            </Box>
        );
    }

    return (
        <Container
            sx={(theme) => ({
                position: "relative",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                py: 10,
                gap: 4,
                [theme.breakpoints.up(834)]: {
                    flexDirection: "column",
                    gap: 6,
                },
                [theme.breakpoints.up(1199)]: {
                    gap: 12,
                },
            })}
        >
            {isRoomAdmin && (
                <RoomAdminController
                    roomId={roomId}
                    setSuccessfulNotificationOpen={setSuccessfulNotificationOpen}
                    setFailedNotificationOpen={setFailedNotificationOpen}
                    isRoomPrivate={isRoomPrivate}
                    privateRoomCode={privateRoomCode}
                    setCurrentTabIndex={setCurrentTabIndex}
                />
            )}
            <Box
                sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    [`& .${typographyClasses.root}`]: {
                        textWrap: "balance",
                    },
                })}
            >
                {currentTabIndex === 1 &&
                    updates.map((update) => {
                        const comments = update?.comments?.items || [];
                        const userComments =
                            comments?.filter(
                                (comment: { createdAt: Date; comment: string; user_id: string }) =>
                                    comment.user_id === userId
                            ) || [];
                        return (
                            <Card
                                key={update.id}
                                variant="soft"
                                sx={(theme) => ({
                                    minWidth: 340,
                                    [theme.breakpoints.up(440)]: {
                                        minWidth: 420,
                                    },
                                    maxWidth: 420,
                                })}
                                color="neutral"
                                size="md"
                            >
                                {(update.image || update.video) &&
                                    (update?.video?.split(".").pop()?.toLowerCase() === "mp4" ||
                                    update?.video?.split(".").pop()?.toLowerCase() === "mov" ? (
                                        <CardOverflow>
                                            {playVideo === update.id ? (
                                                <VideoWithAspectRatioMemo
                                                    src={update.videoUrl?.url.href || ""}
                                                    type="video/mp4"
                                                />
                                            ) : (
                                                <Box sx={{ position: "relative" }}>
                                                    <ImageWithAspectRatioMemo src={update.url?.url.href || ""} alt="" />
                                                    <Button
                                                        onClick={() => handlePlayButtonClick(update.id)}
                                                        sx={{
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform: "translate(-50%, -50%)",
                                                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                            color: "white",
                                                            borderRadius: "50%",
                                                            padding: "10px",
                                                        }}
                                                    >
                                                        <PlayArrow />
                                                    </Button>
                                                </Box>
                                            )}
                                        </CardOverflow>
                                    ) : (
                                        <CardOverflow>
                                            <ImageWithAspectRatioMemo src={update.url?.url.href || ""} alt="" />
                                        </CardOverflow>
                                    ))}
                                <CardContent>
                                    <Typography fontSize="sm" style={{ textWrap: "wrap", wordBreak: "break-word" }}>
                                        {update.update}
                                    </Typography>
                                    <Box
                                        sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                                    >
                                        <Typography fontSize={10} sx={{ color: "text.tertiary", my: 0.5 }}>
                                            {update?.show_time
                                                ? new Date(update.created_at).toLocaleString("en-US")
                                                : new Date(update.created_at).toLocaleDateString("en-US", {
                                                      timeZone: "UTC",
                                                  })}
                                        </Typography>
                                        {isRoomAdmin && (
                                            <Typography
                                                fontSize={10}
                                                fontWeight={700}
                                                color="primary"
                                                sx={{ my: 0.5, cursor: "pointer" }}
                                                onClick={() => {
                                                    setEditUpdateInfo({
                                                        id: update.id,
                                                        update: update.update,
                                                        image: update.image,
                                                        url: update.url,
                                                        created_at: update.created_at,
                                                        show_time: update?.show_time,
                                                    });
                                                    setIsEditModalOpen(true);
                                                }}
                                            >
                                                Edit
                                            </Typography>
                                        )}
                                    </Box>
                                </CardContent>
                                {isRoomAdmin === false && allowedComments === true ? (
                                    <>
                                        {userComments.length > 0 && (
                                            <CardContent orientation="vertical" sx={{ rowGap: "0px" }}>
                                                <Divider>
                                                    <Chip
                                                        variant="outlined"
                                                        color="neutral"
                                                        size="sm"
                                                        endDecorator={<ArrowDownward />}
                                                    >
                                                        Comments
                                                    </Chip>
                                                </Divider>
                                                {userComments.map(
                                                    (comment: {
                                                        id: string;
                                                        createdAt: Date;
                                                        comment: string;
                                                        user_id: string;
                                                        user_info: {
                                                            first_name: string;
                                                            last_name: string;
                                                            email: string;
                                                        };
                                                    }) => (
                                                        <Fragment key={comment.id}>
                                                            <Typography
                                                                fontSize="9px"
                                                                sx={{ fontWeight: "bold", marginTop: 1 }}
                                                            >{`${comment?.user_info?.first_name} ${comment?.user_info?.last_name}`}</Typography>
                                                            <Typography fontSize="11px">{comment.comment}</Typography>
                                                        </Fragment>
                                                    )
                                                )}
                                            </CardContent>
                                        )}
                                        <form
                                            onSubmit={async (e) => {
                                                e.preventDefault();
                                                const formElement = e.target as HTMLFormElement;
                                                const form = new FormData(e.target as HTMLFormElement);

                                                const { userId } = await getCurrentUser();
                                                const { given_name: firstName, family_name: lastName } =
                                                    await fetchUserAttributes();

                                                const data = {
                                                    user_id: userId,
                                                    comment: (form.get("comment") as string) || "",
                                                    room_update_id: update.id,
                                                };

                                                try {
                                                    await client.graphql({
                                                        query: createUpdateComments,
                                                        variables: { input: data },
                                                    });

                                                    setUpdates((prevUpdates) =>
                                                        prevUpdates.map((update) => {
                                                            if (update.id === data.room_update_id) {
                                                                return {
                                                                    ...update,
                                                                    comments: {
                                                                        items: [
                                                                            ...(update?.comments?.items || []), // Ensure that it defaults to an empty array if items are undefined
                                                                            {
                                                                                id: "",
                                                                                comment: data.comment,
                                                                                room_update_id: data.room_update_id,
                                                                                createdAt: new Date().toISOString(),
                                                                                user_id: userId,
                                                                                user_info: {
                                                                                    first_name: firstName,
                                                                                    last_name: lastName,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                };
                                                            }
                                                            return update;
                                                        })
                                                    );

                                                    formElement.reset();
                                                } catch (error) {
                                                    console.error("Error commenting: ", error);
                                                }
                                            }}
                                        >
                                            <CardContent orientation="horizontal" sx={{ gap: 1 }}>
                                                <FormControl sx={{ flex: 1 }}>
                                                    <Input
                                                        variant="plain"
                                                        placeholder="Add a comment…"
                                                        name="comment"
                                                        endDecorator={
                                                            <Button disabled={isGuest} type="submit">
                                                                Post
                                                            </Button>
                                                        }
                                                        sx={{
                                                            flex: 1,
                                                            "--Input-focusedThickness": "0px",
                                                            "--Input-radius": "8px",
                                                        }}
                                                        disabled={isGuest}
                                                    />
                                                    {isGuest && (
                                                        <Link
                                                            href={`/signup?redirect=${encodeURIComponent(
                                                                location.pathname
                                                            )}`}
                                                            sx={{ marginTop: "5px" }}
                                                            fontSize="9px"
                                                        >
                                                            Sign up to comment!
                                                        </Link>
                                                    )}
                                                </FormControl>
                                            </CardContent>
                                        </form>
                                    </>
                                ) : (
                                    <>
                                        {comments?.length > 0 && (
                                            <>
                                                <Divider>
                                                    <Chip
                                                        variant="outlined"
                                                        color="neutral"
                                                        size="sm"
                                                        endDecorator={<ArrowDownward />}
                                                    >
                                                        Comments
                                                    </Chip>
                                                </Divider>
                                                <CardContent orientation="vertical" sx={{ rowGap: 0 }}>
                                                    {comments.map(
                                                        (comment: {
                                                            id: string;
                                                            createdAt: Date;
                                                            comment: string;
                                                            user_id: string;
                                                            user_info?: {
                                                                first_name: string;
                                                                last_name: string;
                                                                email: string;
                                                            };
                                                        }) => (
                                                            <Fragment key={comment.id}>
                                                                <Typography
                                                                    fontSize="9px"
                                                                    sx={{ fontWeight: "bold", marginTop: 1 }}
                                                                >{`${comment?.user_info?.first_name} ${comment?.user_info?.last_name}`}</Typography>
                                                                <Typography fontSize="11px">
                                                                    {comment.comment}
                                                                </Typography>
                                                            </Fragment>
                                                        )
                                                    )}
                                                </CardContent>
                                            </>
                                        )}
                                    </>
                                )}
                            </Card>
                        );
                    })}
            </Box>
            {editUpdateInfo && (
                <UpdateRoomUpdateModal
                    isOpen={isEditModalOpen}
                    setIsOpen={setIsEditModalOpen}
                    update={editUpdateInfo}
                />
            )}
            <Snackbar
                open={successfulNotificationOpen || failedNotificationOpen}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                color={successfulNotificationOpen ? "success" : "danger"}
                autoHideDuration={2000}
                style={{ zIndex: 9999 }}
                variant="soft"
                onClose={() => {
                    setSuccessfulNotificationOpen(false);
                    setFailedNotificationOpen(false);
                }}
            >
                {successfulNotificationOpen ? "Update successfully posted!" : "Failed to post update."}
            </Snackbar>
        </Container>
    );
};
